import React, { useState } from "react";
import cn from "classnames";
import {
  Radio,
  Space,
  DatePicker,
  Button,
  ConfigProvider,
  Divider,
} from "antd";
import styles from "../Released.module.sass";
import useDarkMode from "use-dark-mode";
import Modal from "../../../../components/Modal";

function DateFilter({ valueFilterByDate, onDateFilterChange }) {
  const { RangePicker } = DatePicker;
  const [visibleModal, setVisibleModal] = useState(false);
  const [selectedValue, setSelectedValue] = useState(valueFilterByDate);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const darkMode = useDarkMode(false);

  const showModal = () => {
    setVisibleModal(true);
  };

  const handleCancel = () => {
    setVisibleModal(false);
  };

  const setDateRange = (start, end) => {
    // Set start date to the beginning of the selected day
    const fromDate = new Date(start);
    fromDate.setUTCHours(0, 0, 0, 0);

    // Set end date to the end of the selected day
    const toDate = new Date(end);
    toDate.setUTCHours(23, 59, 59, 999);

    // Convert to UTC ISO string
    setFromDate(fromDate.toISOString());
    setToDate(toDate.toISOString());
  };

  const onChange = (e) => {
    const selectedValue = e.target.value;
    const today = new Date();
    let startDate = new Date(today);
    const daysToSubtract = {
      1: 0, // Today
      2: 7, // Last 7 days
      3: 30, // Last 30 days
      4: 90, // Last 90 days
      5: 365, // Last 365 days
    };
    if (selectedValue in daysToSubtract) {
      startDate.setUTCDate(today.getUTCDate() - daysToSubtract[selectedValue]);
    }
    setSelectedValue(selectedValue);
    setDateRange(startDate, today);
  };

  const onChangeCustomDate = (dates) => {
    if (dates && dates.length === 2) {
      const [start, end] = dates;      
      setSelectedValue(6);
      const fromDate = start.format("YYYY-MM-DD");
      const toDate = end.format("YYYY-MM-DD"); 
      setFromDate(fromDate);
      setToDate(toDate);
    }
  };

  const handleOk = () => {
    onDateFilterChange(selectedValue, fromDate, toDate);
    setVisibleModal(false);
  };

  const themeConfig = {
    token: {
      colorPrimary: "#1890FF",
      colorTextBase: darkMode.value ? "#FFFFFF" : "#000000",
      colorBgContainer: darkMode.value ? "#1F1F1F" : "#FFFFFF",
      colorBgElevated: darkMode.value ? "#2F2F2F" : "#FFFFFF", // Background for dropdown
      colorText: darkMode.value ? "#FFFFFF" : "#000000", // Text color in dropdown
      buttonBoxShadow: darkMode.value ? "none" : "0 2px 0 rgb(230, 247, 255)",
    },
  };
  const buttonStyles = {
    boxShadow: darkMode.value ? "none" : "0 2px 0 rgb(230, 247, 255)",
  };
  return (
    <>
      <button
        className={cn(
          "button-stroke button-small",
          styles.button,
          "whitespace-nowrap p-2.5 text-xs"
        )}
        onClick={showModal}
      >
        Date
      </button>
      <ConfigProvider theme={themeConfig}>
        <Modal
          outerClassName={styles.outer}
          visible={visibleModal}
          onClose={() => setVisibleModal(false)}
          title="Date Filter"
        >
          <div className={styles.details}>
            <div className={cn("title-purple", styles.title)}>Date Filter</div>
            <div className={styles.row}>
              <div className={styles.col}>
                <Divider />
                <Radio.Group onChange={onChange} value={selectedValue}>
                  <Space direction="vertical">
                    <Radio value={1}>Today</Radio>
                    <Radio value={2}>Last 7 days</Radio>
                    <Radio value={3}>Last 30 days</Radio>
                    <Radio value={4}>Last 90 days</Radio>
                    <Radio value={5}>Last 365 days</Radio>
                    <Radio value={6}>
                      Custom
                      {selectedValue === 6 ? (
                        <Space direction="vertical" size={12}>
                          <RangePicker
                            
                            format="YYYY-MM-DD"
                            onChange={onChangeCustomDate}
                          />
                        </Space>
                      ) : null}
                    </Radio>
                    <Button
                      type="primary"
                      onClick={() => setSelectedValue(0)}
                      style={buttonStyles}
                    >
                      Clear
                    </Button>
                  </Space>
                </Radio.Group>
                <div className={cn(styles.btns, "mt-5 mr-[10px]")}>
                  <button
                    className={cn("button-stroke mr-3", styles.button)}
                    onClick={handleCancel}
                  >
                    Discard
                  </button>
                  <button
                    className={cn("button", styles.button)}
                    onClick={handleOk}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
}
export default DateFilter;
