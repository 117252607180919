import Swal from "sweetalert2";
import useDarkMode from "use-dark-mode";

const CustomAlert = () => {
  const darkMode = useDarkMode(false);

  const showAlert = (
    title,
    text,
    icon = "success",
    confirmButtonText = "Yes",
    cancelButtonText = "Cancel",
    timer = null,
    timerProgressBar = false,
    allowOutsideClick = true,
    showCancelButton = true
  ) => {
    return Swal.fire({
      title: title,
      text: text,
      icon: icon,
      showCancelButton: showCancelButton,
      confirmButtonText: confirmButtonText,
      cancelButtonText: cancelButtonText,
      background: darkMode.value ? "#333" : "#fff",
      color: darkMode.value ? "#fff" : "#000",
      customClass: {
        confirmButton:
          "bg-[#2A85FF] hover:bg-[#0069f6] text-white font-semibold py-2 px-4 rounded-lg shadow-sm mr-[30px]",
        cancelButton: darkMode.value
          ? "bg-[#111315] text-white font-semibold py-2 px-4 rounded-xl shadow-sm border-3 border-[#272B30] hover:border-3 hover:border-white"
          : "bg-[white] text-black font-semibold py-2 px-4 rounded-xl shadow-sm border-3 border-gray hover:border-3 hover:border-black",
      },
      buttonsStyling: false,
      timer: timer,
      timerProgressBar: timerProgressBar,
      allowOutsideClick: allowOutsideClick,
    });
  };

  return { showAlert };
};

export default CustomAlert;
