import React from "react";
import { usePermissions } from "../context/Permissions/PermissionsContext";

const AccessControl = ({ moduleCode, requiredPrivilege, children }) => {
  const { permissions, status } = usePermissions();

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Failed to load permissions. Please try again.</div>;
  }

  if (status === "succeeded") {
    let hasAccess;
    if (permissions === "All") {
      hasAccess = true;
    } else {
      hasAccess = permissions.some(
        (perm) =>
          perm.module_permission.module_id === moduleCode &&
          perm.module_permission.code === requiredPrivilege
      );
    }

    if (hasAccess) {
      return <>{children}</>;
    }

    return null;
  }
};

export default AccessControl;
