import React, { createContext, useContext, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchUserPermissions } from "../../features/accessControl/acccesControlSlice";

const PermissionsContext = createContext();

export const PermissionsProvider = ({ children }) => {
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.permissions.permissions);
  const status = useSelector((state) => state.permissions.status);

  useEffect(() => {
    dispatch(fetchUserPermissions());
  }, [dispatch]);

  return (
    <PermissionsContext.Provider value={{ permissions, status }}>
      <Outlet />
    </PermissionsContext.Provider>
  );
};

export const usePermissions = () => {
  return useContext(PermissionsContext);
};
