// import React, { useCallback, useEffect } from "react";
// import { createPortal } from "react-dom";
// import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
// import OutsideClickHandler from "react-outside-click-handler";
// import cn from "classnames";
// import styles from "./Modal.module.sass";
// import Icon from "../Icon";

// const Modal = ({ outerClassName, visible, onClose, children }) => {
//   const escFunction = useCallback(
//     (e) => {
//       if (e.keyCode === 27) {
//         onClose();
//       }
//     },
//     [onClose]
//   );

//   useEffect(() => {
//     document.addEventListener("keydown", escFunction, false);
//     return () => {
//       document.removeEventListener("keydown", escFunction, false);
//     };
//   }, [escFunction]);

//   useEffect(() => {
//     if (visible) {
//       const target = document.querySelector("#modal");
//       disableBodyScroll(target);
//     } else {
//       clearAllBodyScrollLocks();
//     }
//   }, [visible]);

//   return createPortal(
//     visible && (
//       <div id="modal" className={styles.modal}>
//         <div className={cn(styles.outer, outerClassName)}>
//           <OutsideClickHandler onOutsideClick={onClose}>
//             {children}
//             <button className={styles.close} onClick={onClose}>
//               <Icon name="close" size="20" />
//             </button>
//           </OutsideClickHandler>
//         </div>
//       </div>
//     ),
//     document.body
//   );
// };

// export default Modal;
// import React, { useCallback, useEffect } from "react";
// import { createPortal } from "react-dom";
// import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
// import OutsideClickHandler from "react-outside-click-handler";
// import cn from "classnames";
// import styles from "./Modal.module.sass";
// import Icon from "../Icon";
// import { DatePicker, Radio, Space, Button } from "antd";
// import dayjs from "dayjs";

// const Modal = ({ outerClassName, visible, onClose, children }) => {
//   const escFunction = useCallback(
//     (e) => {
//       if (e.keyCode === 27) {
//         onClose();
//       }
//     },
//     [onClose]
//   );

//   useEffect(() => {
//     document.addEventListener("keydown", escFunction, false);
//     return () => {
//       document.removeEventListener("keydown", escFunction, false);
//     };
//   }, [escFunction]);

//   useEffect(() => {
//     if (visible) {
//       const target = document.querySelector("#modal");
//       disableBodyScroll(target);
//     } else {
//       clearAllBodyScrollLocks();
//     }
//   }, [visible]);

//   // Handle outside click to close the modal, except for clicks within the Select or Date Picker
//   const handleOutsideClick = (e) => {
//     if (
//       !e.target.closest(".ant-select-dropdown") &&
//       !e.target.closest(".ant-picker-dropdown")
//     ) {
//       onClose();
//     }
//   };

//   return createPortal(
//     visible && (
//       <div id="modal" className={styles.modal}>
//         <div className={cn(styles.outer, outerClassName)}>
//           <OutsideClickHandler onOutsideClick={handleOutsideClick}>
//             {children}
//             <button className={styles.close} onClick={onClose}>
//               <Icon name="close" size="20" />
//             </button>
//           </OutsideClickHandler>
//         </div>
//       </div>
//     ),
//     document.body
//   );
// };

// export default Modal;
import React, { useCallback, useEffect } from "react";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import cn from "classnames";
import styles from "./Modal.module.sass";
import Icon from "../Icon";

const Modal = ({ outerClassName, visible, onClose, children }) => {
  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal");
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  const handleOutsideClick = (e) => {
    const isOutsideSelect = !e.target.closest(".ant-select-dropdown");
    const isOutsidePicker = !e.target.closest(".ant-picker-dropdown");

    if (isOutsideSelect && isOutsidePicker) {
      onClose();
    }
  };

  return createPortal(
    visible && (
      <div id="modal" className={styles.modal}>
        <div className={cn(styles.outer, outerClassName)}>
          <OutsideClickHandler onOutsideClick={handleOutsideClick}>
            {children}
            <button className={styles.close} onClick={onClose}>
              <Icon name="close" size="20" />
            </button>
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  );
};

export default Modal;
