import React from "react";
import { usePermissions } from "../context/Permissions/PermissionsContext";

const MultiAccessControl = ({ moduleCodes, requiredPrivileges, children }) => {

  const { permissions, status } = usePermissions();

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  if (status === "failed") {
    return <div>Failed to load permissions. Please try again.</div>;
  }

  if (status === "succeeded") {
    let hasAccess = false;
    
    if (permissions === "All") {
      hasAccess = true;
    } else {
      hasAccess = moduleCodes.some((moduleCode) =>
        requiredPrivileges.some((requiredPrivilege) =>
          permissions.some(
            (perm) =>
              perm.module_permission.module_id === moduleCode &&
              perm.module_permission.code === requiredPrivilege
          )
        )
      );
    }

    if (hasAccess) {
      return <>{children}</>;
    }

    return null;
  }

  return null;
};

export default MultiAccessControl;
