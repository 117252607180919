import styles from "./Row.module.sass";
import cn from "classnames";

import moment from "moment";

import { useMenu } from "../../../../../context/Roles/MenuContext";

const Row = ({ item, activeTable, activeId }) => {
  const { setSubActiveTab, setCamera } = useMenu();

  const handleUpdate = (camera) => {
    setSubActiveTab(3);
    setCamera(camera);
  };
  // const deviceId = item.camera_unique_id.slice(0, 20) + "...";

  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
        onClick={() => handleUpdate(item)}
      >
        <div className={styles.col}>
          <div className={styles.email}>{item.name}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.email}>{item.brand}</div>
        </div>

        <div className={styles.col}>
          <div className={styles.email}>
            {moment(item.created_at).format("DD MMM YYYY")}
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;
