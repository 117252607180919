import React, { useState } from "react";
import { ConfigProvider, Divider, Select, Space } from "antd";
import cn from "classnames";
import styles from "../Released.module.sass";
import Modal from "../../../../components/Modal";
import Checkbox from "../../../../components/Checkbox";
import useDarkMode from "use-dark-mode";
function Filter({
  title,
  type,
  values = [],
  onChange,
  onSearch,
  suggestions = [],
  isTagFilter,
}) {
  const [visibleModal, setVisibleModal] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filterValue, setFilterValue] = useState(type === "select" ? [] : "");
  const [tempFilterValue, setTempFilterValue] = useState(
    type === "select" ? [] : ""
  );
  const darkMode = useDarkMode(false);
  const showModal = () => {
    setVisibleModal(true);
    // setIsModalOpen(true);
    setTempFilterValue(filterValue);
  };

  const handleOk = () => {
    setFilterValue(tempFilterValue);
    onChange(tempFilterValue);
    setVisibleModal(false);
    // setIsModalOpen(false);
  };

  const handleCancel = () => {
    // setIsModalOpen(false);
    setVisibleModal(false);
  };

  const handleTempChange = (newValueOrEvent) => {
    let value;
    if (Array.isArray(newValueOrEvent)) {
      value = newValueOrEvent;
    } else if (
      newValueOrEvent &&
      typeof newValueOrEvent === "object" &&
      newValueOrEvent.hasOwnProperty("value")
    ) {
      value = newValueOrEvent.value;
    } else {
      value = newValueOrEvent;
    }
    setTempFilterValue(value);
  };
  const themeConfig = {
    token: {
      colorPrimary: "#1890ff",
      colorTextBase: darkMode.value ? "#ffffff" : "#000000",
      colorBgContainer: darkMode.value ? "#1f1f1f" : "#ffffff",
      colorBgElevated: darkMode.value ? "#2f2f2f" : "#ffffff", // Background for dropdown
      colorText: darkMode.value ? "#ffffff" : "#000000", // Text color in dropdown
      buttonBoxShadow: darkMode.value ? "none" : "0 2px 0 rgb(230, 247, 255)",
    },
  };
  const buttonStyles = {
    boxShadow: darkMode.value ? "none" : "0 2px 0 rgb(230, 247, 255)",
  };
  return (
    <>
      <button
        className={cn(
          "button-stroke button-small",
          styles.button,
          "whitespace-nowrap p-2.5 text-xs"
        )}
        onClick={showModal}
      >
        {title}
      </button>
      <ConfigProvider theme={themeConfig}>
        <Modal
          outerClassName={styles.outer}
          visible={visibleModal}
          onClose={() => setVisibleModal(false)}
        >
          <div className={styles.details}>
            <div className={cn("title-purple", styles.title)}>{title}</div>
            <div className={styles.row}>
              <div className={styles.col}>
                <Divider />
                {type === "radio" ? (
                  <Space direction="vertical">
                    {values.map((option) => (
                      <Checkbox
                        key={option.value}
                        value={tempFilterValue.includes(option.value)}
                        content={option.label}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          const newValue = checked
                            ? [...tempFilterValue, option.value]
                            : tempFilterValue.filter(
                                (val) => val !== option.value
                              );
                          handleTempChange(newValue);
                        }}
                      />
                    ))}
                  </Space>
                ) : isTagFilter ? (
                  <Select
                    mode="multiple"
                    allowClear
                    style={{ width: "100%" }}
                    placeholder={`Search and select ${title.toLowerCase()}`}
                    value={tempFilterValue}
                    onSearch={onSearch}
                    onChange={handleTempChange}
                  >
                    {suggestions.map((tag, index) => (
                      <Select.Option key={index} value={tag}>
                        {tag}
                      </Select.Option>
                    ))}
                  </Select>
                ) : (
                  <Select
                    mode="tags"
                    style={{ width: "100%" }}
                    placeholder="Please Select"
                    onChange={handleTempChange}
                    value={tempFilterValue}
                    options={values}
                  />
                )}
                <div className={cn(styles.btns, "mt-5 mr-[10px]")}>
                  <button
                    className={cn("button-stroke mr-3", styles.button)}
                    onClick={handleCancel}
                  >
                    Discard
                  </button>
                  <button
                    className={cn("button", styles.button)}
                    onClick={handleOk}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </ConfigProvider>
    </>
  );
}

export default Filter;
