import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Create.module.sass";

import Icon from "../../../../components/Icon";
import TextInput from "../../../../components/TextInput";
import Dropdown from "../../../../components/Dropdown";
import { useMenu } from "../../../../context/Roles/MenuContext";
import {
  create_barcode,
  get_all_cameras,
  get_camera_modes,
} from "../../../../API/Camera";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import Card from "../../../../components/Card";
import { useFormikContext } from "../../context/SettingsContext";

const CreateBarcode = ({}) => {
  const { formikRef } = useFormikContext();

  const { setSubActiveTab } = useMenu();
  const initialValues = {
    name: "",
    cameraSettings: [{ camera_id: "", purpose_id: "" }],
  };

  const validationSchema = Yup.object({
    name: Yup.string()
    .matches(/^[a-zA-Z0-9]{1,3}$/, "Name must be up to 3 alphanumeric characters")
    .required("Barcode is required"),
    cameraSettings: Yup.array().of(
      Yup.object().shape({
        camera_id: Yup.string().required("Camera is required"),
        purpose_id: Yup.string().required("Purpose is required"),
      })
    ),
  });

  const [cameraOptions, setCameraOptions] = useState([]);
  const [selectedCameras, setSelectedCameras] = useState([]);
  const [purpose, setPurpose] = useState([]);

  const handleSave = async (
    values,
    { setTouched, setErrors, validateForm }
  ) => {
    try {
      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        setTouched({
          name: true,
          cameraSettings: values.cameraSettings.map(() => ({
            camera_id: true,
            purpose_id: true,
          })),
        });
        setErrors(errors);
        return;
      }

      const formData = {
        barcode: values.name,
        cameras: values.cameraSettings.map((item) => {
          return { id: item.camera_id, mode: item.purpose_id };
        }),
      };

      const res = await create_barcode(formData);

      if (res.status === 200) {
        setSubActiveTab(1);

        message.success(res.data.message);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data.errors;
        message.error(Object.values(errors[0])[0]);
      } else if (error.response.status === 500) {
        message.error(error.response.data.err);
      }
    }
  };

  useEffect(() => {
    const fetchCameras = async () => {
      try {
        const response = await get_all_cameras();
        setCameraOptions(response.data);
      } catch (error) {
        message.error("Failed to fetch cameras.");
      }
    };

    const fetchPurpose = async () => {
      try {
        const response = await get_camera_modes();
        const result = Object.entries(response.data).map(([key, value]) => {
          return {
            id: key,
            name: value,
          };
        });

        setPurpose(result);
      } catch {}
    };

    fetchCameras();
    fetchPurpose();
  }, []);

  const handleBack = () => {
    setSubActiveTab(1);
  };

  const handleDropdownChange = (index, field, value, setFieldValue, values) => {
    const newSelectedCameras = [...selectedCameras];

    if (field.includes("camera_id")) {
      newSelectedCameras[index] = value;
      setSelectedCameras(newSelectedCameras);
      setFieldValue(field, value);
    } else {
      setFieldValue(field, value);
    }

    setSelectedCameras(newSelectedCameras);
  };

  const handleRemoveSet = (index, remove) => {
    const newSelectedCameras = [...selectedCameras];
    newSelectedCameras.splice(index, 1);
    setSelectedCameras(newSelectedCameras);
    remove(index);
  };

  const availableCameras = cameraOptions.filter(
    (camera) => !selectedCameras.includes(camera.id)
  );

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSave}
      innerRef={formikRef}
    >
      {({ isSubmitting, setFieldValue, values, errors, touched }) => (
        <Form>
          <Card
            className={cn(styles.card)}
            title="New Barcode"
            classTitle="title-red"
            head={
              <div className={styles.flex_box}>
                <button className={cn("button-stroke")} onClick={handleBack}>
                  <Icon name="arrow-left" size="24" />
                  <span>Back</span>
                </button>
              </div>
            }
          >
            <div className={styles.description}>
              <div className={styles.txt_container}>
                <Field name="name">
                  {({ field, meta }) => (
                    <TextInput
                      {...field}
                      label="Enter Barcode"
                      tooltip="Maximum 50 characters."
                      className={cn(styles.field)}
                      error={meta.touched && meta.error ? meta.error : ""}
                      required
                    />
                  )}
                </Field>
              </div>
              <FieldArray name="cameraSettings">
                {({ insert, remove, push }) => (
                  <>
                    {values.cameraSettings.length > 0 &&
                      values.cameraSettings.map((setting, index) => (
                        <>
                          <div className={styles.groups} key={index}>
                            <div className={styles.group}>
                              <Field name={`cameraSettings.${index}.camera_id`}>
                                {({ field, meta }) => (
                                  <Dropdown
                                    {...field}
                                    label="Select Camera"
                                    tooltip="Maximum 100 characters. No HTML or emoji allowed"
                                    value={
                                      cameraOptions.find(
                                        (option) =>
                                          option.id ===
                                          values.cameraSettings[index].camera_id
                                      )?.name || ""
                                    }
                                    setValue={(value) => {
                                      const selectedCameraId =
                                        cameraOptions.find(
                                          (option) => option.name === value
                                        )?.id || "";
                                      handleDropdownChange(
                                        index,
                                        `cameraSettings.${index}.camera_id`,
                                        selectedCameraId,
                                        setFieldValue,
                                        values
                                      );
                                    }}
                                    options={availableCameras.map(
                                      (option) => option.name
                                    )}
                                    className={cn(styles.field)}
                                    error={
                                      meta.touched && meta.error
                                        ? meta.error
                                        : ""
                                    }
                                  />
                                )}
                              </Field>
                              <Field
                                name={`cameraSettings.${index}.purpose_id`}
                              >
                                {({ field, meta }) => (
                                  <Dropdown
                                    {...field}
                                    label="Select Purpose"
                                    tooltip="Maximum 100 characters. No HTML or emoji allowed"
                                    value={
                                      purpose.find(
                                        (option) =>
                                          option.id ===
                                          values.cameraSettings[index]
                                            .purpose_id
                                      )?.name || ""
                                    }
                                    setValue={(value) => {
                                      setFieldValue(
                                        `cameraSettings.${index}.purpose_id`,
                                        purpose.find(
                                          (option) => option.name === value
                                        )?.id || ""
                                      );

                                      handleDropdownChange(
                                        index,
                                        `cameraSettings.${index}.purpose_id`,
                                        purpose.find(
                                          (option) => option.name === value
                                        )?.id || "",
                                        setFieldValue,
                                        values
                                      );
                                    }}
                                    options={purpose.map(
                                      (option) => option.name
                                    )}
                                    className={cn(styles.field)}
                                    error={
                                      meta.touched && meta.error
                                        ? meta.error
                                        : ""
                                    }
                                  />
                                )}
                              </Field>{" "}
                            </div>{" "}
                            <div className={styles.delete_container}>
                              {index > 0 ? (
                                <button
                                  type="button"
                                  className={cn(styles.link, styles.active)}
                                  onClick={() => handleRemoveSet(index, remove)}
                                >
                                  <Icon name="trash" size="24" />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className={cn(styles.empty_box)}
                                >
                                  {/* <Icon name="trash" size="24" /> */}
                                </button>
                              )}
                            </div>
                          </div>
                          {values.cameraSettings.length > 0 &&
                          values.cameraSettings.every(
                            (setting) =>
                              setting.camera_id !== "" &&
                              setting.purpose_id !== ""
                          ) &&
                          availableCameras?.length > 0 ? (
                            <button
                              type="button"
                              className={cn("button-stroke", styles.button)}
                              onClick={() =>
                                push({ camera_id: "", purpose_id: "" })
                              }
                            >
                              Add Camera
                            </button>
                          ) : (
                            ""
                          )}
                        </>
                      ))}
                  </>
                )}
              </FieldArray>
            </div>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default CreateBarcode;
