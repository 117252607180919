import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import { Link } from "react-router-dom";
import Icon from "../Icon";
import Search from "./Search";
import Messages from "./Messages";
import Notification from "./Notification";
import User from "./User";
import { GET_EMPLOYEE } from "../../API/Employee";

const Header = ({ onOpen }) => {
  const [visible, setVisible] = useState(false);
  const [employee, setEmployee] = useState(null);
  const handleClick = () => {
    onOpen();
    setVisible(false);
  };
  useEffect(() => {
    // Fetch employees when the component mounts
    const fetchEmployees = async () => {
      try {
        const data = await GET_EMPLOYEE();
        setEmployee(data); // Store the employee data in state
      } catch (error) {
        console.error("Failed to fetch employees:", error);
      }
    };

    fetchEmployees();
  }, []);
  return (
    <header className={styles.header}>
      <button className={styles.burger} onClick={() => handleClick()}></button>
      <Search className={cn(styles.search, { [styles.visible]: visible })} />

      <button
        className={cn(styles.buttonSearch, { [styles.active]: visible })}
        onClick={() => setVisible(!visible)}
      >
        <Icon name="search" size="24" />
      </button>
      <div className={styles.control}>
        <div className={styles.userresult}>
          {employee ? employee.full_name : null}
          {/* Display the full name */}
        </div>
        {/* <div className={cn("button-small", styles.button)} to="/products/add">
          <Icon name="add" size="24" />
          <span>Create</span>
        </div> */}
        {/* <Messages className={styles.messages} /> */}
        {/* <Notification className={styles.notification} /> */}
        <User className={styles.user} />
      </div>
      {/* <div className={styles.btns}>
        <Link className={styles.link} to="/sign-in">
          keerthi
        </Link>
      </div> */}
      {/* <div className={styles.btns}>
        <Link className={styles.link} to="/sign-in">
          Sign in
        </Link>
        <Link className={cn("button", styles.button)} to="/sign-up">
          Sign up
        </Link>
      </div> */}
    </header>
  );
};

export default Header;
