import React, { useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";

import Loader from "../../../../components/Loader";
import Row from "./Row";

// data
import { customers } from "../../../../mocks/customers";

const Table = ({ data, className, activeTable, setActiveTable }) => {
  const [activeId, setActiveId] = useState(customers[0].id);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.table)}>
        <div className={cn(styles.row, { [styles.active]: activeTable })}>
          <div className={styles.col}>Role</div>
          <div className={styles.col}>Created By</div>

          <div className={styles.col}>Created On</div>
          <div className={styles.col}>Status</div>
        </div>
        {data?.map((role, index) => (
          <Row
            item={role}
            key={index}
            activeTable={activeTable}
            setActiveTable={setActiveTable}
            activeId={activeId}
            setActiveId={setActiveId}
            value={selectedFilters.includes(role.id)}
            onChange={() => handleChange(role.id)}
          />
        ))}
      </div>
     
    </div>
  );
};

export default Table;
