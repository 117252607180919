import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignUp.module.sass";
import { use100vh } from "react-div-100vh";
import { Link } from "react-router-dom";
import Entry from "./Entry";
import Code from "./Code";
import Image from "../../components/Image";

import DarkLogo from "../../assets/images/ppg_logo_in_dark.svg";

const items = [
  "Unlimited product uploads",
  "Pro tips",
  "Free forever",
  "Full author options",
];

const SignUp = () => {
  const [visible, setVisible] = useState(true);
  const [userData, setUserData] = useState();
  const heightWindow = use100vh();

  return (
    <div className={styles.row}>
      <div className={styles.col} style={{ minHeight: heightWindow }}>
        <div className={styles.head}>
          <div className={styles.info}>
            Already a member?{" "}
            <Link className={styles.link} to="/sign-in">
              Sign in
            </Link>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div>
            {" "}
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src="/logo.png"
                srcDark={DarkLogo}
                alt="Core"
              />
            </Link>
          </div>
          <div className={cn("h2", styles.title)}>Sign up</div>
          {visible ? (
            <Entry
              onConfirm={() => setVisible(false)}
              setUserData={setUserData}
            />
          ) : (
            <Code userData={userData} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
