export const companies = [
    {
        id: "0",
        title: "Mailchimp",
        content:
            "Test your product idea by launching payments with little to no code.",
        image: "/images/contents/companies/mailchimp.png",
    },
    {
        id: "1",
        title: "CovertKit",
        content:
            "Test your product idea by launching payments with little to no code.",
        image: "/images/contents/companies/covert-kit.png",
    },
    {
        id: "2",
        title: "Zapier",
        content:
            "Test your product idea by launching payments with little to no code.",
        image: "/images/contents/companies/zapier.png",
    },
    {
        id: "3",
        title: "AirDev",
        content:
            "Test your product idea by launching payments with little to no code.",
        image: "/images/contents/companies/air-dev.png",
    },
    {
        id: "4",
        title: "Booking Autopilot",
        content:
            "Test your product idea by launching payments with little to no code.",
        image: "/images/contents/companies/booking-autopilot.png",
    },
    {
        id: "5",
        title: "Amazon Web Service",
        content:
            "Test your product idea by launching payments with little to no code.",
        image: "/images/contents/companies/amazon-web-service.png",
    },
    {
        id: "6",
        title: "Dropbox",
        content:
            "Test your product idea by launching payments with little to no code.",
        image: "/images/contents/companies/dropbox.png",
    },
    {
        id: "7",
        title: "Intercomm",
        content:
            "Test your product idea by launching payments with little to no code.",
        image: "/images/contents/companies/intercomm.png",
    },
    {
        id: "8",
        title: "Google",
        content:
            "Test your product idea by launching payments with little to no code.",
        image: "/images/contents/companies/google.png",
    },
];

export const companiesHero = [
    {
        src: "/images/contents/makerbazzer.svg",
        width: 140,
        height: 40,
    },
    // {
    //     src: "/images/contents/airtable.svg",
    //     width: 128,
    //     height: 40,
    // },
    // {
    //     src: "/images/contents/gumroad.svg",
    //     width: 123,
    //     height: 40,
    // },
    // {
    //     src: "/images/contents/notion.svg",
    //     width: 107,
    //     height: 40,
    // },
];

export const companiesHeroDark = [
    {
        src: "/images/contents/mailchimp-dark.svg",
        width: 140,
        height: 40,
    },
    // {
    //     src: "/images/contents/airtable-dark.svg",
    //     width: 128,
    //     height: 40,
    // },
    // {
    //     src: "/images/contents/gumroad-dark.svg",
    //     width: 123,
    //     height: 40,
    // },
    // {
    //     src: "/images/contents/notion-dark.svg",
    //     width: 107,
    //     height: 40,
    // },
];

export const companiesCustomer = [
    {
        src: "/images/contents/mailchimp.svg",
        width: 126,
        height: 36,
    },
    {
        src: "/images/contents/airtable.svg",
        width: 115,
        height: 36,
    },
    {
        src: "/images/contents/gumroad.svg",
        width: 111,
        height: 36,
    },
    {
        src: "/images/contents/notion.svg",
        width: 96,
        height: 36,
    },
    {
        src: "/images/contents/amazon.svg",
        width: 83,
        height: 36,
    },
    {
        src: "/images/contents/shopify.svg",
        width: 96,
        height: 36,
    },
];
