import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Card from "../../../components/Card";
import { Link, useParams, useNavigate } from "react-router-dom";
import AccessControl from "../../../HOC/AccessControl";
import Icon from "../../../components/Icon";
import cn from "classnames";
import styles from "./AddStaffMember.module.sass";
import TextInput from "../../../components/TextInput";
import Dropdown from "../../../components/Dropdown";
import { message } from "antd";
import custome_axios from "../../../axios";
import Swal from "sweetalert2";
import useDarkMode from "use-dark-mode";
const AddStaffMember = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const darkMode = useDarkMode(false);
  const [initialValues, setInitialValues] = useState({
    email: "",
    full_name: "",
    // password: "",
    // first_name: "",
    // last_name: "",
    mobile_no: "",
    role_id: "",
    status:true
  });
  const [roleOptions, setRoleOptions] = useState([]);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await custome_axios.get(`${id?"/role":"/role/permitted_roles"}`);
        const roles = response.data.data.map((role) => ({
          label: role.role,
          value: role.id,
        }));
        setRoleOptions([...roles]);
      } catch (error) {
        message.error("Failed to fetch roles.");
      }
    };

    fetchRoles();
  }, []);

  useEffect(() => {
    if (id) {
      const fetchStaffMember = async () => {
        try {
          const response = await custome_axios.get(`/employee/${id}`);

          setInitialValues({
            email: response.data.data.email,
            full_name: response.data.data.full_name,
            // last_name: response.data.data.last_name,
            mobile_no: response.data.data.mobile_no,
            role_id: response.data.data.role.id,
            status:response.data.data.status
            // password: "",
          });
        } catch (error) {
          message.error("Failed to fetch staff member data.");
        }
      };
      
      fetchStaffMember();
    }
  }, [id]);

  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Invalid email format")
      .required("Email is required"),
    // password: id
    //   ? Yup.string().max(100, "Maximum 100 characters allowed")
    //   : Yup.string()
    //       .max(100, "Maximum 100 characters allowed")
    //       .required("Password is required"),
    full_name: Yup.string()
      .max(100, "Maximum 100 characters allowed")
      .required("Full Name is required"),

    mobile_no: Yup.string().required("Phone is required"),
    role_id: Yup.string().required("Role is required"),
  });

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      const getChangedValues = (initialValues, currentValues) => {
        const changedValues = {};
        Object.keys(currentValues).forEach((key) => {
          if (initialValues[key] !== currentValues[key]) {
            changedValues[key] = currentValues[key];
          }
        });
        return changedValues;
      };
      
      const changedValues = getChangedValues(initialValues, values);

      if (id) {
        if (Object.keys(changedValues).length > 0) {
          const res = await custome_axios.patch(`/employee/${id}`, changedValues);
          if (res.status === 200) {
            message.success("Member updated successfully!");
            navigate("/staffs");
          }
        } else {
          message.info("No changes to save.");
        }
        // const res = await custome_axios.patch(`/employee/${id}`, {
        //   email: values.email,
        //   // password: values.password,
        //   full_name: values.full_name,
        //   // last_name: values.last_name,
        //   mobile_no: values.mobile_no,
        //   role_id: values.role_id,
        // });
      
      } else {
        const res = await custome_axios.post("/employee/register", {
          email: values.email,
          // password: values.password,
          full_name: values.full_name,
          // last_name: values.last_name,
          mobile_no: values.mobile_no,
          role_id: values.role_id,
        });
        if (res.status == 201) {
          message.success("Member saved successfully!");
          navigate("/staffs");
        }
      }
      resetForm();
    } catch (error) {
      message.error(error.response.data.err);
    }
    setSubmitting(false);
  };
  const handleCancle = () => {
    navigate("/staffs");
  };
  const handleDeactivate = async () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, deactivate it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          await custome_axios.post(`/employee/deactivate/${id}`);
          message.success("Member deactivated successfully!");
          Swal.fire(
            "Deactivated!",
            "The staff member has been deactivated.",
            "success"
          );
          navigate("/staffs");
        } catch (error) {
          message.error("Failed to deactivate member.");
        }
      }
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setFieldValue, resetForm, values }) => (
        <Form>
          <Card
            className={cn(styles.card)}
            title="Member Details"
            classTitle="title-green"
            head={
              <Link
                className={cn("button-stroke button-small", styles.button)}
                to="/staffs"
              >
                <Icon name="arrow-left" size="24" />
                <span>Back</span>
              </Link>
            }
          >
            <div className={styles.description}>
              <Field name="full_name">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    value={values.full_name}
                    label="Full Name"
                    tooltip="Maximum 100 characters. No HTML or emoji allowed"
                    className={styles.field}
                    error={meta.touched && meta.error ? meta.error : ""}
                  />
                )}
              </Field>

              {/* <Field name="last_name">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    value={values.last_name}
                    label="Last Name"
                    tooltip="Maximum 100 characters. No HTML or emoji allowed"
                    className={styles.field}
                    error={meta.touched && meta.error ? meta.error : ""}
                  />
                )}
              </Field> */}

              <Field name="mobile_no">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    value={values.mobile_no}
                    label="Phone"
                    tooltip="Maximum 100 characters. No HTML or emoji allowed"
                    className={styles.field}
                    error={meta.touched && meta.error ? meta.error : ""}
                  />
                )}
              </Field>

              <Field name="email">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    value={values.email}
                    label="Email"
                    type="email"
                    tooltip="Maximum 100 characters. No HTML or emoji allowed"
                    className={styles.field}
                    autocomplete="off"
                    error={meta.touched && meta.error ? meta.error : ""}
                  />
                )}
              </Field>
              {/* {!id && (
                <Field name="password">
                  {({ field, meta }) => (
                    <TextInput
                      {...field}
                      value={values.password}
                      label="Set Password"
                      type="password"
                      tooltip="Maximum 100 characters. No HTML or emoji allowed"
                      className={styles.field}
                      autocomplete="off"
                      error={meta.touched && meta.error ? meta.error : ""}
                    />
                  )}
                </Field>
              )} */}
              <AccessControl moduleCode="BjQPiy" requiredPrivilege="OZnKi4">
                <Field name="role_id">
                  {({ field, meta }) => (
                    <Dropdown
                      {...field}
                      label="Role"
                      tooltip="Maximum 100 characters. No HTML or emoji allowed"
                      value={
                        roleOptions.find(
                          (option) => option.value === values.role_id
                        )?.label || ""
                      }
                      setValue={(value) =>
                        setFieldValue(
                          "role_id",
                          roleOptions.find((option) => option.label === value)
                            ?.value || ""
                        )
                      }
                      options={roleOptions.map((option) => option.label)}
                      className={cn(styles.field, "w-[50%]")}
                      error={meta.touched && meta.error ? meta.error : ""}
                      disabled={false}
                    />
                  )}
                </Field>
              </AccessControl>
            </div>
          </Card>

          <div
            style={{
              marginTop: "20px",
              backgroundColor: darkMode.value ? "#272B30" : null,
              border: darkMode.value ? "#272B30" : null,
            }}
            className={cn("panel", styles.panel)}
          >
            <div className={styles.info}>
              <Icon name="check-all" size="24" />
              Last saved <span>Oct 4, 2021 - 23:32</span>
            </div>
            <div className={styles.btns}>
              {id && (
                <>
                  {" "}
                  {initialValues.status === true ? (
                    <AccessControl
                      moduleCode="eV79MY"
                      requiredPrivilege="J56KCw"
                    >
                      <button
                        type="button"
                        className={cn("button-stroke", styles.button)}
                        onClick={handleDeactivate}
                      >
                        Deactivate
                      </button>
                    </AccessControl>
                  ) : (
                    <button
                    type="button"
                    className={cn("button-stroke", styles.button)}
                   
                  >
                    Deactivated
                  </button>
                  )}
                </>
              )}

              {id ? (
                <AccessControl moduleCode="eV79MY" requiredPrivilege="EJcOHw">
                  {" "}
                  <button
                    type="button"
                    className={cn("button-stroke", styles.button)}
                    onClick={handleCancle}
                  >
                    Cancel
                  </button>
                  <button
                    type="submit"
                    className={cn("button", styles.button)}
                    disabled={isSubmitting}
                  >
                    {" "}
                    Update Member
                  </button>
                </AccessControl>
              ) : (
                <>
                  <button
                    type="button"
                    className={cn("button-stroke", styles.button)}
                    onClick={() => resetForm()}
                    style={{
                      color: darkMode.value ? "#ffff" : "#272B30",
                    }}
                  >
                    Cancel
                  </button>{" "}
                  <button
                    type="submit"
                    className={cn("button", styles.button)}
                    disabled={isSubmitting}
                  >
                    {" "}
                    <AccessControl
                      moduleCode="eV79MY"
                      requiredPrivilege="EJcOHw"
                    >
                      {" "}
                      Save Member
                    </AccessControl>
                  </button>
                </>
              )}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default AddStaffMember;
