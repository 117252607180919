export const reviews = [
   
    {
        id: "0",
        author: "Sameer Rana",
        position: "Operations Head at Akaar Cosmetics",
        avatar: "/images/content/testimony0.png",
        content:
        "When I signed up with Pick Pack Go it was a no-brainer. It's been one of the best decisions I've made to ensure my warehouse operations are on point.",
    },
  
    {
        id: "1",
        author: "Leah Miller",
        position: "CEO at Doggo Treats",
        avatar: "/images/content/testimony6.png",
        content:
        "I simply love Pick Pack Go, I can’t even imagine running my business without it now.",
    },
    {
        id: "2",
        author: "Sam Trout",
        position: "COO at QuickShop Inc.",
        avatar: "/images/content/testimony1.png",
        content:
            "Since integrating PickPackGo, our efficiency has increased by 58%, and packing errors are virtually nonexistent now.",
    },
    {
        id: "3",
        author: "Priya Gupta",
        position: "Logistics Head at Fashion Forward",
        avatar: "/images/content/testimony3.png",
        content:
        "Having AI Insights  from Packo is like having a data analyst on call 24/7! It has transformed how we take decisions",
    },
    {
        id: "4",
        author: "Hanna Gafinkle",
        position: "Strategy Lead at DecorPlus",
        avatar: "/images/content/testimony2.png",
        content:
            "The business insights we get from PPG have informed our strategic decisions, leading to significant improvements in our business operations.",
    },
    {
        id: "5",
        author: "Mike R.",
        position: "E-commerce Director at BitesBox",
        avatar: "/images/content/testimony4.png",
        content:
           "Our Shopify integration with PickPackGo is seamless; our error rates dropped by 46%, enhancing customer satisfaction.",
    },
    {
        id: "6",
        author: "John Joe",
        position: "Supply Chain Director at PrimeWare.",
        avatar: "/images/content/testimony8.png",
        content:
        "The real-time analytics from Pick Pack Go have drastically reduced errors and boosted efficiency. It’s a game-changer for our operations.",
    },
    {
        id: "7",
        author: "Emily",
        position: "Logistics Lead at Floral Rush",
        avatar: "/images/content/testimony5.png",
        content:
           "The camera system to monitor packing has reassured us and our customers alike, improving trust and reducing complaints.",
    },
    {
        id: "8",
        author: "Seema Khanna",
        position: "EC Coordinator at UrbanTrend",
        avatar: "/images/content/testimony9.png",
        content:
        "Integrating Pick Pack Go was smooth, and we’ve seen a major increase in efficiency and customer satisfaction. It’s a must-have for any business.",
    },
    {
        id: "9",
        author: "Neeraj Gupta",
        position: "Manager at QuickShip Logistics",
        avatar: "/images/content/testimony7.png",
        content:
        "Pick Pack Go has transformed our warehouse operations with unmatched accuracy and efficiency. It’s now essential to our daily workflow.",
    },
];
