import { useLocation, Navigate, Outlet } from "react-router-dom";

const PublicRoute = () => {
  const jwtToken = localStorage.getItem("token");
  const location = useLocation();

  return (
    <>
      {jwtToken && (
        <Navigate to="/orders/" state={{ from: location }} replace />
      )}
      {!jwtToken && <Outlet />}
    </>
  );
};

export default PublicRoute;
