import React, { useState } from "react";
import cn from "classnames";
import styles from "./OrderImageIndex.module.sass";
import Control from "../OrderImageIndex/OrderControl";
import Checkbox from "../../../../../../components/Checkbox";

const OrderImageIndex = ({
  className,
  item,
  value,
  onChange,
  released,
  withoutCheckbox,
  handleDelete,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = () => {
    onChange();
    setVisible(!visible);
  };

  const renderMedia = () => {
    if (item.media_type === "image") {
      return (
        <img
          srcSet={`${item.media_url} 2x`}
          src={item.media_url}
          alt="Product Image"
          className={cn(styles.image, styles.media)}
        />
      );
    } else if (item.media_type === "video") {
      return (
        <video
          className={cn(styles.video, styles.media)}
          src={item.media_url}
          type="video/mp4"
          controls="controls"
        ></video>
      );
    } else {
      return null;
    }
  };

  return (
    <div
      className={cn(styles.product, className, {
        [styles.active]: visible,
      })}
    >
      <div className={styles.preview}>
        {!withoutCheckbox && (
          <Checkbox
            className={styles.checkbox}
            classCheckboxTick={styles.checkboxTick}
            value={value}
            onChange={() => handleClick()}
          />
        )}
        <Control
          handleDelete={handleDelete}
          item={item}
          className={styles.control}
        />
        {renderMedia()}
      </div>
    </div>
  );
};

export default OrderImageIndex;
