import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  DatePicker,
  Radio,
  Space,
  message,
  Select,
  ConfigProvider,
  Divider,
} from "antd";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import moment from "moment";
import dayjs from "dayjs";
import cn from "classnames";
import styles from "./NewCustomFilter.module.sass";
import Modal from "../../../../components/Modal";
import useDarkMode from "use-dark-mode";
import {
  GET_CUSTOM_FILTER_BY_ID,
  CREATE_CUSTOM_FILTER,
  UPDATE_CUSTOM_FILTER,
  GET_FETCH_TAG_SUGGESTION,
  GET_ORDER_FILTER_OPTIONS_BY_TYPE,
} from "../../../../API/Orders";
import Loader from "../../../../components/Loader";

const { RangePicker } = DatePicker;
const dateFormat = "YYYY-MM-DD";
const dateFormatOne = "YYYY-MM-DD";

const defaultInitialValues = {
  filterName: "",
  fulfillmentAt: null,
  fromDate: null,
  toDate: null,
  valueFilterByDate: 0,
  deliveryStatus: [],
  deliveryMethod: [],
  fulfillmentStatus: [],
  taggedWith: [],
  notTaggedWith: [],
  financialStatus: [],
  appName: [],
  channelName: [],
  returnStatus: [],
  risk: [],
  shopifyOrderStatus: [],
};

const NewCustomFilter = ({
  isNewFilterModalOpen,
  handleNewFilterModalOk,
  handleNewFilterModalCancel,
  editFilterId,
  fetchCustomFilters,
}) => {
  const [initialValues, setInitialValues] = useState(defaultInitialValues);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [valueFilterByDate, setValueFilterByDate] = useState(0);
  const [fulfilmentOptions, setFulfilmentOptions] = useState([]);
  const [deliveryStatusOptions, setDeliveryStatusOptions] = useState([]);
  const [deliveryMethodOptions, setDeliveryMethodOptions] = useState([]);
  const [financialStatusOptions, setFinancialStatusOptions] = useState([]);
  const [optionsApp, setOptionsApp] = useState([]);
  const [optionsRisk, setOptionsRisk] = useState([]);
  const [shopifyOrderStatus, setShopifyOrderStatus] = useState([]);
  const [optionsReturn, setOptionsReturn] = useState([]);
  const [taggedWithSuggestions, setTaggedWithSuggestions] = useState([]);
  const [notTaggedWithSuggestions, setNotTaggedWithSuggestions] = useState([]);

  useEffect(() => {
    const fetchFilterDetails = async () => {
      try {
        if (editFilterId) {
          setLoading(true);
          const response = await GET_CUSTOM_FILTER_BY_ID(editFilterId);
          const data = response.data;
          setInitialValues(mapFilterData(data));

          setFromDate(data.fromDate);
          setToDate(data.toDate);

          const value = getValueFilterByDate(data.fromDate, data.toDate);
          setValueFilterByDate(value);
        } else {
          setInitialValues(defaultInitialValues);
          setFromDate(null);
          setToDate(null);
          setValueFilterByDate(0);
        }
      } catch (error) {
        message.error("Failed to fetch filter details: " + error.message);
      } finally {
        setLoading(false);
      }
    };
    fetchFilterDetails();
    fetchFilterOptions();
  }, [editFilterId]);

  const mapFilterData = (data) => ({
    filterName: data.custom_filter_name || "",
    fulfillmentAt: data.fulfillmentAt,
    fromDate: data.fromDate,
    toDate: data.toDate,
    fulfillmentStatus: mapArrayToNames(data.fulfillmentStatus),
    taggedWith: mapArrayToNames(data.taggedWith),
    notTaggedWith: mapArrayToNames(data.notTaggedWith),
    deliveryStatus: mapArrayToNames(data.deliveryStatus),
    deliveryMethod: mapArrayToNames(data.deliveryMethod),
    financialStatus: mapArrayToNames(data.financialStatus),
    appName: mapArrayToNames(data.appName),
    channelName: mapArrayToNames(data.channelName),
    returnStatus: mapArrayToNames(data.returnStatus),
    risk: mapArrayToNames(data.riskLevel),
    shopifyOrderStatus: mapArrayToNames(data.shopifyOrderStatus),
  });
  const mapArrayToNames = (array) =>
    array ? array.map((item) => item.name) : [];

  const getValueFilterByDate = (fromDate, toDate) => {
    if (!fromDate || !toDate) return 0;
    const start = moment(fromDate);
    const end = moment(toDate);
    const diffDays = end.diff(start, "days");

    if (diffDays === 0) return 1; // Today
    if (diffDays === 7) return 2; // Last 7 days
    if (diffDays === 30) return 3; // Last 30 days
    if (diffDays === 90) return 4; // Last 90 days
    if (diffDays === 365) return 5; // Last 365 days
    return 6; // Custom
  };

  const validationSchema = Yup.object().shape({
    filterName: Yup.string()
      .required("Filter name is required")
      .max(50, "Filter name must be 50 characters or less"),
  });
  const fetchFilterOptions = async () => {
    try {
      const data = await GET_ORDER_FILTER_OPTIONS_BY_TYPE();

      const mapOptions = (options) =>
        Object.entries(options || {}).map(([key, value]) => ({
          label: key,
          value,
        }));

      setFulfilmentOptions(mapOptions(data?.fulfillmentStatus));
      setDeliveryStatusOptions(mapOptions(data?.deliveryStatus));
      setDeliveryMethodOptions(mapOptions(data.deliveryMethod));
      setFinancialStatusOptions(mapOptions(data?.financialStatus));
      setOptionsApp(mapOptions(data?.app));
      setOptionsRisk(mapOptions(data?.risk));
      setOptionsReturn(mapOptions(data?.returnStatus));
      setShopifyOrderStatus(mapOptions(data?.shopifyOrderStatus));
    } catch (error) {
      message.error("Failed to fetch filter options:", error);
    }
  };

  const handleTaggedWithSearch = async (value) => {
    setLoading(true);
    try {
      const response = await GET_FETCH_TAG_SUGGESTION(value);
      const suggestions = response.data.map((item) => item.tag_name.trim());
      setTaggedWithSuggestions(suggestions);
    } catch (error) {
      message.error("Failed to fetch suggestions for taggedWith:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleNotTaggedWithSearch = async (value) => {
    setLoading(true);
    try {
      const response = await GET_FETCH_TAG_SUGGESTION(value);
      const suggestions = response.data.map((item) => item.tag_name.trim());
      setNotTaggedWithSuggestions(suggestions);
    } catch (error) {
      message.error("Failed to fetch suggestions for notTaggedWith:", error);
    } finally {
      setLoading(false);
    }
  };
  const handleSubmit = async (values, { resetForm }) => {
    try {
      const payload = {
        filterName: values.filterName,
        deliveryStatus:
          values.deliveryStatus.length > 0 ? values.deliveryStatus : undefined,
        deliveryMethod:
          values.deliveryMethod.length > 0 ? values.deliveryMethod : undefined,
        fulfillmentAt: values.fulfillmentAt,
        fromDate: values.fromDate,
        toDate: values.toDate,
        fulfillmentStatus:
          values.fulfillmentStatus.length > 0
            ? values.fulfillmentStatus
            : undefined,
        taggedWith:
          values.taggedWith.length > 0 ? values.taggedWith : undefined,
        notTaggedWith:
          values.notTaggedWith.length > 0 ? values.notTaggedWith : undefined,
        financialStatus:
          values.financialStatus.length > 0
            ? values.financialStatus
            : undefined,
        appName: values.appName.length > 0 ? values.appName : undefined,
        channelName:
          values.channelName.length > 0 ? values.channelName : undefined,
        returnStatus:
          values.returnStatus.length > 0 ? values.returnStatus : undefined,
        risk: values.risk.length > 0 ? values.risk : undefined,
        shopifyOrderStatus:
          values.shopifyOrderStatus.length > 0
            ? values.shopifyOrderStatus
            : undefined,
      };

      const filteredPayload = Object.fromEntries(
        Object.entries(payload).filter(([_, v]) => v !== undefined)
      );

      if (editFilterId) {
        await UPDATE_CUSTOM_FILTER(editFilterId, filteredPayload);
      } else {
        await CREATE_CUSTOM_FILTER(filteredPayload);
      }
      handleNewFilterModalOk();
      fetchCustomFilters();
      resetForm();
      handleNewFilterModalCancel();
    } catch (error) {
      message.error("Failed to submit filter: " + error.message);
    }
  };
  const darkMode = useDarkMode(false);
  const themeConfig = {
    token: {
      colorPrimary: "#1890ff",
      colorTextBase: darkMode.value ? "#ffffff" : "#000000",
      colorBgContainer: darkMode.value ? "#1f1f1f" : "#ffffff",
      colorBgElevated: darkMode.value ? "#2f2f2f" : "#ffffff", // Background for dropdown
      colorText: darkMode.value ? "#ffffff" : "#000000", // Text color in dropdown
      buttonBoxShadow: darkMode.value ? "none" : "0 2px 0 rgb(230, 247, 255)",
    },
  };
  const buttonStyles = {
    boxShadow: darkMode.value ? "none" : "0 2px 0 rgb(230, 247, 255)",
  };
  return (
    <ConfigProvider theme={themeConfig}>
      <Modal
        outerClassName={styles.outer}
        visible={isNewFilterModalOpen}
        onClose={handleNewFilterModalCancel}
        onOk={handleNewFilterModalOk}
      >
        <div className={styles.details}>
          <div className={cn("title-purple", styles.title)}>
            {editFilterId ? "Edit Custom Filter" : "New Custom Filter"}
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <Divider />

              {loading && <Loader className={styles.loader} />}
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                enableReinitialize
                onSubmit={handleSubmit}
              >
                {({ values, setFieldValue, errors, touched }) => {
                  const onChange = (e) => {
                    const selectedValue = e.target.value;
                    setValueFilterByDate(selectedValue);

                    const now = moment();
                    let fromDate, toDate;

                    switch (selectedValue) {
                      case 1: // Today
                        fromDate = now.startOf("day");
                        toDate = now.endOf("day");
                        break;
                      case 2: // Last 7 days
                        fromDate = now
                          .clone()
                          .subtract(6, "days")
                          .startOf("day");
                        toDate = now.endOf("day");
                        break;
                      case 3: // Last 30 days
                        fromDate = now
                          .clone()
                          .subtract(29, "days")
                          .startOf("day");
                        toDate = now.endOf("day");
                        break;
                      case 4: // Last 90 days
                        fromDate = now
                          .clone()
                          .subtract(89, "days")
                          .startOf("day");
                        toDate = now.endOf("day");
                        break;
                      case 5: // Last 365 days
                        fromDate = now
                          .clone()
                          .subtract(364, "days")
                          .startOf("day");
                        toDate = now.endOf("day");
                        break;
                      default:
                        fromDate = null;
                        toDate = null;
                    }

                    if (fromDate && toDate) {
                      setFromDate(fromDate.toISOString());
                      setToDate(toDate.toISOString());
                      setFieldValue("fromDate", fromDate.toISOString());
                      setFieldValue("toDate", toDate.toISOString());
                    } else {
                      setFromDate(null);
                      setToDate(null);
                      setFieldValue("fromDate", null);
                      setFieldValue("toDate", null);
                    }
                  };
                  const onChangeCustomDate = (dates) => {
                    if (dates && dates.length === 2) {
                      const [start, end] = dates;

                      const fromDate = start.format("YYYY-MM-DD");
                      const toDate = end.format("YYYY-MM-DD");
                      setFromDate(fromDate);
                      setToDate(toDate);

                      setFromDate(fromDate);
                      setToDate(toDate);
                      setFieldValue("fromDate", fromDate);
                      setFieldValue("toDate", toDate);
                      setValueFilterByDate(6); // Set to custom
                    } else {
                      setFromDate(null);
                      setToDate(null);
                      setFieldValue("fromDate", null);
                      setFieldValue("toDate", null);
                      setValueFilterByDate(0);
                    }
                  };
                  const dateOptions = [
                    { value: 1, label: "Today" },
                    { value: 2, label: "Last 7 days" },
                    { value: 3, label: "Last 30 days" },
                    { value: 4, label: "Last 90 days" },
                    { value: 5, label: "Last 365 days" },
                    { value: 6, label: "Custom" },
                  ];

                  return (
                    <Form>
                      <div className="flex justify-between gap-5">
                        <div className="w-[45%]">
                          <div className="mb-[15px]">
                            <p className="mb-[5px]">
                              <label htmlFor="filterName">Filter name :</label>
                            </p>
                            <Field name="filterName">
                              {({ field, meta }) => (
                                <div>
                                  <Input {...field} />
                                  {meta.touched && meta.error && (
                                    <div className="text-red-500 text-xs">
                                      {meta.error}
                                    </div>
                                  )}
                                </div>
                              )}
                            </Field>
                          </div>
                          <div className="mb-[15px]">
                            <p className="mb-[5px]">
                              {" "}
                              <label htmlFor="fulfillmentStatus">
                                Fulfillment status :
                              </label>
                            </p>
                            <Field name="fulfillmentStatus">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Please select"
                                  onChange={(value) =>
                                    setFieldValue("fulfillmentStatus", value)
                                  }
                                >
                                  {fulfilmentOptions.map((option) => (
                                    <Select.Option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </div>
                          <div className="mb-[15px]">
                            <p className="mb-[5px]">
                              <label htmlFor="taggedWith">Tagged with :</label>
                            </p>
                            <Field name="taggedWith">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Search and select tags"
                                  onSearch={handleTaggedWithSearch}
                                  onChange={(value) =>
                                    setFieldValue("taggedWith", value)
                                  }
                                >
                                  {taggedWithSuggestions.map((tag, index) => (
                                    <Select.Option key={index} value={tag}>
                                      {tag}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </div>
                          <div className="mb-[15px]">
                            <p className="mb-[15px]">
                              <label htmlFor="notTaggedWith">
                                Not tagged with :
                              </label>
                            </p>
                            <Field name="notTaggedWith">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Search and select tags"
                                  onSearch={handleNotTaggedWithSearch}
                                  onChange={(value) =>
                                    setFieldValue("notTaggedWith", value)
                                  }
                                >
                                  {notTaggedWithSuggestions.map(
                                    (tag, index) => (
                                      <Select.Option key={index} value={tag}>
                                        {tag}
                                      </Select.Option>
                                    )
                                  )}
                                </Select>
                              )}
                            </Field>
                          </div>

                          <div>
                            <p>
                              <label htmlFor="valueFilterByDate">
                                Date range :
                              </label>
                            </p>
                            <br />
                            <Radio.Group
                              value={valueFilterByDate}
                              onChange={onChange}
                            >
                              <Space direction="vertical">
                                {dateOptions.map((option) => (
                                  <Radio
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                    {option.value === 6 &&
                                      valueFilterByDate === 6 && (
                                        <Space direction="vertical" size={12}>
                                          <RangePicker
                                            format={dateFormat}
                                            onChange={onChangeCustomDate}
                                            value={[
                                              fromDate
                                                ? dayjs(
                                                    new Date(fromDate)
                                                      .toISOString()
                                                      .slice(0, 19)
                                                  )
                                                : null,
                                              toDate
                                                ? dayjs(
                                                    new Date(toDate)
                                                      .toISOString()
                                                      .slice(0, 19)
                                                  )
                                                : null,
                                            ]}
                                          />
                                        </Space>
                                      )}
                                  </Radio>
                                ))}
                                <Button
                                  style={buttonStyles}
                                  type="primary"
                                  onClick={() => {
                                    setValueFilterByDate(0);
                                    setFromDate(null);
                                    setToDate(null);
                                    setFieldValue("fromDate", null);
                                    setFieldValue("toDate", null);
                                  }}
                                >
                                  Clear
                                </Button>
                              </Space>
                            </Radio.Group>
                          </div>
                        </div>
                        <div className="w-[45%]">
                          <div className="mb-[15px]">
                            <p className="mb-[5px]">
                              <label htmlFor="deliveryStatus">
                                Delivery Status :
                              </label>
                            </p>
                            <Field name="deliveryStatus">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Please select"
                                  onChange={(value) =>
                                    setFieldValue("deliveryStatus", value)
                                  }
                                >
                                  {deliveryStatusOptions.map((option) => (
                                    <Select.Option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </div>
                          <div className="mb-[15px]">
                            <p className="mb-[5px]">
                              <label htmlFor="deliveryMethod">
                                Delivery Method :
                              </label>
                            </p>
                            <Field name="deliveryMethod">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Please select"
                                  onChange={(value) =>
                                    setFieldValue("deliveryMethod", value)
                                  }
                                >
                                  {deliveryMethodOptions.map((option) => (
                                    <Select.Option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </div>

                          <div className="mb-[15px]">
                            <p className="mb-[5px]">
                              <label htmlFor="financialStatus">
                                Financial Status :
                              </label>
                            </p>
                            <Field name="financialStatus">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Please select"
                                  onChange={(value) =>
                                    setFieldValue("financialStatus", value)
                                  }
                                >
                                  {financialStatusOptions.map((option) => (
                                    <Select.Option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </div>

                          <div className="mb-[15px]">
                            <p className="mb-[5px]">
                              {" "}
                              <label htmlFor="appName">App name :</label>
                            </p>
                            <Field name="appName">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Please select"
                                  onChange={(value) =>
                                    setFieldValue("appName", value)
                                  }
                                >
                                  {optionsApp.map((option) => (
                                    <Select.Option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </div>

                          <div className="mb-[15px]">
                            <p className="mb-[5px]">
                              <label htmlFor="channelName">
                                Channel name :
                              </label>
                            </p>
                            <Field name="channelName">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Please select"
                                  onChange={(value) =>
                                    setFieldValue("channelName", value)
                                  }
                                >
                                  {optionsApp.map((option) => (
                                    <Select.Option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </div>

                          <div className="mb-[15px]">
                            <p className="mb-[5px]">
                              <label htmlFor="returnStatus">
                                Return status :
                              </label>
                            </p>
                            <Field name="returnStatus">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Please select"
                                  onChange={(value) =>
                                    setFieldValue("returnStatus", value)
                                  }
                                >
                                  {optionsReturn.map((option) => (
                                    <Select.Option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </div>

                          <div className="mb-[15px]">
                            <p className="mb-[5px]">
                              <label htmlFor="risk">Risk :</label>
                            </p>
                            <Field name="risk">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Please select"
                                  onChange={(value) =>
                                    setFieldValue("risk", value)
                                  }
                                >
                                  {optionsRisk.map((option) => (
                                    <Select.Option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </div>
                          <div className="mb-[15px]">
                            <p className="mb-[5px]">
                              <label htmlFor="shopifyOrderStatus">
                                Order Status :
                              </label>
                            </p>
                            <Field name="shopifyOrderStatus">
                              {({ field }) => (
                                <Select
                                  {...field}
                                  mode="multiple"
                                  allowClear
                                  style={{ width: "100%" }}
                                  placeholder="Please select"
                                  onChange={(value) =>
                                    setFieldValue("shopifyOrderStatus", value)
                                  }
                                >
                                  {shopifyOrderStatus.map((option) => (
                                    <Select.Option
                                      key={option.value}
                                      value={option.value}
                                    >
                                      {option.label}
                                    </Select.Option>
                                  ))}
                                </Select>
                              )}
                            </Field>
                          </div>
                          <div className="mb-[15px]">
                            <p className="mb-[5px]">
                              <label htmlFor="fulfillmentAt">
                                Fulfilled at :
                              </label>
                            </p>
                            <Field name="fulfillmentAt">
                              {({ field }) => (
                                <div>
                                  <DatePicker
                                    style={{ width: "100%" }}
                                    format={dateFormatOne}
                                    value={
                                      values.fulfillmentAt
                                        ? dayjs(values.fulfillmentAt)
                                        : null
                                    }
                                    onChange={(date) => {
                                      const formattedDate = date
                                        ? date.toISOString()
                                        : null;
                                      setFieldValue(
                                        "fulfillmentAt",
                                        formattedDate
                                      );
                                    }}
                                  />
                                </div>
                              )}
                            </Field>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Space
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                          }}
                        >
                          <Button onClick={handleNewFilterModalCancel}>
                            Cancel
                          </Button>
                          <Button
                            style={buttonStyles}
                            type="primary"
                            htmlType="submit"
                          >
                            Save
                          </Button>
                        </Space>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </Modal>
    </ConfigProvider>
  );
};

export default NewCustomFilter;
