import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import permissionsService from "./accessControllService";

export const fetchUserPermissions = createAsyncThunk(
  "permissions/fetchUserPermissions",
  async (user, thunkAPI) => {
    try {
      return await permissionsService.permissions(user);
    } catch (err) {
      const message = err.response || err.response.data;
      return thunkAPI.rejectWithValue(message);
    }
  }
);
const permissionsSlice = createSlice({
  name: "permissions",
  initialState: {
    permissions: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserPermissions.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(fetchUserPermissions.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.permissions = action.payload.data.data;
      })
      .addCase(fetchUserPermissions.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default permissionsSlice.reducer;
