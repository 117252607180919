import axios from "axios";
import custom_axios from "../../axios";
const env = process.env;

const permissions = async () => {
  const response = await custom_axios.get("/auth_module/privileges/");
  return response;
};

const permissionsService = {
  permissions,
};
export default permissionsService;
