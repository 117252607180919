import React, { useEffect, useState } from "react";

import cn from "classnames";
import styles from "./Tags.module.sass";
import Item from "../../../Item";
import { get_tags_settings } from "../../../../../API/TagsSettings";

import Table from "./Table/index";
const Tags = ({ className }) => {
  const [settings, setSettings] = useState([]);

  const fetchTags = async () => {
    const res = await get_tags_settings();
    setSettings(res.data);
  };

 
  useEffect(() => {
    fetchTags();
  }, []);

  return (
    <Item
      className={cn(styles.card, className)}
      title="Tags"
      classTitle="title-purple"
    >
    
      <div className={styles.list}>
        <Table data={settings} className={styles.table} />
      </div>
    </Item>
  );
};

export default Tags;
