import custom_axios from "../axios";

export const GET_ALL_EMPLOYEES = async () => {
  try {
    const response = await custom_axios.get(`/employee/all`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching employees:", error);
    throw error;
  }
};
export const GET_EMPLOYEE = async () => {
  try {
    const response = await custom_axios.get(`/employee`);
    return response.data.data;
  } catch (error) {
    console.error("Error fetching employee details:", error);
    throw error;
  }
};

export const SEND_SELECTED_EMPLOYEE_ID = async (orderIds) => {
  if (!orderIds || orderIds.length === 0) {
    throw new Error("No order IDs provided");
  }
  try {
    const response = await custom_axios.put(`/employee/order-unassign`, {
      order_id_list: orderIds,
    });
    return response;
  } catch (error) {
    console.error("Error unassigning orders:", error);
    throw error;
  }
};

export const ASSIGN_ORDERS = async (formData) => {
  try {
    const response = await custom_axios.post(`/employee/order-assignment`, {
      assign_to: formData.assign_to,
      order_id_list: formData.order_id_list,
    });
    return response;
  } catch (error) {
    console.error("Error un-assigning orders:", error);
    throw error;
  }
};
