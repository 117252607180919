import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./View.module.sass";
import Item from "../../Item";

import Table from "../Table";
import { get_all_cameras } from "../../../../API/Camera";
import { useMenu } from "../../../../context/Roles/MenuContext";
import { Formik, Form } from "formik";
import { useFormikContext } from "../../context/SettingsContext";
import { getStoredCameras } from "../../../../utils/cameraUtils";

const View = () => {
  const { formikRef } = useFormikContext();
  const [visible, setVisible] = useState(false);

  const { setSubActiveTab } = useMenu();
  const [cameras, setCamras] = useState([]);
  const handleCreateCamera = () => {
    setSubActiveTab(2);
  };

  const updateCamerasWithLocalStorage = async () => {
    try {
      // Fetch cameras from the API
      const res = await get_all_cameras();
      const apiCameras = res.data;

      // Get cameras from local storage
      const storedCameras = getStoredCameras();
      const storedCamerasMap = new Map(
        storedCameras.map((camera) => [camera.id, camera])
      );

      // Update API cameras with brand names from local storage
      const updatedCameras = apiCameras.map((camera) => {
        const storedCamera = storedCamerasMap.get(camera.id);
        return {
          ...camera,
          brand: storedCamera ? storedCamera.brand : "Not linked",
        };
      });

      return updatedCameras;
    } catch (error) {
      console.error("Error updating cameras with local storage data:", error);
      throw error;
    }
  };
  const fetchAllCamaras = async () => {
    const updatedCameras = await updateCamerasWithLocalStorage();
    setCamras(updatedCameras);
  };

  useEffect(() => {
    fetchAllCamaras();
  }, []);
  const initialValues = {
    name: "",
  };
  return (
    <Item className={cn(styles.card)} title="Camera" classTitle="title-green">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleCreateCamera}
        innerRef={formikRef}
      >
        {({ isSubmitting, setFieldValue, values, errors, touched }) => (
          <Form>{/* Empty form to trigger formik ref */}</Form>
        )}
      </Formik>
      <div className={styles.list}>
        <Table
          data={cameras}
          className={styles.table}
          activeTable={visible}
          setActiveTable={setVisible}
        />
      </div>
    </Item>
  );
};

export default View;
