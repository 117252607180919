import custom_axios from "../axios";

export const verify_otp = async (formData) => {
  try {
    const data = {
      email: formData.email,
      otp: formData.otp,
      full_name: formData.full_name,
      store_logo: formData.store_logo,
    };
    const response = await custom_axios.post(`/auth/verify-otp`, data, {
      headers: {
        Authorization: `Bearer ${formData.token}`,
        "Content-Type": "application/json",
      },
    });
    return response;
  } catch (error) {
    console.error("Failed to verify otp:", error);
    throw error;
  }
};


