import React from "react";
import styles from "./Parameter.module.sass";
import Tooltip from "../../../../../../components/Tooltip";
import cn from "classnames";
const Parameter = ({ refund }) => {
  return (
    <div className={styles.parameter}>
      <div className={styles.title}>
        {refund?.title}

        {refund?.tooltip && (
          <Tooltip
            className={styles.tooltip}
            title={refund.tooltip}
            icon="info-stroke"
            place="top"
          />
        )}
      </div>
      {refund.discount &&
        (refund.discount ? (
          <div className={cn(styles.yes, "mr-[15px]")}>{refund.discount}</div>
        ) : (
          <div className={styles.no}>No code</div>
        ))}
      {refund?.content && (
        <div className={styles.content}>{refund?.content}</div>
      )}
    </div>
  );
};

export default Parameter;
