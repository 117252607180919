import React, { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./OrderPreview.module.sass";
import Panel from "../Panel";
import Slider from "react-slick";
import Icon from "../../../../../../components/Icon";
import moment from "moment";
import cn from "classnames";

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const OrderPreview = ({
  visible,
  onClose,
  media,
  download,
  title,
  figcaption,
}) => {
  const [zoomLevel, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);

  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);
    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, [escFunction]);

  useEffect(() => {
    if (visible) {
      const target = document.querySelector("#modal-product");
      disableBodyScroll(target);
    } else {
      clearAllBodyScrollLocks();
    }
  }, [visible]);

  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
    setPosition({ x: 0, y: 0 });
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => Math.max(1, prevZoomLevel - 0.1));
    setPosition({ x: 0, y: 0 });
  };

  const handleMouseDown = (e) => {
    setIsDragging(true);
  };

  const handleMouseMove = (e) => {
    if (isDragging && zoomLevel > 1) {
      setPosition((prevPosition) => {
        const newX = prevPosition.x + e.movementX;
        const newY = prevPosition.y + e.movementY;
        const maxOffset = 100 * (zoomLevel - 1);
        return {
          x: Math.max(-maxOffset, Math.min(maxOffset, newX)),
          y: Math.max(-maxOffset, Math.min(maxOffset, newY)),
        };
      });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-right" size="24" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-left" size="24" />
      </SlickArrow>
    ),
  };

  return createPortal(
    visible && (
      <div id="modal-product" className={styles.modal}>
        <div className={styles.outer}>
          <OutsideClickHandler onOutsideClick={onClose}>
            <Panel
              title={title}
              onClose={onClose}
              download={download}
              galleryPanel={media
                .filter((x) => x.media_type === "image")
                .map((x) => x.media_url)}
            />
            <div className={styles.wrapper}>
              <Slider className="photo-slider" {...settings}>
                {media.map((x, index) => (
                  <div className={styles.slide} key={index}>
                    <div className={styles.preview}>
                      {x.media_type === "image" ? (
                        <img
                          src={x.media_url}
                          alt="Gallery"
                          style={{
                            transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
                            cursor: zoomLevel > 1 ? "grab" : "default",
                            transition: isDragging
                              ? "none"
                              : "transform 0.1s ease-out",
                          }}
                          onMouseDown={handleMouseDown}
                          onMouseMove={handleMouseMove}
                          onMouseUp={handleMouseUp}
                          onMouseLeave={handleMouseUp}
                        />
                      ) : (
                        <video
                          src={x.media_url}
                          type="video/mp4"
                          controls="controls"
                        ></video>
                      )}
                    </div>
                  </div>
                ))}
              </Slider>
              {figcaption && (
                <div
                  className={styles.figcaption}
                  style={{
                    transform: "translateX(1%)",
                    opacity: "0.7",
                    borderRadius: "20px",
                    width: "80%",
                    margin: "0 auto",
                    background: "#272b30",
                    marginTop: "27px",
                    marginBottom: "20px",
                    // padding: "10px",
                    overflow: "hidden",
                  }}
                >
                  <div className="flex items-center justify-between ml-[20px] mr-[20px]">
                    {media.map((x, index) => (
                      <p key={index} className="text-white">
                        Captured By :{" "}
                        {x.employee?.full_name ? x.employee?.full_name : "N/A"}
                      </p>
                    ))}
                    <div className="flex justify-center mt-2">
                      <button
                        className={cn(
                          "text-white hover:text-gray-400 transition-colors duration-300",
                          styles.Zoomin
                        )}
                        onClick={handleZoomIn}
                      >
                        <Icon name="zoomin" size="24" />
                      </button>
                      <button
                        className={cn(
                          "text-white hover:text-gray-400 transition-colors duration-300",
                          styles.Zoomin
                        )}
                        onClick={handleZoomOut}
                      >
                        <Icon name="zoomOut" size="24" />
                      </button>
                    </div>
                    {media.map((x, index) => (
                      <p key={index} className="text-white">
                        TimeStamp :{" "}
                        {moment(x.created_at).format("D-M-YYYY h:mm A")
                          ? moment(x.created_at).format("D-M-YYYY h:mm A")
                          : "N/A"}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </OutsideClickHandler>
        </div>
      </div>
    ),
    document.body
  );
};

export default OrderPreview;
