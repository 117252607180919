import Dividers from "../../LandingPage/components/Dividers/Dividers";
import Layout from "../../LandingPage/components/Layout/Layout";
import Main from "../../LandingPage/templates/HomePage/Main/Main";
import styles from "./privacy.module.sass";
const Privacy = () => {
  return (
    <Layout darkHeader>
      <Dividers>
        <div style={{ padding: "100px 40px" }}>
          <h1>Privacy Policy</h1>
          <p>
            Morfdesk LLP ("Morfdesk", "Pick Pack Go"), a limited liability
            partnership registered under the Limited Liability Partnership Act,
            2008, with its registered office at #126, 2nd Floor, Sector-13,
            Karnal, Haryana-132001, understands the importance of protecting and
            safeguarding your privacy. We have therefore established and created
            this privacy policy ("Privacy Policy") to inform you about the type
            of personal information we collect from you, how we use it, whom we
            share it with, how we store and protect it, and what options you
            have to limit our use of the personal data related to you. In this
            Privacy Policy, "personal data" means information or pieces of
            information that could allow you to be identified as a person
            ("Personal Data").
          </p>
          <h3>1. APPLICABILITY</h3>
          <p>
            This Privacy Policy applies to your use of our website
            https://pickpackgo.io/ and all products and services that we offer
            you via our website (together called "Website").
          </p>
          <h3>2. CONSENT</h3>
          <p>
            Morfdesk will not collect, use, or disclose your personal data
            without your prior consent. Therefore, we ask you to agree to this
            Privacy Policy before you can use our Website. By accessing or using
            our Website or by otherwise giving us your information, you will be
            deemed to have the capacity to enter into a legally binding contract
            as per the jurisdiction from which you are accessing the Website or
            giving us the information and you consent to the collection,
            storage, and processing of your personal information as stated in
            this Privacy Policy. You can withdraw your consent at any time by
            sending an email to security@morfdesk.com or by using the form
            available on this Website.
          </p>
          <h3>3. COLLECTION AND USE OF PERSONAL DATA</h3>
          <p>
            Upon prior consent, we may collect, store, use, and disclose
            information about individuals that may constitute Personal Data to
            provide our products and services for lawful, explicit, and
            legitimate purposes and for further processing of Personal Data
            consistent with those purposes. When you provide information that
            enables us to respond to your queries, we will, wherever permissible
            by relevant laws, collect the information for the purposes described
            in this Privacy Policy. Morfdesk will not collect any Personal Data
            about you unless you voluntarily provide this information to us.
            Some of this information that you may provide will identify you
            personally, either alone or in combination with other information
            available to us. We may collect the Personal Data you choose to
            provide when you fill out forms or raise a query through our
            Website, send emails, call us, or write to us. If you contact us, we
            may keep a record of such correspondence. We may also collect
            Personal Data by asking you to complete surveys that we would use
            for research purposes, although you have the right not to respond to
            them. We may collect your personal information when you visit the
            Website. The list of Personal Data collected through the Website is
            notified to you at the time of granting permission to share the
            relevant information. Such information may include log data, cookie
            data, device information, demographic information, behavioural
            information, and sensitive personal data. We may use automated
            technologies including (where available) the use of web server logs
            to collect IP addresses, device details, browser types, cookies, and
            web beacons which might help us in system administration, filter
            traffic, and improve the effectiveness of the Website and our
            marketing activities. When you provide us with your mobile phone
            number, you consent to the use of your mobile phone number for the
            purposes identified in this Privacy Policy. If you choose to refrain
            from receiving any call/text notifications from us on your mobile,
            we will not use your mobile number for such purposes unless required
            to meet legal requirements or legitimate business purposes.
          </p>
          <h3>4. SHARING OF PERSONAL DATA</h3>
          <p>
            We process your personal data through AWS and MongoDB, which are the
            main cloud hosting providers of Morfdesk. We have office operations
            in an international location i.e. [US, UK], and we share information
            between our group companies for administrative and legitimate
            business purposes. Where required or permitted by law, information
            may be provided to others, such as statutory authorities, government
            institutions, regulators, and law enforcement agencies for
            compliance with legal requirements. We do not share personal
            information about you with third parties, except: (a) to provide
            products or services you have requested; (b) when we have your
            permission; or (c) under the following circumstances: We may provide
            the information to trusted entities who work on behalf of or with
            Morfdesk as per the provisions of data privacy laws and under strict
            confidentiality agreements. These entities may use your Personal
            Data to help us communicate with you for legitimate business
            purposes. However, these companies do not have any independent right
            to further share or disseminate this information. We may use the
            information to respond to subpoenas, court orders, or legal
            processes, or to establish or exercise our legal rights or defend
            against legal claims. We may share information where we believe it
            is necessary to investigate, prevent, or take action against any
            illegal activities, suspected fraud, situations involving potential
            threats to the physical safety of any person, or as otherwise
            required by national and international legislation. We may share
            information where we believe it is necessary to protect or enforce
            our rights, usage terms, intellectual or physical property, or the
            safety of Morfdesk or associated parties. From time to time, we may
            consider corporate transactions such as a merger, acquisition,
            reorganization, asset sale, or similar. In these instances, we may
            transfer or allow access to information to enable the assessment and
            undertaking of that transaction. If we buy or sell any business or
            assets, personal data may be transferred to third parties involved
            in the transaction.
          </p>
          <h3>5. DATA STORAGE AND RETENTION</h3>
          <p>
            Your personal data is stored in the databases of our trusted
            third-party service providers. We contractually require those third
            parties to keep data secure and confidential, and we do not allow
            them to disclose your Personal Data to others without our
            authorization, or to use it for their own purposes. Morfdesk will
            retain your Personal Data pursuant to business purposes and in line
            with our retention policies or as long as necessary to fulfil the
            purposes we collected it for, including satisfying any legal,
            accounting, or reporting requirements. To determine the appropriate
            retention period for personal data, we consider the amount, nature,
            and sensitivity of the Personal Data, the potential risk of harm
            from unauthorized use or disclosure of your Personal Data, the
            purposes for which we process your Personal Data, and whether we can
            achieve those purposes through other means, and the applicable legal
            requirements.
          </p>
          <h3>6.INDIVIDUAL RIGHTS</h3>
          <p>
            You may have certain rights relating to your Personal Data provided
            for under applicable law. These are the rights to:
          </p>
          <ul>
            <li>
              Request access to your Personal Data and the processing activities
              on the personal data.
            </li>
            <li>
              Request that your Personal Data be rectified if it is inaccurate
              or incomplete.
            </li>
            <li>
              Request erasure of your Personal Data in certain circumstances.
            </li>
            <li>
              Request restriction of the processing of your Personal Data in
              certain circumstances.
            </li>
            <li>
              Object to the processing of your Personal Data in certain
              circumstances.
            </li>
            <li>
              Receive your Personal Data provided to us in a structured,
              commonly used, and machine-readable format in certain
              circumstances.
            </li>
            <li>Lodge a complaint with a relevant authority.</li>
            <li>Withdraw your consent at any time by contacting us.</li>
          </ul>
          <p>
            You have the option to subscribe/opt-in to receive our brochures,
            newsletters, marketing, and research-related materials. To exercise
            the rights outlined above in respect of your Personal Data, please
            contact the details available in the contact section below. We will
            endeavour to respond to you within 3 working days. Please note that
            we may need to retain certain Personal Data for recordkeeping
            purposes and/or to complete any transactions that you began before
            requesting a change or deletion.
          </p>
          <h3>7. PROTECTION OF MINORS' INFORMATION</h3>
          <p>
            The Website is not directed to children under the age of eighteen
            (18) years. We do not knowingly solicit Personal Data from or market
            to anyone under eighteen (18) years of age. If you believe that we
            have received information from a minor, please notify us
            immediately, and we will ensure that reasonable efforts will be made
            by us to delete such information from our database.
          </p>
          <h3>8. EXTERNAL LINKS</h3>
          <p>
            Our Website may contain links to other sites, including social media
            links, event sites, etc., whose information practices may be
            different from ours. You should read such third-party websites'
            privacy notices before interacting or providing any of your Personal
            Information. Morfdesk does not control those sites or their privacy
            practices. We do not endorse or make any representations about
            third-party websites.
          </p>
          <h3>9. COOKIES AND OTHER TECHNOLOGIES</h3>
          <p>
            Our Website uses cookies and similar technologies to facilitate the
            proper functioning of our Websites and to achieve performance. A
            cookie is a text-only string of information that a website transfers
            to the cookie file of the browser on your computer’s hard disk so
            that the website can remember who you are. Please read our Cookie
            Policy for more details about the information we collect through
            cookies when you use this site.
          </p>
          <h3>10. REASONABLE SECURITY PRACTICES & PROCEDURES</h3>
          <p>
            We have put in place reasonable security practices and procedures
            comprising managerial, technical, operational, and physical security
            controls that comply with all applicable laws and regulations to
            protect your Personal Data from unauthorized or inappropriate
            access, loss, alteration, disclosure, damage, and destruction. We
            have put in place procedures to deal with any suspected data
            security breach and will notify you and any applicable
            regulator/supervisory authority of any suspected breach where we are
            legally required to do so. Users are responsible for maintaining the
            secrecy of their own passwords. If you have reason to believe that
            your interaction with us is no longer secure or (for example, if you
            feel that the security of any account you might have with us has
            been compromised) or a Personal Data breach has occurred, please
            immediately notify us by contacting us at security@morfdesk.com.
            Please refer to our breach management policy for more details.
          </p>
          <h3>11. CHANGES TO THIS POLICY</h3>
          <p>Please note that this policy may change from time</p>
          <h3>12. GRIEVANCE OFFICER AND CONTACT DETAILS</h3>
          <p>
            If you have any complaints related to your Personal Data, data
            protection concerns, any communications regarding the enforcement of
            your privacy rights, or questions regarding our privacy policy or
            the practices described herein, you may contact us at:
          </p>
          <div className={styles.address}>
            <p>Morfdesk LLP ("Morfdesk", "Pick Pack Go")</p>
            <p> #126, 2nd Floor, Sector-13, Karnal, Haryana-132001</p>
            <p>Name of the contact: Roshit Gopinath </p>
            <p> Email: security@morfdesk.com</p>{" "}
            <p>
              {" "}
              We will endeavour to respond to your queries and concerns within 3
              working days.
            </p>
          </div>
        </div>
      </Dividers>
     
    </Layout>
  );
};

export default Privacy;
