export const getStoredCameras = () => {
  const cameras = localStorage.getItem("Camera");
  return cameras ? JSON.parse(cameras) : [];
};

export const getCameraById = (cameraId) => {
  const cameras = getStoredCameras();
  return cameras.find((camera) => camera.id === cameraId);
};

export const removeCameraFromStorage = (cameraId) => {
  const cameras = getStoredCameras();
  const updatedCameras = cameras.filter((camera) => camera.id !== cameraId);
  localStorage.setItem("Camera", JSON.stringify(updatedCameras));
};

export const addOrUpdateCameraInStorage = (camera) => {
  const cameras = getStoredCameras();
  const existingIndex = cameras.findIndex((c) => c.id === camera.id);

  if (existingIndex > -1) {
    // Update the existing camera
    cameras[existingIndex] = camera;
  } else {
    // Add new camera
    cameras.push(camera);
  }

  localStorage.setItem("Camera", JSON.stringify(cameras));
};
