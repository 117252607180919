import cn from "classnames";
import styles from "./Details.module.sass";
import Icon from "../../../../../components/Icon";
import Overview from "./Overview";
import Products from "./Products";
import { Link } from "react-router-dom";
import useDarkMode from "use-dark-mode";
const navigation = ["Product", "Comments"];

const Details = ({
  className,
  setValue,
  activeIndex,
  setActiveIndex,
  orderDetails,
  orderNumber,
  handleRemoveAllMedia,
  handleDelete,
  activeTabIndex,
}) => {
  // const handleClick = (index) => {
  //   setActiveIndex(index);
  //   index === 0 && setValue(false);
  //   index === 1 && setValue(true);
  // };
  const darkMode = useDarkMode(false);
  return (
    <div className={cn(styles.details, className)}>
      {/* <div className={styles.head}>
        <div className={styles.nav}>
          {navigation.map((x, index) => (
            <button
              className={cn(styles.link, {
                [styles.active]: index === activeIndex,
              })}
              onClick={() => handleClick(index)}
              key={index}
            >
              {x}
            </button>
          ))}
        </div>
      </div> */}
      <Overview
        orderDetails={orderDetails}
        orderNumber={orderNumber}
        activeTabIndex={activeTabIndex}
      />

      {(activeTabIndex === 0 || activeTabIndex === 3) && (
        <Products
          orderDetails={orderDetails}
          orderNumber={orderNumber}
          handleRemoveAllMedia={handleRemoveAllMedia}
          handleDelete={handleDelete}
        />
      )}
    </div>
  );
};

export default Details;
