import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import cn from "classnames";
import { Divider, Space } from "antd";
import styles from "./GeneratePicklList.module.sass";

import Modal from "../../../../../components/Modal";
import Radio from "../../../../../components/Radio";
import CustomToast from "../../../../../components/CustomToast";
import {
  ASSIGN_ORDERS,
  GET_ALL_EMPLOYEES,
  SEND_SELECTED_EMPLOYEE_ID,
} from "../../../../../API/Employee";

function GeneratePickListPopUp({
  setVisible,
  visible,
  checkedItems,
  updateFilter,
  handleCheckboxChange,
  selectedPickListType,
  orderDetails,
}) {
  const navigate = useNavigate();

  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);

  const checkedIds = Object.keys(checkedItems).filter((id) => checkedItems[id]);
  const showToast = CustomToast();
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await GET_ALL_EMPLOYEES();

        const roles = response.map((item) => ({
          label: `${item.full_name}`,
          value: item.id,
        }));
        roles.push({
          label: `None`,
          value: null,
        });

        setStaffOptions([...roles]);
      } catch (error) {
        showToast("Failed to fetch roles: ", "error");
      }
    };

    fetchRoles();
  }, []);
  const ids = checkedIds.join(",");

  const handleOk = async () => {
    setVisible(false);
    try {
      const formData = {
        assign_to: selectedEmployee,
        order_id_list: checkedIds.map(Number),
      };

      if (selectedEmployee !== null) {
        const unassign_order = await SEND_SELECTED_EMPLOYEE_ID(
          formData.order_id_list
        );
        if (unassign_order.status === 200) {
          const assign_order = await ASSIGN_ORDERS(formData);
          showToast(`Orders successfully assigned.`, "success");
        }
      }

      updateFilter();

      const newCheckedItems = {};
      checkedIds.forEach((id) => (newCheckedItems[id] = false));
      handleCheckboxChange(null, newCheckedItems);

      if (selectedPickListType === "order") {
        navigate("/generatePickList", { state: { checkedItems } });
      } else if (selectedPickListType === "product") {
        navigate("/generatePickListByProduct", { state: { checkedItems } });
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        showToast("Only admin can access", "error");
      } else {
        showToast("Failed to assign orders.", "error");
      }
    }
  };

  return (
    <Modal
      outerClassName={styles.outer}
      visible={visible}
      onClose={() => setVisible(false)}
    >
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>Selected Orders</div>
        <p className={styles.subtitle}>{orderDetails}</p>
        <div className={styles.row}>
          <div className={styles.col}>
            <Divider />
            <p>Only generate picklist PDF</p>
            <span>
              Picklist will be generated for selected orders without changing
              order assignment.
            </span>
            <div className={cn("mt-3")}>
              <button
                className={cn("button", styles.button)}
                onClick={handleOk}
              >
                Generate Picklist
              </button>
            </div>
            <Divider />
            <div className={styles.col}>
              <div className={cn("mb-3")}>
                <p>Generate picklist PDF and assign orders</p>
                <span>
                  Picklist will be generated for selected orders and selected
                  orders can be assigned to a specific user.
                </span>
              </div>
              <Space direction="vertical">
                {staffOptions.map((role) => (
                  <Radio
                    key={role.value}
                    value={selectedEmployee === role.value}
                    onChange={() => setSelectedEmployee(role.value)}
                    content={role.label}
                    name="staff"
                    reverse
                  />
                ))}
              </Space>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default GeneratePickListPopUp;
