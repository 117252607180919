import React, { useState } from "react";
import styles from "./Market.module.sass";
import cn from "classnames";
import Checkbox from "../../../../components/Checkbox";
import Row from "./Row";

const Market = ({ data }) => {
  const [chooseAll, setСhooseAll] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <div className={styles.market}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Checkbox
              className={styles.checkbox}
              value={chooseAll}
              onChange={() => setСhooseAll(!chooseAll)}
            />
          </div>
          <div className={styles.col}>ID</div>
          <div className={styles.col}>Update By</div>
          <div className={styles.col}>Type</div>
          <div className={styles.col}>Date & Time</div>
          <div className={styles.col}>Products</div>
          {/* <div className={styles.col}>Items</div> */}
          <div className={styles.col}>Notes</div>
        </div>

        {data.map((log, index) => (
          <Row
            item={log}
            key={log.id}
            index={index + 1} // Pass the serial number (index + 1)
            value={selectedFilters.includes(log.id)}
            onChange={() => handleChange(log.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default Market;
