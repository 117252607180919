import React, { useState, useRef } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";

import { useReactToPrint } from "react-to-print";

import Row from "./Row";

import { customers } from "../../../../mocks/customers";
import Barcode from "react-barcode";
import { useFormikContext } from "../../context/SettingsContext";

const PrintBarcodes = React.forwardRef(({ barcode_array }, ref) => (
  <div ref={ref}>
    {barcode_array?.map((barcode, index) => (
      <div key={index} className={styles.barcode_container}>
        <div className={styles.barcode_text}>
          {" "}
          <Barcode value={barcode.barcode_value} />
        </div>
        <p>
          {" "}
          Camera:{" "}
          {barcode.cameras.map(
            (camera) => `${camera.name} (${camera.mode})`
          )}{" "}
          {barcode.barcode_value}
        </p>
      </div>
    ))}
  </div>
));
const Table = ({ data, className, activeTable, setActiveTable }) => {
  const { printComponentRef } = useFormikContext();
  const [activeId, setActiveId] = useState(customers[0].id);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.table)}>
        <div className={cn(styles.row, { [styles.active]: activeTable })}>
          <div className={styles.col}>Barcode</div>
          <div className={styles.col}>Purpose</div>
          <div className={styles.col}>Created On</div>
          <div className={cn(styles.col, styles.download_text)}></div>
        </div>
        {data?.map((setting, index) => (
          <Row
            item={setting}
            key={index}
            activeTable={activeTable}
            setActiveTable={setActiveTable}
            activeId={activeId}
            setActiveId={setActiveId}
            value={selectedFilters.includes(setting.id)}
            onChange={() => handleChange(setting.id)}
          />
        ))}
      </div>

      <div style={{ display: "none" }}>
        <PrintBarcodes ref={printComponentRef} barcode_array={data} />
      </div>
    </div>
  );
};

export default Table;
