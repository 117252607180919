import custom_axios from "../axios";

export const EXPORT_ORDERS = async (queryString, payload) => {
  try {
    const response = await custom_axios.post(
      `/order/exportOrder?${queryString}`,
      payload,
      { responseType: "blob" }
    );
    return response.data;
  } catch (error) {
    console.error("Error exporting orders:", error);
    throw error;
  }
};
export const GET_ALL_CUSTOM_FILTERS = async () => {
  try {
    const response = await custom_axios.get(`/custom-filter/all`);
    return response.data;
  } catch (error) {
    console.error("Error fetching custom filters:", error);
    throw error;
  }
};
export const GET_CUSTOM_FILTER_BY_ID = async (id) => {
  try {
    const response = await custom_axios.get(`/custom-filter/${id}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching custom filter by ID:", error);
    throw error;
  }
};
export const CREATE_CUSTOM_FILTER = async (data) => {
  try {
    const response = await custom_axios.post("/custom-filter/create", data);
    return response.data;
  } catch (error) {
    console.error("Error creating custom filter:", error);
    throw error;
  }
};
export const UPDATE_CUSTOM_FILTER = async (id, data) => {
  try {
    const response = await custom_axios.patch(`/custom-filter/${id}`, data);
    return response.data;
  } catch (error) {
    console.error("Error updating custom filter:", error);
    throw error;
  }
};
export const DELETE_CUSTOM_FILTER = async (id) => {
  return custom_axios.delete(`/custom-filter/${id}`);
};
export const GET_ORDER_FILTER_OPTIONS_BY_TYPE = async () => {
  try {
    const response = await custom_axios.get("/order/order-filters");
    return response.data.data;
  } catch (error) {
    console.error("Failed to fetch filter options:", error);
    throw error;
  }
};
export const GET_FETCH_TAG_SUGGESTION = async (tagName) => {
  try {
    const response = await custom_axios.get(
      `/order/all-tags?tag_name=${tagName}`
    );
    return response.data || [];
  } catch (error) {
    console.error("Failed to fetch tag suggestions:", error);
    throw error;
  }
};
export const GENERATE_PICKLIST_BY_ORDER = async (ids) => {
  try {
    const response = await custom_axios.get(
      `/order/generatePickListByIds?ids=${ids}`
    );
    return response.data;
  } catch (error) {
    console.error("Error generating picklist by order:", error);
    throw error;
  }
};

export const GENERATE_PICKLIST_BY_PRODUCT = async (ids) => {
  try {
    const response = await custom_axios.get(
      `order/generatePickListByProduct?ids=${ids}`
    );
    return response.data;
  } catch (error) {
    console.error("Error generating picklist by product:", error);
    throw error;
  }
};
export const GET_ORDERS_CUSTOME_FIELDS = async () => {
  try {
    const response = await custom_axios.get("/custom-fields/all");
    return response.data.data;
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

export const GET_ALL_EMPLOYEE_CUSTOM_FIELDS = async () => {
  try {
    const response = await custom_axios.get(
      `/custom-fields/employee-custom-fields`
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};
export const UPDATE_CUSTOM_FIELDS = async (customFieldIds) => {
  try {
    const response = await custom_axios.put(`/custom-fields`, {
      custom_field_ids: customFieldIds,
    });
    return response.data;
  } catch (error) {
    console.error("Error updating custom fields:", error);
    throw error;
  }
};
export const GET_ORDERDETAILS = async (orderId) => {
  try {
    const response = await custom_axios.get(`/order/order-details/${orderId}`);
    if (response.status !== 200) {
      throw new Error(`Error: ${response.statusText}`);
    }
    return response.data; // Axios responses have the data in the `data` field
  } catch (error) {
    console.error("Error fetching order details:", error);
    throw error;
  }
};

export const fetchOrders = async (queryParams, payload) => {
  try {
    const queryString = queryParams
      .map((param) =>
        param.value ? `${param.key}=${encodeURIComponent(param.value)}` : ""
      )
      .filter(Boolean)
      .join("&");

    const response = await custom_axios.post(
      `/order/all?${queryString}`,
      payload
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching orders:", error);
    throw error;
  }
};

export const addPrintTags = async (order_ids) => {
  try {
    const response = await custom_axios.post(`/order/print_orders`, {
      order_id_list: order_ids,
    });
    return response;
  } catch (error) {
    console.error("Error adding print  tags:", error);
    throw error;
  }
};


export const order_details = async (ids) => {
  try {
    const response = await custom_axios.get(
      `/order/multiple-order-details?ids=${ids}`
    );
    return response;
  } catch (error) {
    console.error("Error fetching order details:", error);
    throw error;
  }
};

