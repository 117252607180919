import React, { useState } from "react";
import cn from "classnames";
import styles from "../Released.module.sass";
import Icon from "../../../../components/Icon";
import Modal from "../../../../components/Modal";
import { Tooltip, Space, Divider, message } from "antd";
import Checkbox from "../../../../components/Checkbox";
import {
  GET_ORDERS_CUSTOME_FIELDS,
  GET_ALL_EMPLOYEE_CUSTOM_FIELDS,
  UPDATE_CUSTOM_FIELDS,
} from "../../../../API/Orders";
import CustomToast from "../../../../components/CustomToast";

function CustomizingOrderFields({
  onCustomFieldChange,
  activeButton,
  setActiveButton,
}) {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [orders, setOrders] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const showToast = CustomToast();
  const showModal = async () => {
    try {
      setVisibleModal(true);
      const ordersData = await GET_ORDERS_CUSTOME_FIELDS();
      setOrders(ordersData);
      const customFieldsData = await GET_ALL_EMPLOYEE_CUSTOM_FIELDS();
      const mergedData = ordersData.map((order) => {
        const customField = customFieldsData.find(
          (field) => field.id === order.id
        );
        return {
          ...order,
          checked: customField ? customField.check : false,
        };
      });
      setSelectedKeys(
        mergedData.filter((item) => item.checked).map((item) => item.id)
      );
      setIsModalVisible(true);
    } catch (error) {
      showToast("Error fetching data:", "error");
    }
  };

  const handleCancel = () => {
    setVisibleModal(false);
    setActiveButton(null);
  };

  const handleSendKeys = async () => {
    try {
      setVisibleModal(false);
      const selectedColumns = selectedKeys.map((key) => ({
        id: key,
      }));
      const selected = selectedColumns.map((item) => item.id);
      await UPDATE_CUSTOM_FIELDS(selected);
      showToast("Custom fields updated successfully", "success");
      setSelectedKeys([]);
      setIsModalVisible(false);
      onCustomFieldChange();
    } catch (error) {
      showToast(`Error updating columns: ${error.message}`, "error");
    }
  };
  const handleClick = () => {
    setActiveButton("customize");
    showModal();
  };

  return (
    <>
      <Tooltip title="Select Columns to Display">
        <div style={{ justifyContent: "end" }}>
          <div className="relative">
            <button
              className={cn(styles.link, {
                [styles.active]: activeButton === "customize",
              })}
              onClick={handleClick}
            >
              <Icon name="customized_oders_grid" size="24" />
            </button>
          </div>
        </div>
      </Tooltip>
      <Modal
        outerClassName={styles.outer}
        visible={visibleModal}
        onClose={() => {
          setVisibleModal(false);
          setActiveButton(null);
        }}
      >
        <div className={styles.details}>
          <div className={cn("title-purple", styles.title)}>
            Customize Your Orders
          </div>

          <div className={styles.row}>
            <div className={styles.col}>
              <Divider />
              <Space direction="vertical">
                {orders.map((item) => (
                  <Checkbox
                    key={item.id}
                    value={selectedKeys.includes(item.id)}
                    onChange={(e) => {
                      const { checked } = e.target;
                      setSelectedKeys((prev) =>
                        checked
                          ? [...prev, item.id]
                          : prev.filter((key) => key !== item.id)
                      );
                    }}
                    content={item.label}
                  />
                ))}
              </Space>

              <div className={cn(styles.btns, "mt-5 mr-[10px]")}>
                <button
                  className={cn("button-stroke mr-3", styles.button)}
                  onClick={handleCancel}
                >
                  Discard
                </button>
                <button
                  className={cn("button", styles.button)}
                  onClick={handleSendKeys}
                >
                  Apply
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default CustomizingOrderFields;
