import React, { useCallback, useEffect, useState } from "react";
import styles from "./OrdersTable.module.sass";
import Checkbox from "../../../../components/Checkbox";
import Row from "./Row";
import { fetchOrders } from "../../../../API/Orders";
import { BiDownArrowAlt, BiUpArrowAlt } from "react-icons/bi";
import { ConfigProvider, Pagination } from "antd";
import useDarkMode from "use-dark-mode";
import CustomToast from "../../../../components/CustomToast";
import Loader from "../../../../components/Loader";

const OrdersTable = ({
  search,
  risk,
  tags,
  notTaggedWith,
  delivery,
  deliveryMethod,
  financial,
  app,
  returnStatus,
  channel,
  fulfillment,
  shopifyOrderStatus,
  valueFilterByDate,
  valueFilterByToDate,
  valueFilterByFromDate,
  checkedItems,
  selectedEmployee,
  handleCheckboxChange,
  page,
  setPage,
  totalItems,
  setTotalItems,
  itemsPerPage,
  setItemsPerPage,
  customFilters,
  customFieldTrigger,
  resetCheckedItems,
}) => {
  const [chooseAll, setСhooseAll] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const [data, setData] = useState([]);
  const [sortField, setSortField] = useState("order_number");
  const [sortOrder, setSortOrder] = useState("desc");
  const [indeterminate, setIndeterminate] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [page, setPage] = useState(1);
  // const [totalItems, setTotalItems] = useState(0);
  // const [itemsPerPage, setItemsPerPage] = useState(10);

  const headerkeys = data && data.length > 0 ? Object.keys(data[0]) : [];

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const queryParams = [
        { key: "financialStatus", value: financial },
        { key: "fulfillmentStatus", value: fulfillment },
        { key: "orderNumber", value: search },
        { key: "sort_by", value: sortField },
        { key: "sort_order", value: sortOrder },
        { key: "fromDate", value: valueFilterByFromDate },
        { key: "toDate", value: valueFilterByToDate },
        { key: "page", value: page },
        { key: "pageSize", value: itemsPerPage },
        { key: "assignTo", value: selectedEmployee },
        { key: "customFilterId", value: customFilters },
        { key: "riskLevel", value: risk },
        { key: "returnStatus", value: returnStatus },
        { key: "deliveryStatus", value: delivery },
        { key: "deliveryMethod", value: deliveryMethod },
        { key: "channel", value: channel },
        { key: "app", value: app },
        { key: "shopifyOrderStatus", value:shopifyOrderStatus}
      ];

      const payload = {
        taggedWith: Array.isArray(tags) ? tags.join(",") : tags || "",
        notTaggedWith: Array.isArray(notTaggedWith)
          ? notTaggedWith.join(",")
          : notTaggedWith || "",
      };

      const response = await fetchOrders(queryParams, payload);
      setData(response.data);
      setTotalItems(response.count);
    } catch (error) {
      CustomToast.fire({
        icon: "error",
        text: "Error fetching data:",
      });
    } finally {
      setLoading(false);
    }
  }, [
    financial,
    fulfillment,
    tags,
    notTaggedWith,
    search,
    sortField,
    sortOrder,
    valueFilterByFromDate,
    valueFilterByToDate,
    page,
    itemsPerPage,
    selectedEmployee,
    customFilters,
    returnStatus,
    shopifyOrderStatus,
    risk,
    delivery,
    deliveryMethod,
    app,
    channel,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData, customFieldTrigger]);

  useEffect(() => {
    setPage(1); // Reset page to 1 when any filter changes
  }, [
    financial,
    fulfillment,
    tags,
    notTaggedWith,
    search,
    valueFilterByFromDate,
    valueFilterByToDate,
    selectedEmployee,
    customFilters,
    returnStatus,
    shopifyOrderStatus,
    risk,
    delivery,
    deliveryMethod,
    app,
    channel,
  ]);

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  useEffect(() => {
    const checkedCount = data.filter((item) => checkedItems[item.id]).length;
    if (checkedCount === 0) {
      setSelectAll(false);
    } else if (checkedCount === data.length) {
      setSelectAll(true);
    }
    setIndeterminate(checkedCount > 0 && checkedCount < data.length);
  }, [checkedItems, data]);

  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    const newCheckedItems = {};
    data.forEach((item) => {
      newCheckedItems[item.id] = checked;
    });
    handleCheckboxChange(e, newCheckedItems);
    setSelectAll(checked);
    setIndeterminate(false);
  };

  const handleIndividualCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const newCheckedItems = { ...checkedItems, [name]: checked };
    handleCheckboxChange(event, newCheckedItems);

    const allChecked = data.every((item) => newCheckedItems[item.id]);
    const someChecked = data.some((item) => newCheckedItems[item.id]);
    setSelectAll(allChecked);
    setСhooseAll(someChecked);
  };

  // const handlePageChange = (currentPage, pageSize) => {
  //   setPage(currentPage);
  //   setItemsPerPage(pageSize);
  // };
  const handlePageChange = (currentPage, pageSize) => {
    setPage(currentPage);
    if (pageSize !== itemsPerPage) {
      setItemsPerPage(pageSize);
    }
  };
  const darkMode = useDarkMode(false);
  const themeConfig = {
    token: {
      colorPrimary: "#1890ff",
      colorTextBase: darkMode.value ? "#ffffff" : "#000000",
      colorBgContainer: darkMode.value ? "#1f1f1f" : "#ffffff",
      colorBgElevated: darkMode.value ? "#2f2f2f" : "#ffffff",
      colorText: darkMode.value ? "#ffffff" : "#000000",
      buttonBoxShadow: darkMode.value ? "none" : "0 2px 0 rgb(230, 247, 255)",
      paginationBg: darkMode.value ? "#2f2f2f" : "#ffffff",
      paginationItemBg: darkMode.value ? "#2f2f2f" : "#ffffff",
      paginationItemBgHover: darkMode.value ? "#3a3a3a" : "#f5f5f5",
      paginationItemActiveBg: darkMode.value ? "#3a3a3a" : "#e6f7ff",
      paginationItemLinkBg: darkMode.value ? "#2f2f2f" : "#ffffff",
    },
  };

  return (
    <>
      {loading && <Loader className={styles.loader} />}
      <ConfigProvider theme={themeConfig}>
        <div className={styles.market}>
          <div className="overflow-auto">
            <div className={styles.table}>
              <div style={{ border: "1px solid black" }} className={styles.row}>
                <div className={styles.col}>
                  <Checkbox
                    className={styles.checkbox}
                    checked={selectAll}
                    onChange={handleSelectAll}
                    ref={(input) => {
                      if (input) {
                        input.indeterminate = indeterminate;
                      }
                    }}
                  />
                </div>
                {/* <div className={styles.col}>So.no</div> */}
                {headerkeys &&
                  headerkeys.map((item, index) => (
                    <div
                      style={{
                        whiteSpace: "nowrap",
                        alignItems: "center",
                        cursor: "pointer",
                      }}
                      className={styles.col}
                      key={index}
                      onClick={() => handleSort(item)}
                    >
                      {item
                        .split("_")
                        .map(
                          (word) => word.charAt(0).toUpperCase() + word.slice(1)
                        )
                        .join(" ")}

                      <span>
                        {sortField === item ? (
                          sortOrder === "asc" ? (
                            <BiUpArrowAlt
                              size="20px"
                              style={{ display: "inline-block" }}
                            />
                          ) : (
                            <BiDownArrowAlt
                              size="20px"
                              style={{ display: "inline-block" }}
                            />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  ))}
              </div>

              {data.map((item, index) => (
                <Row
                  key={index}
                  data={item}
                  checkedItems={checkedItems}
                  handleCheckboxChange={handleCheckboxChange}
                />
              ))}
            </div>
          </div>

          <div className={styles.foot}>
            <Pagination
              current={page}
              total={totalItems}
              pageSize={itemsPerPage}
              onChange={handlePageChange}
              onShowSizeChange={(current, size) => {
                setItemsPerPage(size);
                setPage(1);
                setSelectAll(false);
              }}
              showTotal={(total, range) =>
                `${range[0]}-${range[1]} of ${total} items`
              }
              showSizeChanger
            />
          </div>
        </div>
      </ConfigProvider>
    </>
  );
};

export default OrdersTable;
