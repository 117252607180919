import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./ProfileInformation.module.sass";
import Item from "../Item";
import Icon from "../../../components/Icon";

import Image from "../../../components/Image";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { get_store_sync_status, logo_presigned_url } from "../../../API/Store";
import { useFormikContext } from "../context/SettingsContext";

import { upload_store_logo_url } from "../../../API/StoreSettings";
import store_dark_image from "../../../assets/images/store_logo_dark.svg";
import store_light_image from "../../../assets/images/store_logo_light.svg";

import CustomToast from "../../../components/CustomToast";
const ProfileInformation = ({ className }) => {
  const { formikRef } = useFormikContext();
  const showToast = CustomToast();
  const [selectedImage, setSelectedImage] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(null);
  const [storeLogo, setStoreLogo] = useState();
  const getStoreData = async () => {
    try {
      const store_data = await get_store_sync_status();

      const url = store_data.data.store_logo;
      setStoreLogo(url);
    } catch (err) {
      console.log("error", err);
    }
  };
  const initialValues = {
    store_logo: "",
  };
  const handleImageChange = (e, setFieldValue) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedImage(file);
      setFieldValue("store_logo", file);
      // Generate a preview URL for the selected image
      const preview = URL.createObjectURL(file);
      setPreviewUrl(preview);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    setPreviewUrl(null); // Clear the preview
  };
  const validationSchema = Yup.object({
    store_logo: Yup.string().required("Logo is required"),
  });
  const handleSave = async (
    values,
    { setTouched, setErrors, validateForm }
  ) => {
    try {
      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        setTouched({
          store_logo: true,
        });
        setErrors(errors);
        return;
      }

      const formData = {
        name: values.store_logo.name,
        type: values.store_logo.type,
      };
      const presigned_url = await logo_presigned_url(formData);

      await fetch(presigned_url.data.signedUrl, {
        method: "PUT",
        headers: {
          "Content-Type": formData.type,
        },
        body: values.store_logo,
      });

      const res = await upload_store_logo_url(presigned_url.data.fileUrl);

      if (res.status === 200) {
        showToast(res.data.message, "success");

        setStoreLogo(presigned_url.data.fileUrl);
        setPreviewUrl(null);
      }
    } catch (error) {
      showToast(error.response.data.err, "error");
    }
  };
  useEffect(() => {
    getStoreData();
  }, []);
  return (
    <Item
      className={cn(styles.card, className)}
      title="Store Information"
      classTitle="title-green"
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSave}
        innerRef={formikRef}
      >
        {({ setFieldValue, errors }) => (
          <Form>
            <div className={styles.profile}>
              <div className={styles.avatar}>
                {previewUrl ? (
                  <img src={previewUrl} alt="Avatar Preview" />
                ) : (
                  <Image
                    src={storeLogo ? storeLogo : store_light_image}
                    srcDark={storeLogo ? storeLogo : store_dark_image}
                    alt="Core"
                  />
                )}
                {previewUrl && (
                  <button className={styles.remove} onClick={handleRemoveImage}>
                    <Icon name="close" />
                  </button>
                )}
              </div>

              <div className={styles.file}>
                <input
                  id="fileInput"
                  type="file"
                  onChange={(e) => handleImageChange(e, setFieldValue)}
                  style={{ display: "none" }}
                />
                <button
                  className={cn("button", styles.button)}
                  type="button"
                  onClick={() => document.getElementById("fileInput").click()}
                  style={{ cursor: "pointer" }}
                >
                  <Icon name="add" size="24" />
                  <span>Upload new picture</span>
                </button>
              </div>

              {previewUrl && (
                <button
                  className={cn("button-stroke", styles.button)}
                  onClick={handleRemoveImage}
                >
                  Remove
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
    </Item>
  );
};

export default ProfileInformation;
