import Swal from "sweetalert2";
import useDarkMode from "use-dark-mode";

const CustomToast = () => {
  const darkMode = useDarkMode(false);

  const showToast = (message, icon = "success") => {
    Swal.fire({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      background: darkMode.value ? "#333" : "#fff",
      color: darkMode.value ? "#fff" : "#000",
      icon: icon,
      title: message,
      didOpen: (toast) => {
        toast.onmouseenter = Swal.stopTimer;
        toast.onmouseleave = Swal.resumeTimer;
      },
    });
  };

  return showToast;
};

export default CustomToast;
