import React, { useState } from "react";
import styles from "./Tags.module.sass";
import cn from "classnames";
import TooltipGlodal from "../../../../../components/TooltipGlodal";
import moment from "moment";

const Tags = ({ orderDetails }) => {
  const [content, setContent] = useState();
  console.log("orderDetails in tags", orderDetails);

  const tagNames = orderDetails?.Tags?.map((tag) => tag.tag_name) || [];
  const tagClassNames = [
    "status-yellow",
    "status-green-dark",
    "status-purple-dark",
    "status-light_orange-dark",
    "status-light-dark",
  ];

  const formatTagName = (name) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const formatDateTime = (date) => {
    const today = moment().startOf("day");
    const processedDate = moment(date);

    if (processedDate.isSame(today, "day")) {
      return `Today at ${processedDate.format("h:mm A")}`;
    } else if (processedDate.isSame(today.subtract(1, "day"), "day")) {
      return `Yesterday at ${processedDate.format("h:mm A")}`;
    } else {
      return processedDate.format("ddd MMM DD YYYY, h:mm A");
    }
  };

  return (
    <>
      <div className={styles.head}>
        <div className={cn("title-blue", styles.subtitle)}>Tags</div>
      </div>
      <ul className={styles.tagList}>
        {tagNames.map((name, index) => {
          const className = tagClassNames[index % tagClassNames.length];
          return (
            <li
              key={index}
              className={cn(className, styles.status, "mt-[10px]", "mr-[10px]")}
            >
              {formatTagName(name)}
            </li>
          );
        })}
      </ul>
      <TooltipGlodal />
    </>
  );
};

export default Tags;
