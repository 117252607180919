import React, { useState } from "react";

import styles from "./Header.module.sass";
import { Link } from "react-router-dom";

import Image from "../../components/Image";
import DarkLogo from "../../assets/images/ppg_logo_in_dark.svg";
const Header = ({ onOpen }) => {
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  return (
    <header className={styles.header}>
      <button className={styles.burger} onClick={() => handleClick()}></button>

      <div className={styles.control}>
        <Link className={styles.logo} to="/">
          <Image
            className={styles.pic}
            src="/logo.png"
            srcDark={DarkLogo}
            alt="Core"
          />
        </Link>
      </div>
    </header>
  );
};

export default Header;
