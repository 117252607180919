import React, { useState, useEffect, useRef } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";

const Dropdowncheckbox = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  error,
  scroll = false,
  dropdownStyles,
}) => {
  const [visible, setVisible] = useState(false);
  const [shouldShowUp, setShouldShowUp] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (visible) {
      const dropdown = dropdownRef.current;
      if (dropdown) {
        const { bottom } = dropdown.getBoundingClientRect();
        if (window.innerHeight - bottom < 200) {
          setShouldShowUp(true);
        } else {
          setShouldShowUp(false);
        }
      }
    }
  }, [visible]);

  const handleClick = (value) => {
    setValue(value);
    setVisible(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div className={styles.label}>
          {label}{" "}
          {tooltip && <Tooltip content={tooltip} className={styles.tooltip} />}
        </div>
      )}
      <div
        className={cn(styles.dropdown, className, {
          [styles.active]: visible,
          [styles.small]: small,
        })}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
          style={dropdownStyles}
          ref={dropdownRef}
        >
          <div className={styles.selection}>{value}</div>
        </div>
        <div
          className={cn(styles.body, {
            [styles.bodyUp]: shouldShowUp, // Apply the bodyUp class conditionally
          })}
        >
          {options.map((x, index) => (
            <div
              className={styles.option}
              onClick={() => handleClick(x, index)}
              key={index}
            >
              {x}
            </div>
          ))}
        </div>
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdowncheckbox;
