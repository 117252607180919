import React, { useState } from "react";
import cn from "classnames";
import styles from "./Unauthorized.module.sass";

import Header from "../../components/HeaderFull/";
import useDarkMode from "use-dark-mode";

import unauthorized_image from "../../assets/images/unauthorized_image.svg";
import stop_icon from "../../assets/images/stop.svg";

export default function Unauthorized() {
  const [visible, setVisible] = useState(false);
  const darkMode = useDarkMode(false);

  return (
    <>
      <div
        className={cn(styles.page, {
          "bg-[#272b30]": darkMode.value,
          "bg-[#f4f4f4]": !darkMode.value,
        })}
      >
        <Header onOpen={() => setVisible(true)} />
        <div className={styles.inner}>
          <div className={cn(styles.container)}>
            <div>
              <img src={unauthorized_image} />
            </div>
            <div className={styles.text_container}>
              <img src={stop_icon} />
              <span>
                Permissions not granted. Please contact the administrator.
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
