import React from "react";

import CreateBarcode from "../CreateBarcode/Create";

const Create = () => {
  return (
    <>
      <CreateBarcode />
    </>
  );
};

export default Create;
