import React from "react";
import cn from "classnames";
import styles from "./Suggestion.module.sass";

const Suggestion = ({ className, item, onClick }) => {
  return (
    <div className={cn(styles.link, className)} onClick={onClick}>
      <div className={styles.details}>
        <div className={styles.container}>
          <div className={styles.title}>{item.data.full_name}</div>
          <div
            className={cn({
              "status-green-dark":
                item.data.role.role === "Admin" ||
                item.data.role.role === "Super Admin",
              "status-orange-dark": item.data.role.role === "Picker",
              "status-purple-dark": item.data.role.role === "Packer",
              "status-blue-dark":
                item.data.role.role !== "Admin" &&
                item.data.role.role !== "Super Admin" &&
                item.data.role.role !== "Picker" &&
                item.data.role.role !== "Packer",
            })}
          >
            {item.data.role.role}
          </div>
        </div>
        <div className={styles.content}>{item.data.email}</div>
      </div>
    </div>
  );
};

export default Suggestion;
