import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./CustomFilterDropdown.module.sass";
import Tooltip from "../../../../../components/Tooltip";

const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  value,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  error,
  scroll = false,
  dropdownStyles,
  disabled = false,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (option, index) => {
    if (typeof option === "object" && option.onClick) {
      option.onClick();
    } else {
      setValue(option);
    }
    setVisible(false);
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      {label && (
        <div
          className={cn(styles.label, classDropdownLabel)}
          onClick={() => !disabled && setVisible(!visible)}
        >
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place="right"
            />
          )}
        </div>
      )}

      <div
        className={cn(
          styles.dropdown,
          className,
          { [styles.small]: small },
          {
            [styles.active]: visible,
          }
        )}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          onClick={() => setVisible(!visible)}
          style={dropdownStyles}
        >
          <div className={styles.selection}>{value}</div>
        </div>
        {visible && !disabled && (
          <div
            className={cn(
              styles.body,
              "absolute top-full left-0 mt-1 w-full bg-white rounded shadow-lg",
              {
                "max-h-40 overflow-y-auto": scroll,
                "max-h-auto overflow-visible": !scroll,
                [styles.bodyUp]: upBody,
              }
            )}
          >
            {options.map((option, index) => (
              <div
                className={cn(styles.option, {
                  [styles.selectioned]:
                    option === value ||
                    (typeof option === "object" && option.label === value),
                })}
                onClick={() => handleClick(option, index)}
                key={index}
              >
                {typeof option === "object" ? option.label : option}
              </div>
            ))}
          </div>
        )}
        {error && <div className={styles.error}>{error}</div>}
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
