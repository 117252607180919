import custom_axios from "../axios";
export const search_items = async (search_term, cancelTokenRef) => {
  const encodedSearchTerm = encodeURIComponent(search_term);
  if (cancelTokenRef.current) {
    cancelTokenRef.current.cancel("Operation canceled due to new request.");
  }

  // Create a new CancelToken
  cancelTokenRef.current = custom_axios.CancelToken.source();

  try {
    const response = await custom_axios.get(`/search?q=${encodedSearchTerm}`, {
      cancelToken: cancelTokenRef.current.token,
    });

    return response.data.data;
  } catch (error) {
    if (custom_axios.isCancel(error)) {
      console.error("Previous request canceled", error.message);
    } else {
      console.error("Search error:", error);
    }
  }
};
