import React, { useState } from "react";
import styles from "./Market.module.sass";
import cn from "classnames";
import Checkbox from "../../../../components/Checkbox";
import Row from "./Row";

const Market = ({ selectedLocationId, mode, value, variants }) => {
  // console.log("varient", variants);
  const [chooseAll, setСhooseAll] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <div className={styles.market}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Checkbox
              className={styles.checkbox}
              value={chooseAll}
              onChange={() => setСhooseAll(!chooseAll)}
            />
          </div>
          <div className={styles.col}>Product</div>
          <div className={styles.col}>Update By</div>
          <div className={styles.col}>Unavailable</div>
          <div className={styles.col}>Committed</div>
          <div className={styles.col}>Available</div>
          <div className={styles.col}>On Hand</div>
        </div>

        {variants.map((x, index) => (
          <Row
            item={x}
            key={index}
            value={selectedFilters.includes(x.id)}
            onChange={() => handleChange(x.id)}
            mode={mode}
            updateValue={value}
          />
        ))}
      </div>
    </div>
  );
};

export default Market;
