import { twMerge } from "tailwind-merge";


const icons = {
    "arrow-down":
        "M7.044 9.204a.9.9 0 0 1 1.273 0l3.682 3.683 3.685-3.683a.9.9 0 0 1 1.172-.087l.101.087a.9.9 0 0 1 0 1.273l-4.32 4.32a.9.9 0 0 1-1.273 0l-4.32-4.32a.9.9 0 0 1 0-1.273z",
    "arrow-right":
        "M12.795 4.905l6.3 6.3.017.017.052.058-.069-.075c.036.036.069.074.1.114l.04.055.03.047.032.057.026.052.023.053a1.13 1.13 0 0 1 0 .835l-.023.052-.026.052-.033.058-.029.046-.04.056-.03.038-.052.058-.017.017-6.3 6.3c-.439.439-1.152.439-1.591 0s-.439-1.152 0-1.591l4.377-4.38H5.7c-.621 0-1.125-.504-1.125-1.125s.504-1.125 1.125-1.125h9.884l-4.379-4.38c-.399-.399-.436-1.024-.109-1.465l.109-.126c.439-.439 1.152-.439 1.591 0z",
    "arrow-up-right":
        "M17 6l.033.002h.026l.026.003.032.002.031.006.028.003.024.006.029.004.036.012.027.005.019.008.026.007.033.015.034.012.018.01.018.007.043.024.028.014.009.007.016.009.058.042.019.012.003.004.009.006c.057.046.109.098.155.155l.006.009.016.022.042.058.009.016.007.009.014.028.024.043.007.018.01.018.012.034.015.033.007.026.008.019.005.027.012.036.004.029.006.024.003.028.006.031.002.032.003.026v.026L18 7v10a1 1 0 1 1-2 0V9.414l-8.293 8.293a1 1 0 0 1-1.32.083l-.094-.083a1 1 0 0 1 0-1.414L14.585 8H7a1 1 0 0 1-.993-.883L6 7a1 1 0 0 1 1-1h10z",
    check: "M18.564 6.408a.9.9 0 0 1 1.273 1.273l-9.9 9.9a.9.9 0 0 1-1.273 0l-4.5-4.5a.9.9 0 0 1 1.273-1.273L9.3 15.671l9.264-9.263z",
    close: "M6.07 6.07a.75.75 0 0 1 1.061 0L12 10.939l4.87-4.869a.75.75 0 0 1 .99-.062l.071.062a.75.75 0 0 1 0 1.061L13.061 12l4.87 4.87a.75.75 0 0 1 .062.99l-.062.071a.75.75 0 0 1-1.061 0L12 13.061l-4.87 4.87a.75.75 0 0 1-.99.062l-.071-.062a.75.75 0 0 1 0-1.061L10.939 12 6.07 7.13a.75.75 0 0 1-.062-.99l.062-.071z",
    copyright:
        "M11.992 1.992c5.52 0 10 4.48 10 10s-4.48 10-10 10-10-4.48-10-10 4.48-10 10-10zm0 2c-4.41 0-8 3.59-8 8s3.59 8 8 8 8-3.59 8-8-3.59-8-8-8zm-.15 3.61c1.96 0 3.37 1.21 3.45 3.19h-1.79c-.02-.51-.35-1.6-1.63-1.66-.6.02-1.88.11-1.88 3 0 1.67.55 2.69 1.86 2.73 1.18-.06 1.6-.85 1.63-1.44h1.79c-.03 1.3-1.22 2.95-3.44 2.95-2.91 0-3.84-2.3-3.84-4.24 0-3.14 1.64-4.53 3.85-4.53z",
    facebook:
        "M2 12.056c0 4.972 3.611 9.106 8.333 9.944v-7.222h-2.5V12h2.5V9.778c0-2.5 1.611-3.888 3.889-3.888.722 0 1.5.111 2.222.222v2.556h-1.277c-1.223 0-1.5.611-1.5 1.389V12h2.667l-.444 2.778h-2.222V22C18.389 21.162 22 17.028 22 12.056 22 6.525 17.5 2 12 2S2 6.525 2 12.056z",
    eye: "M12 3.9c3.042 0 5.705 1.469 7.969 3.884.778.83 1.453 1.716 2.026 2.602l.71 1.211a.9.9 0 0 1 0 .805c-.127.253-.364.675-.71 1.211-.573.887-1.248 1.773-2.026 2.602C17.705 18.631 15.042 20.1 12 20.1s-5.705-1.469-7.969-3.884c-.778-.83-1.453-1.716-2.026-2.602l-.71-1.211a.9.9 0 0 1 0-.805c.127-.253.364-.675.71-1.211.573-.887 1.248-1.773 2.026-2.602C6.295 5.369 8.958 3.9 12 3.9zm0 1.8c-2.471 0-4.701 1.231-6.656 3.316a16.66 16.66 0 0 0-1.827 2.348l-.385.625-.008.011.008.013.274.451.111.173a16.66 16.66 0 0 0 1.827 2.348C7.299 17.069 9.529 18.3 12 18.3s4.701-1.231 6.656-3.316a16.66 16.66 0 0 0 1.827-2.348l.385-.625.006-.013-.006-.011-.274-.451-.111-.173a16.66 16.66 0 0 0-1.827-2.348C16.701 6.931 14.471 5.7 12 5.7zm0 2.7a3.6 3.6 0 1 1 0 7.2 3.6 3.6 0 0 1 0-7.2zm0 1.8a1.8 1.8 0 1 0 0 3.6 1.8 1.8 0 1 0 0-3.6z",
    linkedin:
        "M9.429 8.969h3.714v1.85c.535-1.064 1.907-2.02 3.968-2.02 3.951 0 4.889 2.118 4.889 6.004V22h-4v-6.312c0-2.213-.535-3.461-1.897-3.461-1.889 0-2.674 1.345-2.674 3.46V22h-4V8.969zM2.57 21.83h4V8.799h-4V21.83zM7.143 4.55a2.53 2.53 0 0 1-.753 1.802c-.484.481-1.138.75-1.82.748a2.59 2.59 0 0 1-1.818-.747A2.55 2.55 0 0 1 2 4.55c0-.677.27-1.325.753-1.803S3.89 2 4.571 2s1.336.269 1.819.747a2.54 2.54 0 0 1 .753 1.803z",
    no: "M5.054 5.054a1.44 1.44 0 0 1 2.036 0l4.905 4.906 4.908-4.906a1.44 1.44 0 0 1 1.837-.167l.199.167a1.44 1.44 0 0 1 0 2.036l-4.907 4.905 4.907 4.908a1.44 1.44 0 0 1 .167 1.837l-.167.199a1.44 1.44 0 0 1-2.036 0l-4.908-4.907-4.905 4.907a1.44 1.44 0 0 1-1.837.167l-.199-.167a1.44 1.44 0 0 1 0-2.036l4.906-4.908L5.054 7.09a1.44 1.44 0 0 1-.167-1.837l.167-.199z",
    twitter:
        "M23.356 4.96a9.33 9.33 0 0 1-2.675.733 4.67 4.67 0 0 0 2.048-2.578 9.3 9.3 0 0 1-2.958 1.13 4.66 4.66 0 0 0-5.601-.923 4.66 4.66 0 0 0-2.337 5.173 13.23 13.23 0 0 1-9.602-4.868c-.4.69-.63 1.49-.63 2.342a4.66 4.66 0 0 0 2.072 3.878c-.74-.024-1.463-.223-2.11-.583v.06A4.66 4.66 0 0 0 5.3 13.892c-.686.186-1.406.213-2.104.08a4.66 4.66 0 0 0 4.352 3.234 9.35 9.35 0 0 1-5.786 1.995 9.5 9.5 0 0 1-1.112-.065c2.129 1.369 4.608 2.096 7.14 2.093 8.57 0 13.255-7.098 13.255-13.254 0-.2-.005-.402-.014-.602a9.47 9.47 0 0 0 2.323-2.41l.002-.003z",
    question:
        "M12 1.875c5.592 0 10.125 4.533 10.125 10.125A10.13 10.13 0 0 1 12 22.125 10.13 10.13 0 0 1 1.875 12C1.875 6.408 6.408 1.875 12 1.875zm0 2.25c-4.349 0-7.875 3.526-7.875 7.875S7.651 19.875 12 19.875s7.875-3.526 7.875-7.875S16.349 4.125 12 4.125zm.008 11.25c.621 0 1.125.504 1.125 1.125s-.504 1.125-1.125 1.125c-.629 0-1.133-.504-1.133-1.125s.504-1.125 1.125-1.125zm.566-8.949c.89.153 1.698.616 2.28 1.307s.9 1.566.899 2.467c0 1.243-.726 2.211-1.851 2.961a7.03 7.03 0 0 1-1.618.806c-.589.196-1.227-.122-1.423-.712s.122-1.227.712-1.423c.251-.084.673-.271 1.082-.544.563-.375.849-.757.849-1.091.001-.372-.131-.732-.37-1.017s-.572-.475-.939-.538-.744.006-1.064.194-.564.484-.688.835c-.206.586-.848.894-1.435.688s-.894-.848-.688-1.435c.3-.852.891-1.571 1.67-2.028s1.694-.625 2.585-.472z",
    yes: "M18.885 5.534a1.44 1.44 0 1 1 2.036 2.036L10.059 18.432a1.44 1.44 0 0 1-2.036 0l-4.937-4.937a1.44 1.44 0 0 1 2.036-2.036l3.919 3.918 9.844-9.843z",
};



const Icon = ({ className, name, fill }) => (
    <svg
        className={twMerge(`inline-flex w-6 h-6 ${className || ""}`)}
        width={24}
        height={24}
        viewBox="0 0 24 24"
    >
        <path fill={fill} d={icons[name]}></path>
    </svg>
);

export default Icon;
