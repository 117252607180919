import React, { useEffect, useState } from "react";
import cn from "classnames";
import { Divider, Space } from "antd";
import styles from "../Released.module.sass";
import custome_axios from "../../../../axios";
import Modal from "../../../../components/Modal";
import Radio from "../../../../components/Radio";
import CustomToast from "../../../../components/CustomToast";
function AssignToPickerOrPacker({
  setAssignVisibleModal,
  assignvisibleModal,
  checkedItems,
  updateFilter,
  handleCheckboxChange,
}) {
  const ids = Object.keys(checkedItems);
  const [staffOptions, setStaffOptions] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const checkedIds = Object.keys(checkedItems).filter((id) => checkedItems[id]);
  const showToast = CustomToast();
  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await custome_axios.get("/employee/all");
        const roles = response.data.data.map((item) => ({
          label: `${item.full_name}`,
          value: item.id,
        }));
        setStaffOptions([...roles]);
      } catch (error) {
        showToast("Failed to fetch roles: ", "error");
      }
    };
    fetchRoles();
  }, []);

  const handleOk = async () => {
    setAssignVisibleModal(false);
    try {
      await custome_axios.post("/employee/order-assignment", {
        assign_to: selectedRole,
        order_id_list: checkedIds.map(Number),
      });

      showToast(`Orders successfully assigned.`, "success");
      updateFilter(); // Call updateFilter without arguments

      // Clear checked items after successful assignment
      const newCheckedItems = {};
      checkedIds.forEach((id) => (newCheckedItems[id] = false));
      handleCheckboxChange(null, newCheckedItems);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        showToast("Only admin can access", "error");
      } else {
        showToast("Failed to assign orders.", "error");
      }
    }
  };

  const handleCancel = () => {
    setAssignVisibleModal(false);
  };

  return (
    <Modal
      outerClassName={styles.outer}
      visible={assignvisibleModal}
      onClose={() => setAssignVisibleModal(false)}
    >
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>
          Assign To Picker / Packer
        </div>

        <div className={styles.row}>
          <div className={styles.col}>
            <Divider />
            <Space direction="vertical">
              {staffOptions.map((role) => (
                <Radio
                  key={role.value}
                  value={selectedRole === role.value}
                  onChange={() => setSelectedRole(role.value)}
                  content={role.label}
                  name="staff"
                  reverse
                />
              ))}
            </Space>

            <div className={cn(styles.btns, "mt-5 mr-[10px]")}>
              <button
                className={cn("button-stroke mr-3", styles.button)}
                onClick={handleCancel}
              >
                Discard
              </button>
              <button
                className={cn("button", styles.button)}
                onClick={handleOk}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AssignToPickerOrPacker;
