import React, { useState } from "react";
import AccessControl from "../../../../../../HOC/AccessControl";
import styles from "./OrderControl.module.sass";
import cn from "classnames";
import Icon from "../../../../../../components/Icon";
import OrderPreview from "./OrderPreview";

const Control = ({ className, item, handleDelete }) => {
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const [visibleModalPreview, setVisibleModalPreview] = useState(false);
  const extractFilename = (url, type) => {
    const regex = /\/([^\/]+-\d{5})/;
    const match = url.match(regex);
    if (match) {
      return `${type === "image" ? match[1] + ".jpg" : match[1] + ".mp4"}`; // or `.mp4` based on your file type
    }
    return "downloaded_file"; // fallback filename
  };

  const handleDownload = () => {
    fetch(item.media_url)
      .then((response) => response.blob())
      .then((blob) => {
        const filename = extractFilename(item.media_url, item.media_type);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      })
      .catch(() => alert("Failed to download file"));
  };

  const media = item
    ? (Array.isArray(item) ? item : [item]).filter(Boolean)
    : [];
  const isVideo = item.media_type === "video";
  const actions = [
    {
      // icon: "play",
      icon: isVideo ? "play" : "eye",
      action: () => setVisibleModalPreview(true),
      moduleCode: "",
      requiredPrivilege: "",
    },

    {
      icon: "trash",
      action: () => {
        if (item && item.id) {
          // console.log("Attempting to remove media with ID:", item.id);
          if (typeof handleDelete === "function") {
            handleDelete(item.id); // Call the function with media ID
          } else {
            // console.error("handleDelete is not a function");
          }
        } else {
          console.error("Item ID is missing or item is invalid.");
        }
      },
      moduleCode: "8FZN9w",
      requiredPrivilege: "esufk7",
    },
  ];
  return (
    <>
      <div className={cn(styles.control, className)}>
        <button className={styles.button} onClick={actions[0].action}>
          <Icon name={actions[0].icon} size="20" />
        </button>
        <AccessControl
          moduleCode={actions[1].moduleCode}
          requiredPrivilege={actions[1].requiredPrivilege}
        >
          <button className={styles.button} onClick={actions[1].action}>
            <Icon name={actions[1].icon} size="20" />
          </button>
        </AccessControl>
        {/* {actions.map((x, index) => (
          <AccessControl
            moduleCode={x.moduleCode}
            requiredPrivilege={x.requiredPrivilege}
          >
            <button className={styles.button} key={index} onClick={x.action}>
              <Icon name={x.icon} size="20" />
            </button>
          </AccessControl>
        ))} */}
      </div>
      {item && (
        <OrderPreview
          visible={visibleModalPreview}
          onClose={() => setVisibleModalPreview(false)}
          media={media}
          title="PickPackGo"
          figcaption="Elegant product mockup for your next project"
          download={handleDownload}
        />
      )}
    </>
  );
};

export default Control;
