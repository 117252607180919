import React, { useEffect, useState } from "react";
import styles from "./Details.module.sass";
import cn from "classnames";
import Card from "../../../../components/Card";
import { Link, useNavigate } from "react-router-dom";
import InventoryTable from "./InventoryTable/index";
import Market from "./Market/index";
import { useParams } from "react-router-dom";
import { GET_INVENTORY_LOG_BY_ID } from "../../../../API/Inventory";
import moment from "moment";
import Loader from "../../../../components/Loader";
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};
const Details = () => {
  const { id } = useParams();
  const [inventoryLog, setInventoryLog] = useState(null);
  const [inventoryLogs, setInventoryLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  const formatDate = (date) => {
    const dateObj = moment(date);
    if (dateObj.isSame(moment(), "day")) {
      return `Today at ${dateObj.format("h:mm A")}`;
    }
    return dateObj.format("MMMM D, YYYY [at] h:mm A");
  };
  useEffect(() => {
    const fetchInventoryLog = async () => {
      setLoading(true);
      try {
        const log = await GET_INVENTORY_LOG_BY_ID(id);
        setInventoryLog(log);
      } catch (error) {
        console.error("Error fetching inventory log:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInventoryLog();
  }, [id]);

  // const capitalizeFirstLetter = (string) => {
  //   return string.charAt(0).toUpperCase() + string.slice(1);
  // };
  const capitalizeFirstLetter = (string) => {
    return string
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  return (
    <>
      {loading && <Loader className={styles.loader} />}
      <Card>
        <div className="flex justify-between mt-[5%]">
          <div
            className={cn("title-red", styles.subtitle)}
            style={{ letterSpacing: "0" }}
          >
            <div>Inventory Logs</div>
            <div className="flex">
              <div className={styles.heade}>
                <div>
                  {inventoryLog?.updated_by?.full_name}
                  {/* <span className={styles.headespace}></span> */}
                </div>
                <div className={styles.divider}></div>
              </div>

              <div className={styles.heade}>
                <div>
                  {inventoryLog?.created_at
                    ? formatDate(inventoryLog?.created_at)
                    : "-"}
                  {/* <span className={styles.headespace}></span> */}
                </div>
                <div className={styles.divider}></div>
              </div>
              <div className={styles.heade}>
                <div>
                  Products :
                  <span className={styles.headespace}>
                    {" "}
                    {inventoryLog?.products || 0}
                  </span>
                </div>
                <div className={styles.divider}></div>
              </div>

              <div className={styles.heade}>
                <div>
                  Items :
                  <span className={styles.headespace}>
                    {inventoryLog?.items || 0}
                  </span>
                </div>
                <div className={styles.divider}></div>
              </div>
            </div>
          </div>
          <div
            style={{ height: "25px" }}
            className={cn("status-yellow", styles.status)}
          >
            {inventoryLog?.inventory_mode
              ? capitalizeFirstLetter(inventoryLog?.inventory_mode)
              : "-"}{" "}
            Mode
          </div>
        </div>
      </Card>
      <Card>
        <InventoryTable data={inventoryLog ? [inventoryLog] : []} />
      </Card>
      <div style={{ marginTop: "20px" }}>
        <Card>
          <Market
            data={inventoryLog ? inventoryLog.ModifiedVariants || [] : []}
          />
        </Card>
      </div>
    </>
  );
};

export default Details;
