import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Create.module.sass";

import Switch from "../../../../components/Switch";
import Card from "../../../../components/Card";
import Icon from "../../../../components/Icon";
import Checkbox from "../../../../components/Checkbox";
import Row from "./Row/Row";
import {
  get_all_modules,
  get_all_module_permission,
  assign_module_permission,
  remove_module_permission,
  get_module__assigned_permissions,
} from "../../../../API/RBAC";
import { useMenu } from "../../../../context/Roles/MenuContext";

const CreatePermission = () => {
  const { setSubActiveTab, setIsMenuVisible, role } = useMenu();

  const [modules, setModules] = useState([]);
  const [selectedModule, setSelectedModule] = useState(null);
  const [modulePermission, setModulePermission] = useState([]);
  const [assignedPermissions, setAssignedPermissions] = useState([]);
  const [viewPermission, setViewPermission] = useState(null);
  const [view, setView] = useState();

  const fetchModules = async () => {
    const res = await get_all_modules();
    setModules(res.data);

    setSelectedModule(res.data[0].code);
    fetchModulePermission(res.data[0].code);
  };

  const fetchModulePermission = async (id) => {
    const res = await get_all_module_permission(id);
    const permissions = await get_module__assigned_permissions(role.id, id);

    const view = res.data.find((item) => item.name === "View");
    setView(view);
    const viewAssigned = permissions.data.some(
      (item) => item.module_permission.name === "View"
    );

    setModulePermission(res.data.filter((item) => item.name !== "View"));

    setAssignedPermissions(
      permissions.data.map((item) => {
        return item.module_permission;
      })
    );
    setViewPermission(viewAssigned ? view : null);
  };

  const handleChange = (id) => {
    setSelectedModule(id);
    fetchModulePermission(id);
  };

  const handlePermissionChange = async (permission) => {
    if (assignedPermissions.some((item) => item.code === permission.code)) {
      const permissionIds = [permission.code];
      const res = await deletePermission(permissionIds);
      if (res) {
        setAssignedPermissions(
          assignedPermissions.filter((item) => item.code !== permission.code)
        );
      }
    } else {
      const permissionIds = [permission.code];
      const res = await createPermission(permissionIds);

      if (res) {
        setAssignedPermissions((assignedPermissions) => [
          ...assignedPermissions,
          permission,
        ]);
      }
    }
  };

  const createPermission = async (permissionIds) => {
    try {
      const formData = {
        role_id: role.id,
        module_permission_ids: permissionIds,
      };
      return await assign_module_permission(formData);
    } catch (error) {
      console.error("Error creating permission:", error);
      return false;
    }
  };

  const deletePermission = async (permissionIds) => {
    try {
      const formData = {
        role_id: role.id,
        module_permission_ids: permissionIds,
      };
      return await remove_module_permission(formData);
    } catch (error) {
      console.error("Error deleting permission:", error);
    }
  };

  const handleAssignView = async () => {
    if (viewPermission) {
      const permissionIds = [viewPermission.code];
      handleDeselectAll();
      const res = await deletePermission(permissionIds);

      if (res) {
        setViewPermission(null);
      }
    } else {
      const formData = {
        role_id: role.id,
        module_permission_ids: [view.code],
      };
      const res = await assign_module_permission(formData);
      if (res) {
        setViewPermission(view);
      }
    }
  };
  const handleBack = () => {
    setSubActiveTab(1);
    setIsMenuVisible(true);
  };
  useEffect(() => {
    fetchModules();
    setIsMenuVisible(false);
  }, []);
  const handleSelectAll = async () => {
    const unselectedPermissionIds = modulePermission
      .filter(
        (permission) =>
          !assignedPermissions.some(
            (assigned) => assigned.code === permission.code
          )
      )
      .map((permission) => permission.code);

    if (unselectedPermissionIds.length) {
      const res = await createPermission(unselectedPermissionIds);

      if (res) {
        setAssignedPermissions((prev) => [
          ...prev,
          ...modulePermission.filter((permission) =>
            unselectedPermissionIds.includes(permission.code)
          ),
        ]);
      }
    }
  };

  const handleDeselectAll = async () => {
    const selectedPermissionIds = assignedPermissions.map(
      (permission) => permission.code
    );

    if (selectedPermissionIds.length) {
      const res = await deletePermission(selectedPermissionIds);
      if (res) {
        setAssignedPermissions([]);
      }
    }
  };
  return (
    <div className={styles.section}>
      <div className={styles.row}>
        <div className={styles.col}>
          <Card
            className={styles.card}
            title={role.role}
            classTitle="title-red"
            head={
              <>
                <button className={cn("button-stroke")} onClick={handleBack}>
                  <Icon name="arrow-left" size="24" />
                  <span>Back</span>
                </button>
              </>
            }
          >
            <div className={cn(styles.table)}>
              {modules?.map((module) => (
                <Row
                  item={module}
                  activeTable={selectedModule}
                  activeId={selectedModule}
                  onChange={() => handleChange(module.code)}
                  key={module.id}
                />
              ))}
            </div>
          </Card>
        </div>
        <div className={styles.col}>
          <Card
            className={styles.card}
            title="Permissions"
            classTitle="title-purple"
            head={
              <Switch
                className={styles.switch}
                value={Boolean(viewPermission)}
                onChange={() => handleAssignView()}
              />
            }
          >
            {modulePermission.map((permission) => (
              <Checkbox
                className={styles.checkbox}
                content={permission.name}
                value={assignedPermissions.some(
                  (item) => item.code === permission.code
                )}
                onChange={() => handlePermissionChange(permission)}
                key={permission.code}
                reverse
                disabled={!viewPermission}
              />
            ))}
            <div className={styles.button_container}>
              <button
                className={cn("button-stroke button-small")}
                onClick={handleSelectAll}
                style={{ width: "49%" }}
                disabled={!viewPermission}
              >
                <span>Select All</span>
              </button>
              <button
                className={cn("button-stroke button-small")}
                style={{ width: "49%" }}
                onClick={handleDeselectAll}
                disabled={!viewPermission}
              >
                <span>Deselect All</span>
              </button>
            </div>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CreatePermission;
