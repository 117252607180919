import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import moment from "moment";

const Row = ({ item, value, onChange, index }) => {
  const formatDate = (date) => {
    const dateObj = moment(date);
    if (dateObj.isSame(moment(), "day")) {
      return `Today at ${dateObj.format("h:mm A")}`;
    }
    return dateObj.format(" D MMMM YYYY ");
  };
  const capitalizeFirstLetter = (string) => {
    return string
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}></div>
        <div className={styles.col}>
          {item.created_at ? formatDate(item.created_at) : "-"}
        </div>
        <div className={styles.col}>
          {item.variant_location.name ? item.variant_location.name : "-"}
        </div>
        <div className={styles.col}>
          {item.inventory_mode
            ? capitalizeFirstLetter(item.inventory_mode)
            : "-"}
        </div>
        <div className={styles.col}>{item.notes ? item.notes : "-"}</div>
        {/* <div className={styles.col}>{item.notes ? item.notes : "-"}</div> */}
      </div>
    </>
  );
};

export default Row;
