import React, { useState } from "react";
import styles from "./Market.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../components/Checkbox";
import Row from "./Row";
import Icon from "../../../../../components/Icon";

const Market = ({ data = [] }) => {
  const [chooseAll, setСhooseAll] = useState(false);

  const [selectedFilters, setSelectedFilters] = useState([]);

  const handleChange = (id) => {
    if (selectedFilters.includes(id)) {
      setSelectedFilters(selectedFilters.filter((x) => x !== id));
    } else {
      setSelectedFilters((selectedFilters) => [...selectedFilters, id]);
    }
  };

  return (
    <div className={styles.market}>
      <div className={styles.table}>
        <div className={styles.row}>
          <div className={styles.col}>
            <Checkbox
              className={styles.checkbox}
              value={chooseAll}
              onChange={() => setСhooseAll(!chooseAll)}
            />
          </div>
          <div className={styles.col}>Sr.No</div>
          <div className={styles.col}>Name</div>
          <div className={styles.col}>Barcode</div>
          <div className={styles.col}>SKU</div>
          <div className={styles.col}>Bin Location</div>
          {/* <div className={styles.col} style={{ display: "flex", gap: "6px" }}>
            From <Icon name="arrow-right" size="16" /> To
          </div> */}
          <div className={styles.col}>
            <span
              className={styles.arrow}
              style={{ display: "flex", gap: "6px" }}
            >
              From <Icon name="arrow-right" size="16" /> To
            </span>
          </div>
          <div className={styles.col}>Updated By</div>
        </div>
        {data.length > 0 ? (
          data.map((item, index) => (
            <Row
              item={item}
              key={index}
              value={selectedFilters.includes(item.id)}
              onChange={() => handleChange(item.id)}
              index={index + 1}
            />
          ))
        ) : (
          <div>No data available</div>
        )}
      </div>
    </div>
  );
};

export default Market;
