import custom_axios from "../axios";
export const GET_LOCATIONS = async () => {
  try {
    const response = await custom_axios.get("/variant-location");
    return response.data.data;
  } catch (error) {
    console.error("Error fetching locations:", error);
    throw error;
  }
};

export const GET_SINGLE_VARIANT = async (sku, locationId) => {
  try {
    const response = await custom_axios.get(
      `/variant-inventory/single-variant/${sku}/${locationId}`
    );
    return {
      status: 200,
      data: response.data,
    };
  } catch (error) {
    if (error.response) {
      return error.response;
    } else {
      throw new Error("Network error");
    }
  }
};

export const UPDATE_INVENTORY = async (payload) => {
  try {
    const response = await custom_axios.put(
      "/variant-inventory/update-inventory",
      payload
    );
    return {
      status: response.status,
      data: response.data,
    };
  } catch (error) {
    if (error.response) {
      return {
        status: error.response.status,
        data: error.response.data,
      };
    } else {
      throw new Error("Network error");
    }
  }
};
export const GET_INVENTORY_LOGS = async () => {
  try {
    const response = await custom_axios.get(
      "/variant-inventory/all-inventory-logs"
    );
    return response.data.data;
  } catch (error) {
    console.error("Error fetching inventory logs:", error);
    throw error;
  }
};
export const GET_INVENTORY_LOG_BY_ID = async (id) => {
  try {
    const response = await custom_axios.get(
      `/variant-inventory/inventory-logs/${id}`
    );
    return response.data.data[0];
  } catch (error) {
    console.error("Error fetching inventory log:", error);
    throw error;
  }
};
