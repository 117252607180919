import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import cn from "classnames";
import styles from "./Welcome.module.sass";
import { use100vh } from "react-div-100vh";

import Image from "../../components/Image";

import DarkLogo from "../../assets/images/ppg_logo_in_dark.svg";

import Confetti from "../../assets/images/confetti.png";

const Welcome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const charge_id = searchParams.get("charge_id");
  const heightWindow = use100vh();
  const handleNavigation = () => {
    navigate("/");
  };
  return (
    <div style={{ minHeight: heightWindow }}>
      <div className={styles.modal}>
        <div className={cn(styles.outer)}>
          <div>
            <div className={styles.header}>
              <Image
                className={styles.logo_pic}
                src="/logo.png"
                srcDark={DarkLogo}
                alt="Core"
              />{" "}
              <h3>PickPackGo</h3>
            </div>
            <h2 className={styles.subheader}>Welcome to PickPackGo</h2>
            <div className={styles.header}>
              <Image
                className={styles.pic}
                src={Confetti}
                srcDark={Confetti}
                alt="Core"
              />
            </div>
            <p className={styles.success}>Success</p>
            <p className={styles.price}>$100</p>
            <p className={styles.txn}>
              TXN ID: <span>{charge_id}</span>
            </p>
            <p className={styles.description}>
              Thank you for choosing Pick Pack Go! Your payment is complete, and
              your journey to optimized logistics starts now.
            </p>
            <button
              className={cn("button", styles.button)}
              onClick={handleNavigation}
            >
              Go To Home
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Welcome;
