import React from "react";

import UpdateBarcode from "../UpdateBarcode/Update";

const Update = () => {
  return (
    <>
      <UpdateBarcode />
    </>
  );
};

export default Update;
