import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Update.module.sass";
import Item from "../../Item";

import TextInput from "../../../../components/TextInput";
import { get_role_by_id, update_role_by_id } from "../../../../API/RBAC";

import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import { useMenu } from "../../../../context/Roles/MenuContext";
import { useFormikContext } from "../../context/SettingsContext";
const UpdateRole = () => {
  const { formikRef } = useFormikContext();
  const { role, setIsMenuVisible, setSubActiveTab, setRole } = useMenu();
  const [initialValues, setInitialValues] = useState({
    name: "",
  });
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
  });

  const handleUpdate = async (values) => {
    try {
      const formData = {
        role: values.name,
      };
      const res = await update_role_by_id(role.id, formData);

      if (res.status === 200) {
        setRole(res.data.data);
        setSubActiveTab(3);
        setIsMenuVisible(false);
      }
    } catch (error) {
      const errors = error.response.data.errors;
      if (error.response.status === 400) {
        message.error(Object.values(errors[0])[0]);
      }
    }
  };
  const fetchRoleDetails = async () => {
    const res = await get_role_by_id(role.id);
    setInitialValues({ name: res.data.role });
  };

  useEffect(() => {
    fetchRoleDetails();
  }, []);
  return (
    <Item
      className={cn(styles.card)}
      title="Update Role"
      classTitle="title-red"
    >
      {" "}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleUpdate}
        innerRef={formikRef}
      >
        {({ isSubmitting, setFieldValue, resetForm, values }) => (
          <Form>
            <div className={styles.description}>
              <Field name="name">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    label="Role Name"
                    tooltip="Maximum 50 characters."
                    className={styles.field}
                    error={meta.touched && meta.error ? meta.error : ""}
                    required
                  />
                )}
              </Field>
            </div>
          </Form>
        )}
      </Formik>
    </Item>
  );
};

export default UpdateRole;
