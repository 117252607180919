import React, { useState } from "react";
import styles from "./Staff.module.sass";
import cn from "classnames";
import Card from "../../components/Card";
import Form from "../../components/Form";
import Table from "./Table";
import { Link, useNavigate } from "react-router-dom";
import Icon from "../../components/Icon";
import AccessControl from "../../HOC/AccessControl";
import useDarkMode from "use-dark-mode";
import { ConfigProvider } from "antd";
const Staffsindex = () => {
  const navigate = useNavigate();
  const onEditClick = (id) => {
    navigate(`/staff/edit/${id}`);
  };

  const [search, setSearch] = useState("");
  const [visible, setVisible] = useState(false);

  const handleSubmit = (e) => {
    alert();
  };
  const darkMode = useDarkMode(false);
  const themeConfig = {
    token: {
      colorPrimary: "#1890ff",
      colorTextBase: darkMode.value ? "#ffffff" : "#000000",
      colorBgContainer: darkMode.value ? "#1f1f1f" : "#ffffff",
      colorBgElevated: darkMode.value ? "#2f2f2f" : "#ffffff",
      colorText: darkMode.value ? "#ffffff" : "#000000",
      buttonBoxShadow: darkMode.value ? "none" : "0 2px 0 rgb(230, 247, 255)",
      paginationBg: darkMode.value ? "#2f2f2f" : "#ffffff",
      paginationItemBg: darkMode.value ? "#2f2f2f" : "#ffffff",
      paginationItemBgHover: darkMode.value ? "#3a3a3a" : "#f5f5f5",
      paginationItemActiveBg: darkMode.value ? "#3a3a3a" : "#e6f7ff",
      paginationItemLinkBg: darkMode.value ? "#2f2f2f" : "#ffffff",
    },
  };
  return (
    <>
      <ConfigProvider theme={themeConfig}>
        <Card
          className={cn(styles.card)}
          title="Staff"
          classTitle={cn("title-purple", styles.title)}
          classCardHead={cn(styles.head, { [styles.hidden]: visible })}
          head={
            <>
              <Form
                className={styles.form}
                value={search}
                setValue={setSearch}
                onSubmit={() => handleSubmit()}
                placeholder="Search by name or email"
                type="text"
                name="search"
                icon="search"
              />
              <AccessControl moduleCode="eV79MY" requiredPrivilege="9WgfDT">
                <div className={styles.nav}>
                  <Link className={cn("button", styles.button)} to="/staff/add">
                    <Icon name="add" size="24" />
                    <span>Add Member</span>
                  </Link>
                </div>
              </AccessControl>
            </>
          }
        >
          <div className={cn(styles.row, { [styles.flex]: visible })}>
            <Table
              className={styles.table}
              activeTable={visible}
              setActiveTable={setVisible}
              onEditClick={onEditClick}
            />
          </div>
        </Card>
      </ConfigProvider>
    </>
  );
};

export default Staffsindex;
