import { useState, useEffect } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import { useMenu } from "../../../../../../../context/Roles/MenuContext";
import Switch from "../../../../../../../components/Switch";
import CustomToast from "../../../../../../../components/CustomToast";
import {
  get_tags_config,
  create_tags_config,
} from "../../../../../../../API/TagsSettings";
const Row = ({ item, activeTable, activeId }) => {
  const { setSubActiveTab, setTag } = useMenu();
  const showToast = CustomToast();

  const [localToggleState, setLocalToggleState] = useState({});
  const fetchTagConfig = async () => {
    const res = await get_tags_config();

    // Initialize the localToggleState with the isActive values from the fetched tagsConfig
    const initialToggleState = {};
    res.data.forEach((config) => {
      initialToggleState[config.tag_settings_id] = config.isActive;
    });
    setLocalToggleState(initialToggleState);
  };

  const handleSettingChange = async (tag) => {
    const currentActiveState = localToggleState[tag.id] || false; // Get current state from localToggleState or default to false

    try {
      const formData = {
        tag_settings_id: tag.id,
        store_id: tag.store_id, // Assuming store_id is part of the tag object
        isActive: !currentActiveState, // Toggle the current value
      };

      // Update the local toggle state immediately
      setLocalToggleState((prevState) => ({
        ...prevState,
        [tag.id]: !currentActiveState,
      }));

      const res = await create_tags_config(formData);
    } catch (error) {
      // Revert the local toggle state in case of an error
      setLocalToggleState((prevState) => ({
        ...prevState,
        [tag.id]: currentActiveState, // Revert to previous state
      }));

      if (error?.response?.data?.err) {
        showToast(error.response.data.err, "error");
      } else {
        showToast("Failed to update settings", "error");
      }
    }
  };
  const handleViewCategory = (tag) => {
    if (localToggleState[tag.id]) {
      setSubActiveTab(2);
      setTag(tag);
    }
  };

  useEffect(() => {
    fetchTagConfig();
  }, []);
  return (
    <>
      <div
        onClick={() => {
          handleViewCategory(item);
        }}
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
      >
        <div className={styles.col}>
          <div className={styles.email}>{item.name}</div>
        </div>
        <div className={styles.col}></div>
        <div className={styles.col}></div> <div className={styles.col}></div>
        <div className={styles.col}>
          <Switch
            className={styles.checkbox}
            content={item.name}
            value={localToggleState[item.id] || false} // Use localToggleState for the switch value
            onClick={(e) => e.stopPropagation()}
            onChange={(e) => {
              e.stopPropagation();
              handleSettingChange(item);
            }}
          />
        </div>
      </div>
    </>
  );
};

export default Row;
