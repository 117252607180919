import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./Dropdown.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import Icon from "../../Icon";
import { Tooltip } from "antd";

const Dropdown = ({
  className,
  item,
  visibleSidebar,
  setValue,
  onClose,
  onAction,
}) => {
  const [visible, setVisible] = useState(false);

  const { pathname } = useLocation();
  useEffect(() => {
    if (!document.querySelector(".fixed-tooltip-container")) {
      const container = document.createElement("div");
      container.className = "fixed-tooltip-container";
      Object.assign(container.style, {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        pointerEvents: "none",
        zIndex: 1000,
      });
      document.body.appendChild(container);
    }
  }, []);

  const handleClick = () => {
    setVisible(!visible);
    setValue(true);
  };

  const Head = () => {
    return (
      <>
        {visibleSidebar ? (
          <button
            className={cn(
              styles.head,
              {
                [styles.active]: pathname.includes(item.slug),
              },
              { [styles.wide]: visibleSidebar }
            )}
            onClick={() => handleClick()}
          >
            <Icon name={item.icon} size="24" />
            {item.title}
            <Icon name="arrow-down" size="24" />
          </button>
        ) : (
          <Tooltip
            placement="right"
            title={item.title}
            overlayClassName="fixed-tooltip"
            getPopupContainer={() =>
              document.querySelector(".fixed-tooltip-container")
            }
          >
            <button
              className={cn(
                styles.head,
                {
                  [styles.active]: pathname.includes(item.slug),
                },
                { [styles.wide]: visibleSidebar }
              )}
              onClick={() => handleClick()}
            >
              <Icon name={item.icon} size="24" />
              {item.title}
              <Icon name="arrow-down" size="24" />
            </button>
          </Tooltip>
        )}
      </>
    );
  };

  return (
    <div
      className={cn(
        styles.dropdown,
        className,
        { [styles.active]: visible },
        {
          [styles.active]: pathname.includes(item.slug),
        },
        { [styles.wide]: visibleSidebar }
      )}
    >
      {item.add ? (
        <div
          className={cn(styles.top, {
            [styles.active]: pathname.startsWith("/products/add"),
          })}
        >
          <Head />
          <Link
            className={cn(styles.add, {
              [styles.active]: pathname.startsWith("/products/add"),
            })}
            to="/products/add"
            onClick={onClose}
          >
            <Icon name="plus" size="10" />
          </Link>
        </div>
      ) : (
        <Head />
      )}
      <div className={styles.body}>
        {item.dropdown.map((x, index) => (
          <NavLink
            className={cn(styles.link, {
              [styles.active]: pathname === x.url,
            })}
            to={x.url}
            key={index}
            // onClick={onClose}
            onClick={() => {
              if (x.action) {
                onAction(x.action);
              } else if (x.url) {
              }
            }}
          >
            {x.title}
            {x.counter ? (
              <div
                className={styles.counter}
                style={{ backgroundColor: x.colorCounter }}
              >
                {x.counter}
              </div>
            ) : (
              <Icon name="arrow-next" size="24" />
            )}
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default Dropdown;
