import React from "react";
import cn from "classnames";
import styles from "./Item.module.sass";

import moment from "moment";
const Item = ({ className, item, onClick }) => {
  const capitalizeFirstLetter = (string) => {
  
    return string?.split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  return (
    <div className={cn(styles.item, className)}>
      <div className={styles.link} onClick={onClick}>
        <div className={styles.details}>
          <div className={styles.name_container}>
            {" "}
            <div className={styles.title}>{item.data.name}</div>
            <div className={styles.date_content}>
              {moment(item.data.created_at).format("YYYY-MM-DD")}
            </div>
          </div>

          <div className={styles.status_container}>
            {" "}
            <div
              className={cn({
                "status-role-active":
                  "Paid" === capitalizeFirstLetter(item.data.financial_status),
                "status-role-inactive":
                  "Paid" !== capitalizeFirstLetter(item.data.financial_status),
              })}
            >
              {capitalizeFirstLetter(
                item.data.financial_status ? item.data.financial_status : "-"
              )}
            </div>
            <div style={{ marginLeft: "10px" }}>
              {" "}
              <div
                className={cn({
                  "status-green-dark":
                    "Fulfilled" ===
                    capitalizeFirstLetter(item.data.fulfillment_status),
                  "status-yellow":
                    "Fulfilled" !==
                    capitalizeFirstLetter(item.data.fulfillment_status),
                })}
              >
                {capitalizeFirstLetter(
                  item.data.fulfillment_status
                    ? item.data.fulfillment_status
                    : "-"
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Item;
