import { useState, useEffect } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";

import moment from "moment";
import { update_tags_category } from "../../../../../API/TagsSettings";
import CustomToast from "../../../../../components/CustomToast";

import { useMenu } from "../../../../../context/Roles/MenuContext";
import Switch from "../../../../../components/Switch";
const Row = ({ item, activeTable, activeId }) => {
  const { tag } = useMenu();
  const showToast = CustomToast();
  const [localToggleState, setLocalToggleState] = useState({});
  useEffect(() => {
    setLocalToggleState((prevState) => ({
      ...prevState,
      [item.id]: item.isActive || false, // Initialize with item.isActive value
    }));
  }, [item.isActive, item.id]);

  const handleSettingChange = async (tag_category) => {
    const currentActiveState = localToggleState[tag_category.id] || false; // Get current state from localToggleState or default to false

    try {
      const formData = {
        category_id: tag_category.id,

        isActive: !currentActiveState, // Toggle the current value
      };

      // Update the local toggle state immediately
      setLocalToggleState((prevState) => ({
        ...prevState,
        [tag_category.id]: !currentActiveState,
      }));

      const res = await update_tags_category(formData);
    } catch (error) {
      // Revert the local toggle state in case of an error
      setLocalToggleState((prevState) => ({
        ...prevState,
        [tag_category.id]: currentActiveState, // Revert to previous state
      }));

      if (error?.response?.data?.err) {
        showToast(error.response.data.err, "error");
      } else {
        showToast("Failed to update settings", "error");
      }
    }
  };
  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
      >
        <div className={styles.col}>
          <div className={styles.email}>{item.name}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.email}>{item.description}</div>
        </div>

        <div className={styles.col}>
          <div className={styles.email}>{item.creator.full_name}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.email}>
            {moment(item.createdAt).format("DD MMMM YYYY")}
          </div>
        </div>
        <div className={styles.col}>
          <Switch
            className={styles.checkbox}
            content={tag.name}
            value={localToggleState[item.id] || false}
            // Use localToggleState for the switch value
            onChange={() => handleSettingChange(item)}
          />
        </div>
      </div>
    </>
  );
};

export default Row;
