import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Update.module.sass";

import Icon from "../../../../components/Icon";
import TextInput from "../../../../components/TextInput";
import Dropdown from "../../../../components/Dropdown";
import { useMenu } from "../../../../context/Roles/MenuContext";
import {
  update_camera,
  get_camera_details,
  delete_camera,
} from "../../../../API/Camera";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import Card from "../../../../components/Card";
import { useFormikContext } from "../../context/SettingsContext";
import {
  getStoredCameras,
  getCameraById,
  removeCameraFromStorage,
  addOrUpdateCameraInStorage,
} from "../../../../utils/cameraUtils";

const UpdateCamera = () => {
  const { formikRef, deleteRef } = useFormikContext();
  const { setSubActiveTab, camera } = useMenu();
  const [initialValues, setInitialValues] = useState({
    name: "",
    camera_id: "",
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("Camera name is required"),
    camera_id: Yup.string().required("Select a camera"),
  });

  const [cameras, setCameras] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function getCameras() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true,
        });
        stream.getTracks().forEach((track) => track.stop());

        const devices = await navigator.mediaDevices.enumerateDevices();
        const videoDevices = devices.filter(
          (device) => device.kind === "videoinput"
        );
        // Filter out the devices that are already stored
        const storedCameras = getStoredCameras();
        const filteredDevices = videoDevices.filter(
          (device) =>
            !storedCameras.some(
              (storedCamera) => storedCamera.deviceId === device.deviceId
            )
        );

        setCameras(filteredDevices);
      } catch (error) {
        console.error("Error accessing camera: ", error);
        setError("Camera access denied. Please enable camera permissions.");
      }
    }

    const fetchCameraDetails = async () => {
      try {
        const response = await get_camera_details(camera.id);
        const cameraDetails = response.data;
        const storedCamera = getCameraById(camera.id);

        setInitialValues({
          name: cameraDetails.name,
          camera_id: storedCamera ? storedCamera.deviceId : "",
        });
      } catch {
        message.error("Failed to fetch barcode details.");
      }
    };
    fetchCameraDetails();
    getCameras();
  }, []);

  const handleSave = async (
    values,
    { setTouched, setErrors, validateForm }
  ) => {
    try {
      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        setTouched({
          name: true,
          camera_id: true,
        });
        setErrors(errors);
        return;
      }

      const formData = {
        id: camera.id,
        name: values.name,
        deviceId: values.camera_id,
        brand: values.brand,
      };

      const res = await update_camera(formData);
      addOrUpdateCameraInStorage(formData);
      if (res.status === 200) {
        setSubActiveTab(1);
        message.success(res.data.message);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data.errors;
        message.error(Object.values(errors[0])[0]);
      } else if (
        error.response.status === 500 ||
        error.response.status === 403
      ) {
        message.error(error.response.data.err);
      }
    }
  };
  const handleDelete = async () => {
    const res = await delete_camera(camera.id);
    if (res.status === 200) {
      setSubActiveTab(1);
      message.success("Camera deleted successfully");
      removeCameraFromStorage(camera.id);
    }
  };

  const handleBack = () => {
    setSubActiveTab(1);
  };
  useEffect(() => {
    deleteRef.current = handleDelete; // Set the delete function in context
  }, [deleteRef]);

  return (
    <div>
      {error && <div className={styles.error}>{error}</div>}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSave}
        innerRef={formikRef}
      >
        {({ isSubmitting, setFieldValue, values, errors, touched }) => (
          <Form>
            <Card
              className={cn(styles.card)}
              title="Update Camera"
              classTitle="title-red"
              head={
                <div className={styles.flex_box}>
                  <button className={cn("button-stroke")} onClick={handleBack}>
                    <Icon name="arrow-left" size="24" />
                    <span>Back</span>
                  </button>
                </div>
              }
            >
              <div className={styles.description}>
                <div className={styles.group}>
                  <div>
                    <Field name="name">
                      {({ field, meta }) => (
                        <TextInput
                          {...field}
                          label="Camera Name"
                          tooltip="Maximum 50 characters."
                          className={cn(styles.field)}
                          error={meta.touched && meta.error ? meta.error : ""}
                          required
                        />
                      )}
                    </Field>
                  </div>
                  <div>
                    <Field name="camera_id">
                      {({ field, meta }) => (
                        <Dropdown
                          {...field}
                          label="Select Camera"
                          tooltip="Select the camera to be used."
                          options={cameras.map(
                            (camera) =>
                              camera.label || `Camera ${camera.deviceId}`
                          )}
                          value={
                            cameras.find(
                              (camera) => camera.deviceId === field.value
                            )?.label || "Device not connected"
                          }
                          setValue={(value) => {
                            const selectedCamera = cameras.find(
                              (camera) => camera.label === value
                            );
                            setFieldValue(field.name, selectedCamera?.deviceId);
                            setFieldValue("brand", selectedCamera?.label || "");
                          }}
                          onChange={(value) => setFieldValue(field.name, value)}
                          className={cn(styles.field)}
                          error={meta.touched && meta.error ? meta.error : ""}
                        />
                      )}
                    </Field>
                  </div>
                </div>
              </div>
            </Card>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default UpdateCamera;
