import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/auth/authSlice";
import permissionsReducer from "../features/accessControl/acccesControlSlice";
const Store = configureStore({
  reducer: {
    auth: authReducer,
    permissions: permissionsReducer,
  },
});
export default Store;
