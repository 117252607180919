import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./InventoryMode.module.sass";
import cn from "classnames";
import { Divider } from "antd";
import Radio from "../../components/Radio";

const InventoryMode = ({ onClose }) => {
  const [selectedMode, setSelectedMode] = useState("OnHand");
  const navigate = useNavigate();

  const inventoryModes = [
    {
      id: "OnHand",
      title: "On Hand Inventory",
      description:
        "The total amount of inventory at a location. This is the sum of the available and committed items. This is true representation of what is currently in your warehouse.",
    },
    {
      id: "Available",
      title: "Available Inventory",
      description:
        "Inventory at your store that can be sold. Used for adding new stock or removing stock without affecting inventory committed to orders.",
    },
  ];

  const handleModeChange = (id) => {
    setSelectedMode(id);
  };

  const handleConfirm = () => {
    navigate(`/updateinventory?mode=${selectedMode}`);
    onClose();
  };

  return (
    <>
      <div className={styles.details}>
        <div className={cn("title-purple", styles.title)}>Inventory Mode</div>
        <Divider />
        <div className={styles.radioGroup}>
          {inventoryModes.map((mode) => (
            <Radio
              key={mode.id}
              className={styles.radio}
              content={`<strong>${mode.title}</strong><br/><p style="padding:15px">${mode.description}</p>`}
              name="inventoryMode"
              value={selectedMode === mode.id}
              onChange={() => handleModeChange(mode.id)}
              reverse
            />
          ))}
        </div>
        <div className={styles.btns}>
          <button
            className={cn("button-stroke", styles.button)}
            onClick={onClose}
          >
            Close
          </button>
          <button
            className={cn("button", styles.button)}
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      </div>
    </>
  );
};

export default InventoryMode;
