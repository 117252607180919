import { createContext, useContext, useRef, useState } from "react";

const FormikContext = createContext(null);

export const FormikProvider = ({ children }) => {
  const formikRef = useRef(null);
  const deleteRef = useRef(null);
  const printComponentRef = useRef(null)

  return (
    <FormikContext.Provider value={{ formikRef, deleteRef ,printComponentRef}}>
      {children}
    </FormikContext.Provider>
  );
};

export const useFormikContext = () => useContext(FormikContext);
