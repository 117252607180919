import React from "react";
import cn from "classnames";
import styles from "./Sync.module.sass";
import { use100vh } from "react-div-100vh";

import { useNavigate } from "react-router-dom";
import Image from "../../components/Image";

import DarkLogo from "../../assets/images/ppg_logo_in_dark.svg";
import { findFirstAccessibleRoute } from "../../utils/permissionUtils";

const Sync = () => {
  const heightWindow = use100vh();
  const navigate = useNavigate();
  const findPath = async () => {
    const firstAccessibleRoute = await findFirstAccessibleRoute();

    if (firstAccessibleRoute) {
      navigate(firstAccessibleRoute);
    } else {
      navigate("/unauthorized");
    }
  };

  return (
    <div style={{ minHeight: heightWindow }}>
      <div className={styles.modal}>
        <div className={cn(styles.outer)}>
          <div>
            <div className={styles.header}>
              <Image
                className={styles.pic}
                src="/logo.png"
                srcDark={DarkLogo}
                alt="Core"
              />{" "}
              <h3>PickPackGo</h3>
            </div>
            <h2 className={styles.subheader}>Sync your data with Shopify</h2>
            <p>
              For the best experience, we are syncing your store data with
              PickPackGo for seamless access.
            </p>
            <button className={cn("button", styles.button)} onClick={findPath}>
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Sync;
