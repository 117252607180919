import custom_axios from "../axios";
export const get_tags_settings = async (url) => {
  try {
    const data = {
      url: url,
    };
    const response = await custom_axios.get(`/store-settings/tags`, data);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch tag settings:", error);
    throw error;
  }
};

export const get_tags_config = async (url) => {
  try {
    const data = {
      url: url,
    };
    const response = await custom_axios.get(
      `/store-settings/tags_config`,
      data
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch tag config:", error);
    throw error;
  }
};

export const create_tags_config = async (data) => {
  try {
    const formData = {
      tag_settings_id: data.tag_settings_id,

      isActive: data.isActive,
    };
    const response = await custom_axios.post(
      `/store-settings/tags/switch-tag-settings`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Failed to update tags config:", error);
    throw error;
  }
};

export const get_tag_category = async (id) => {
  try {
    const response = await custom_axios.get(
      `/store-settings/tags_category/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch tag category:", error);
    throw error;
  }
};

export const update_tags_category = async (data) => {
  try {
    const formData = {
      category_id: data.category_id,

      isActive: data.isActive,
    };
    const response = await custom_axios.put(
      `/store-settings/tags/category`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Failed to  update tag category:", error);
    throw error;
  }
};

export const create_tags_category = async (data) => {
  try {
    const response = await custom_axios.post(
      `/store-settings/tags/category`,
      data
    );
    return response;
  } catch (error) {
    console.error("Failed to create  tag category:", error);
    throw error;
  }
};
