import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./View.module.sass";
import Item from "../../Item";
import Tooltip from "../../../../components/Tooltip";
import CustomToast from "../../../../components/CustomToast";
import {
  get_store_settings,
  update_barcode_settings,
} from "../../../../API/StoreSettings";

import Switch from "../../../../components/Switch";
const View = () => {
  const showToast = CustomToast();
  const [settings, setSettings] = useState();

  const fetchSettings = async () => {
    const res = await get_store_settings();

    setSettings(
      res.data.barcode_visibility ? res.data.barcode_visibility : false
    );
  };

  const handleSettingChange = async () => {
    try {
      const res = await update_barcode_settings(!settings);
      if (res.status == 200) {
        setSettings(!settings);
      }
    } catch (error) {
      if (error?.response?.data?.err) {
        showToast(error.response.data.err, "error");
      } else {
        showToast("Failed to update settings", "error");
      }
    }
  };
  useEffect(() => {
    fetchSettings();
  }, []);

  return (
    <Item
      className={cn(styles.card)}
      title="Pick list"
      classTitle="title-green"
    >
      <div className={styles.container}>
        <div className={cn(styles.label)}>
          <p>Show Product Barcode</p>
          <Tooltip
            className={styles.tooltip}
            title={"Barcode visibility in pick list "}
            icon="info"
            place="right"
          />
        </div>

        <Switch
          className={styles.switch}
          value={settings}
          onChange={() => handleSettingChange()}
        />
      </div>
    </Item>
  );
};

export default View;
