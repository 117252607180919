import React, { useEffect, useState } from "react";
import styles from "./InventoryLogs.module.sass";
import cn from "classnames";
import Card from "../../../components/Card";
import costaxios from "../../../axios";
import InventoryTable from "./InventoryTable";
import { GET_INVENTORY_LOGS } from "../../../API/Inventory";
import Loader from "../../../components/Loader";
const InventoryLogs = () => {
  const [inventoryLogs, setInventoryLogs] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchInventoryLogs = async () => {
      setLoading(true);
      try {
        const data = await GET_INVENTORY_LOGS();
        setInventoryLogs(data);
      } catch (error) {
        console.error("Error fetching inventory logs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchInventoryLogs();
  }, []);
  return (
    <>
      {loading && <Loader className={styles.loader} />}
      <Card>
        <>
          <div
            className={cn("title-red", styles.subtitle)}
            style={{ letterSpacing: "0" }}
          >
            <div>Inventory Logs</div>
          </div>
        </>
      </Card>
      <Card>
        <InventoryTable data={inventoryLogs} />
      </Card>
    </>
  );
};

export default InventoryLogs;
