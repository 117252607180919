import { get_all_privileges } from "../API/RBAC";
import { get_store_sync_status } from "../API/Store";
import routesConfig from "../config/routesConfig";
export const findRoute = async () => {
  const result = await get_all_privileges();
  const store_sync_status = await get_store_sync_status();

  const order_sync =
    store_sync_status.data.order_sync === "COMPLETED" ? true : false;
  const permissions = result.data.data;

  if (!order_sync) {
    return "/sync";
  } else if (permissions === "All") {
    return routesConfig[0].path;
  }
  for (const route of routesConfig) {
    const hasAccess = route.moduleCodes.some((moduleCode, index) =>
      permissions.some(
        (perm) =>
          perm.module_permission.module_id === moduleCode &&
          perm.module_permission.code === route.requiredPrivileges[index]
      )
    );

    if (
      hasAccess ||
      (route.moduleCodes.length === 0 && route.requiredPrivileges.length === 0)
    ) {
      return route.path;
    }
  }
  return "/unauthorized";
};

export const findFirstAccessibleRoute = async () => {
  const result = await get_all_privileges();

  const permissions = result.data.data;

  if (permissions === "All") {
    return routesConfig[0].path;
  }
  for (const route of routesConfig) {
    const hasAccess = route.moduleCodes.some((moduleCode, index) =>
      permissions.some(
        (perm) =>
          perm.module_permission.module_id === moduleCode &&
          perm.module_permission.code === route.requiredPrivileges[index]
      )
    );

    if (
      hasAccess ||
      (route.moduleCodes.length === 0 && route.requiredPrivileges.length === 0)
    ) {
      return route.path;
    }
  }
  return "/unauthorized";
};
