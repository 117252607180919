import React from "react";
import { Formik, Form, Field } from "formik";

import cn from "classnames";
import styles from "./Create.module.sass";
import Item from "../../Item";

import TextInput from "../../../../components/TextInput";

import { useMenu } from "../../../../context/Roles/MenuContext";

import { create_role } from "../../../../API/RBAC";

import * as Yup from "yup";
import { message } from "antd";
import { useFormikContext } from "../../context/SettingsContext";
const CreateRole = () => {
  const { setSubActiveTab, setIsMenuVisible, role } = useMenu();
  const { formikRef } = useFormikContext();
  const { setRole } = useMenu();
  const initialValues = { name: "" };
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is required"),
  });
  const handleSave = async (values) => {
    try {
      const formData = {
        role: values.name,
      };
      const res = await create_role(formData);

      setRole(res.data.data);
      if (res.status === 201) {
        setSubActiveTab(3);
        setIsMenuVisible(false);
      }
    } catch (error) {
      const errors = error.response.data.errors;

      if (error.response.status === 400) {
        message.error(Object.values(errors[0])[0]);
      }
    }
  };
  return (
    <Item
      className={cn(styles.card)}
      title="Create Role"
      classTitle="title-red"
    >
      {" "}
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={validationSchema}
        onSubmit={handleSave}
        innerRef={formikRef}
      >
        {({ isSubmitting, setFieldValue, resetForm, values }) => (
          <Form>
            <div className={styles.description}>
              <Field name="name">
                {({ field, meta }) => (
                  <TextInput
                    {...field}
                    label="Role Name"
                    tooltip="Maximum 50 characters."
                    className={styles.field}
                    error={meta.touched && meta.error ? meta.error : ""}
                    required
                  />
                )}
              </Field>
            </div>
          </Form>
        )}
      </Formik>
    </Item>
  );
};

export default CreateRole;
