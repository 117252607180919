import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./View.module.sass";
import Item from "../../../Item";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import Table from "../../Table";
import {
  get_tag_category,
  create_tags_category,
} from "../../../../../API/TagsSettings";
import { useMenu } from "../../../../../context/Roles/MenuContext";
import CustomToast from "../../../../../components/CustomToast";
import TextInput from "../../../../../components/TextInput";
import { useFormikContext } from "../../../context/SettingsContext";
import Modal from "../../../../../components/Modal/index";
import Card from "../../../../../components/Card";
import Icon from "../../../../../components/Icon";
const View = () => {
  const showToast = CustomToast();
  const { formikRef } = useFormikContext();
  const [visible, setVisible] = useState(false);
  const [tagCategory, setTagCategory] = useState([]);
  const [visibleModal, setVisibleModal] = useState(false);
  const { tag, setSubActiveTab } = useMenu();
  const handleCreateTag = () => {
    setVisibleModal(true);
  };

  const fetchAllTagCategory = async () => {
    const res = await get_tag_category(tag.id);
    setTagCategory(res.data);
  };

  useEffect(() => {
    fetchAllTagCategory();
  }, []);
  const initialValues = {
    tag_name: "",
    description: "",
  };
  const handleSubmit = async (values) => {
    try {
      const formData = {
        tag_id: tag.id,
        name: values.tag_name,
        description: values.description,
      };

      const res = await create_tags_category(formData);

      if (res.status == 201) {
        showToast("Tag created", "success");
        setVisibleModal(false);
        fetchAllTagCategory();
      }
    } catch (err) {
      console.error("Error creating tags", err);
      showToast("Failed to update settings", "error");
    }
  };
  const validationSchema = Yup.object({
    tag_name: Yup.string().required("name is required"),

    description: Yup.string().required("Description is required"),
  });

  return (
    <Card
      className={cn(styles.card)}
      title={tag.name}
      classTitle="title-green"
      head={
        <button
          className={cn("button-stroke")}
          onClick={() => setSubActiveTab(1)}
        >
          <Icon name="arrow-left" size="24" />
          <span>Back</span>
        </button>
      }
    >
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleCreateTag}
        innerRef={formikRef}
      >
        {({ isSubmitting, setFieldValue, values, errors, touched }) => (
          <Form>{/* Empty form to trigger formik ref */}</Form>
        )}
      </Formik>
      <div className={styles.list}>
        <Table
          data={tagCategory}
          className={styles.table}
          activeTable={visible}
          setActiveTable={setVisible}
        />
      </div>
      <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ isSubmitting, setFieldValue, resetForm, values }) => (
            <Form>
              <Card
                className={cn(styles.card)}
                title="Create a Tag"
                classTitle="title-green"
              >
                <div className={styles.description}>
                  <Field name="tag_name">
                    {({ field, meta }) => (
                      <TextInput
                        {...field}
                        value={values.full_name}
                        label="Tag Name"
                        tooltip="Maximum 100 characters. No HTML or emoji allowed"
                        className={styles.field}
                        error={meta.touched && meta.error ? meta.error : ""}
                      />
                    )}
                  </Field>
                </div>
                <div className={styles.description}>
                  <Field name="description">
                    {({ field, meta }) => (
                      <TextInput
                        {...field}
                        value={values.mobile_no}
                        label="Description"
                        tooltip="Maximum 100 characters. No HTML or emoji allowed"
                        className={styles.field}
                        error={meta.touched && meta.error ? meta.error : ""}
                      />
                    )}
                  </Field>
                </div>
                <div className={styles.btns}>
                  <button
                    className={cn("button-stroke", styles.button)}
                    onClick={() => setVisibleModal(false)}
                  >
                    <span>Cancel</span>
                  </button>

                  <button
                    className={cn("button", styles.button)}
                    onClick={(e) => {
                      e.stopPropagation(); 
                      handleSubmit(values);
                    }}
                  >
                    Save
                  </button>
                </div>
              </Card>
            </Form>
          )}
        </Formik>
      </Modal>
    </Card>
  );
};

export default View;
