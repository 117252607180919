import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../components/Checkbox";
import Icon from "../../../../../components/Icon";
import moment from "moment";
import { useNavigate } from "react-router-dom";

const Row = ({ item, value, onChange, index }) => {
  const navigate = useNavigate();
  const formatDate = (date) => {
    const dateObj = moment(date);
    if (dateObj.isSame(moment(), "day")) {
      return `Today at ${dateObj.format("h:mm A")}`;
    }
    return dateObj.format("MMMM D, YYYY [at] h:mm A");
  };

  const capitalizeFirstLetter = (string) => {
    return string
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  const handleRowClick = () => {
    navigate(`/inventorylog/${item.id}`);
  };

  return (
    <>
      <div className={styles.row} onClick={handleRowClick}>
        <div className={styles.col}>
          <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          />
        </div>
        <div className={styles.col}>{index}</div>
        <div className={styles.col}>
          {item.updated_by.full_name ? item.updated_by.full_name : "-"}
        </div>
        <div className={styles.col}>
          {item.inventory_mode
            ? capitalizeFirstLetter(item.inventory_mode)
            : "-"}
        </div>
        <div className={styles.col}>
          {item.created_at ? formatDate(item.created_at) : "-"}
        </div>
        <div className={styles.col}>{item.products ? item.products : "-"}</div>
        {/* <div className={styles.col}>{item.items ? item.items : "-"}</div> */}
        <div className={styles.col}>{item.notes ? item.notes : "-"}</div>
      </div>
    </>
  );
};

export default Row;
