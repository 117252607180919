import custom_axios from "../axios";

export const get_all_roles = async () => {
  try {
    const response = await custom_axios.get(`/role`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch roles:", error);
    throw error;
  }
};

export const get_all_permitted_roles = async () => {
  try {
    const response = await custom_axios.get(`/role/permitted_roles`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch roles:", error);
    throw error;
  }
};

export const create_role = async (formData) => {
  try {
    const response = await custom_axios.post(`/role`, formData);
    return response;
  } catch (error) {
    console.error("Failed to create roles:", error);
    throw error;
  }
};

export const get_all_modules = async () => {
  try {
    const response = await custom_axios.get(`/auth_module/`);
    return response.data;
  } catch (error) {
    console.error("Failed to get all roles:", error);
    throw error;
  }
};

export const get_all_module_permission = async (id) => {
  try {
    const response = await custom_axios.get(
      `/auth_module/module_permissions/${id}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to get all role  permissions:", error);
    throw error;
  }
};

export const assign_module_permission = async (formData) => {
  try {
    const response = await custom_axios.post(
      `/auth_module/permission_assignment/`,
      formData
    );
    return response.data;
  } catch (error) {
    console.error("Failed to assign permission:", error);
    return false;
  }
};

export const get_role_permissions = async (formData) => {
  try {
    const response = await custom_axios.get(
      `/auth_module/permission_assignment/1`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to get role permission:", error);
    throw error;
  }
};

export const get_module__assigned_permissions = async (role_id, module_id) => {
  try {
    const response = await custom_axios.get(
      `/auth_module/module_permission_assigned/${role_id}/${module_id}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to get module permission:", error);
    throw error;
  }
};

export const remove_module_permission = async (formData) => {
  try {
    const response = await custom_axios.delete(
      `/auth_module/permission_assignment/${formData.role_id}/`,
      { data: { module_permission_ids: formData.module_permission_ids } }
    );
    return response.data;
  } catch (error) {
    console.error("Failed to delete  permission:", error);
    return false;
  }
};

export const get_role_by_id = async (id) => {
  try {
    const response = await custom_axios.get(`/role/${id}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch  role info:", error);
    throw error;
  }
};

export const update_role_by_id = async (id, formData) => {
  try {
    const response = await custom_axios.patch(`/role/${id}`, formData);
    return response;
  } catch (error) {
    console.error("Failed to update  role info:", error);
    throw error;
  }
};

export const get_all_privileges = async (id, formData) => {
  try {
    const response = await custom_axios.get(`/auth_module/privileges/`);
    return response;
  } catch (error) {
    console.error("Failed to update  privileges info:", error);
    throw error;
  }
};
