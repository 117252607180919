import axios from "axios";

const env = process.env;

const login = async (userData) => {
  const response = await axios.post(
    env.REACT_APP_API_PATH_LOCAL + "/employee/send-otp",
    userData
  );
  return response;
};

const verifyOtp = async (userData) => {
  const response = await axios.post(
    env.REACT_APP_API_PATH_LOCAL + "/employee/verify-otp",
    userData
  );
  if (response.data) {
    localStorage.setItem("token", response.data.token);
  }
  return response;
};

const signUp = async (userData) => {
  const formData = { email: userData.email, full_name: userData.full_name };
  const response = await axios.post(
    env.REACT_APP_API_PATH_LOCAL + "/auth/send-otp",
    formData,
    {
      headers: {
        Authorization: `Bearer ${userData.token}`,
        "Content-Type": "application/json",
      },
    }
  );
  return response;
};

const logout = async () => {
  localStorage.clear();
  window.location.href = "/sign-in";
};


const getStores = async (userData) => {
  const response = await axios.post(
    env.REACT_APP_API_PATH_LOCAL + "/employee/user-stores",
    userData
  );
  return response;
};

const authService = {
  login,
  logout,
  signUp,
  verifyOtp,
  getStores
};


export default authService;
