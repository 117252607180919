import custom_axios from "../axios";
export const get_store_sync_status = async (id, formData) => {
  try {
    const response = await custom_axios.get(`/shopify/store/`);
    return response.data;
  } catch (error) {
    console.error("Failed to find store sync info:", error);
    return true;
  }
};

export const logo_presigned_url = async (formData) => {
  try {
    const data = {
      name: formData.name,
      type: formData.type,
    };
    const response = await custom_axios.post(
      `/shopify/generate-store-logo-presigned-url`,
      data
    );
    return response;
  } catch (error) {
    console.error("Failed to generate  upload url:", error);
    throw error;
  }
};
