import React from "react";
import cn from "classnames";
import styles from "../Released.module.sass";
import Icon from "../../../../components/Icon";
import { Tooltip, message } from "antd";
import { EXPORT_ORDERS } from "../../../../API/Orders"; // Import the API function

function Export({
  checkedItems,
  risk,
  returnStatus,
  tags,
  notTaggedWith,
  app,
  channel,
  deliveryMethod,
  returrn,
  delivery,
  financial,
  fulfillment,
  valueFilterByDate,
  valueFilterByToDate,
  valueFilterByFromDate,
  page,
  totalItems,
  search,
  selectedEmployee,
  setPage,
  setTotalItems,
  itemsPerPage,
  activeButton,
  setActiveButton,
}) {
  const generateQueryParam = (key, value) => (value ? `${key}=${value}` : "");

  const handleExport = async () => {
    try {
      const queryParams = [
        { key: "riskLevel", value: risk },
        { key: "returnStatus", value: returnStatus },
        { key: "channel", value: channel },
        { key: "app", value: app },
        { key: "deliveryMethod", value: deliveryMethod },
        //  { key: "financialStatus", value: financial },
        // { key: "fulfillmentStatus", value: fulfillment },
        // { key: "orderNumber", value: search },
        // { key: "fromDate", value: valueFilterByFromDate },
        // { key: "toDate", value: valueFilterByToDate },
        // { key: "page", value: page },
        // { key: "pageSize", value: itemsPerPage },
        // { key: "assignTo", value: selectedEmployee },
      ];

      const queryString = queryParams
        .map((param) => generateQueryParam(param.key, param.value))
        .filter(Boolean)
        .join("&");

      const payload = {};

      if (tags && tags.length > 0) {
        payload.taggedWith = Array.isArray(tags) ? tags.join(",") : tags;
      }

      if (notTaggedWith && notTaggedWith.length > 0) {
        payload.notTaggedWith = Array.isArray(notTaggedWith)
          ? notTaggedWith.join(",")
          : notTaggedWith;
      }

      const selectedIds = Object.keys(checkedItems)
        .filter((key) => checkedItems[key])
        .map(Number);

      if (selectedIds.length > 0) {
        payload.ids = selectedIds;
      }

      const response = await EXPORT_ORDERS(queryString, payload);
      const downloadUrl = URL.createObjectURL(response);
      const downloadLink = document.createElement("a");
      downloadLink.href = downloadUrl;
      downloadLink.setAttribute("download", "orders.xlsx");
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } catch (error) {
      message.error("Error exporting orders:", error);
    }
  };

  const handleClick = () => {
    setActiveButton("export");
    handleExport();
  };

  return (
    <>
      <Tooltip title="Export">
        <div style={{ justifyContent: "end" }}>
          <div className="relative">
            <button
              className={cn(styles.link, {
                [styles.active]: activeButton === "export",
              })}
              onClick={handleClick}
            >
              <Icon name="export" size="24" />
            </button>
          </div>
        </div>
      </Tooltip>
    </>
  );
}

export default Export;

// import React from "react";
// import cn from "classnames";
// import styles from "../Released.module.sass";
// import Icon from "../../../../components/Icon";
// import { Tooltip, message } from "antd";
// import custome_axios from "../../../../axios";

// function Export({
//   checkedItems,
//   page,
//   totalItems,
//   search,
//   risk,
//   tags,
//   notTaggedWith,
//   delivery,
//   financial,
//   app,
//   returrn,
//   channel,
//   fulfillment,
//   valueFilterByDate,
//   valueFilterByToDate,
//   valueFilterByFromDate,
//   selectedEmployee,
//   setPage,
//   setTotalItems,
//   itemsPerPage,
//   activeButton,
//   setActiveButton,
// }) {
//   const generateQueryParam = (key, value) => (value ? `${key}=${value}` : "");

//   const handleExport = async () => {
//     try {
//       const queryParams = [
//         { key: "financialStatus", value: financial },
//         { key: "fulfillmentStatus", value: fulfillment },
//         { key: "orderNumber", value: search },
//         { key: "taggedWith", value: tags },
//         { key: "notTaggedWith", value: notTaggedWith },
//         { key: "fromDate", value: valueFilterByFromDate },
//         { key: "toDate", value: valueFilterByToDate },
//         { key: "page", value: page },
//         { key: "pageSize", value: itemsPerPage },
//         { key: "assignTo", value: selectedEmployee },
//       ];

//       const queryString = queryParams
//         .map((param) => generateQueryParam(param.key, param.value))
//         .filter(Boolean)
//         .join("&");

//       const ids = Object.keys(checkedItems)
//         .filter((key) => checkedItems[key])
//         .map(Number);

//       const response = await custome_axios.post(
//         `/order/exportOrder?${queryString}`,
//         { ids },
//         { responseType: "blob" }
//       );
//       const downloadUrl = URL.createObjectURL(response.data);
//       const downloadLink = document.createElement("a");
//       downloadLink.href = downloadUrl;
//       downloadLink.setAttribute("download", "orders.xlsx");
//       document.body.appendChild(downloadLink);
//       downloadLink.click();
//       document.body.removeChild(downloadLink);
//     } catch (error) {
//       message.error("Error exporting orders:", error);
//     }
//   };
//   const handleClick = () => {
//     setActiveButton("export");
//     handleExport();
//   };

//   return (
//     <>
//       <Tooltip title="Export">
//         <div style={{ justifyContent: "end" }}>
//           <div className="relative">
//             <button
//               className={cn(styles.link, {
//                 [styles.active]: activeButton === "export",
//               })}
//               onClick={handleClick}
//             >
//               <Icon name="export" size="24" />
//             </button>
//             {/* <button className={cn(styles.link)} onClick={handleExport}>
//               <Icon name="export" size="24" />
//             </button> */}
//           </div>
//         </div>
//       </Tooltip>
//     </>
//   );
// }

// export default Export;
