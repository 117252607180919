import custom_axios from "../axios";
export const get_all_cameras = async (formData) => {
  try {
    const response = await custom_axios.get(`/camera`);
    return response.data;
  } catch (error) {
    console.error("Failed to get all camera:", error);
    throw error;
  }
};

export const get_all_barcode = async (formData) => {
  try {
    const response = await custom_axios.get(`/camera/setting/all`);
    return response.data;
  } catch (error) {
    console.error("Failed to get all barcode:", error);
    throw error;
  }
};

export const create_barcode = async (formData) => {
  try {
    const response = await custom_axios.post(`/camera/setting`, formData);
    return response;
  } catch (error) {
    console.error("Failed to create barcode ", error);
    throw error;
  }
};

export const update_barcode = async (formData) => {
  try {
    const response = await custom_axios.patch(
      `/camera/barcode-setting/${formData.barcode}`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Failed to update barcode ", error);
    throw error;
  }
};

export const get_camera_modes = async () => {
  try {
    const response = await custom_axios.get(`/camera/settings/modes`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch camera modes", error);
    throw error;
  }
};
export const get_barcode_setting = async (barcode) => {
  try {
    const response = await custom_axios.get(
      `/camera/barcode-setting/${barcode}`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to fetch camera modes", error);
    throw error;
  }
};

export const delete_barcode_camera = async (barcode) => {
  try {
    const response = await custom_axios.delete(
      `/camera/barcode-setting/${barcode}/2`
    );
    return response.data;
  } catch (error) {
    console.error("Failed to delete barcode camera", error);
    throw error;
  }
};

export const delete_barcode = async (barcode) => {
  try {
    const response = await custom_axios.delete(
      `/camera/barcode-setting/${barcode}`
    );
    return response;
  } catch (error) {
    console.error("Failed to delete barcode", error);
    throw error;
  }
};

export const create_camera = async (formData) => {
  try {
    const response = await custom_axios.post(`/camera/`, formData);
    return response;
  } catch (error) {
    console.error("Failed to create camera ", error);
    throw error;
  }
};

export const get_camera_details = async (device_id) => {
  try {
    const response = await custom_axios.get(`/camera/${device_id}`);
    return response.data;
  } catch (error) {
    console.error("Failed to fetch camera modes", error);
    throw error;
  }
};

export const update_camera = async (formData) => {
  try {
    const response = await custom_axios.patch(
      `/camera/${formData.id}`,
      formData
    );
    return response;
  } catch (error) {
    console.error("Failed to update barcode ", error);
    throw error;
  }
};

export const delete_camera = async (id) => {
  try {
    const response = await custom_axios.delete(`/camera/${id}`);
    return response;
  } catch (error) {
    console.error("Failed to delete camera", error);
    throw error;
  }
};
