import React, { useState } from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../components/Checkbox";
import Icon from "../../../../../components/Icon";

const Row = ({ item, value, onChange, mode, updateValue }) => {
  const calculateNewValue = () => {
    const currentValue = item.VariantInventory.on_hand;
    if (mode === "increment") return currentValue + updateValue;
    if (mode === "decrement") return Math.max(0, currentValue - updateValue);
    return updateValue; // for 'set by' mode
  };
  const newValue = item.VariantInventory.on_hand + item.updateBy;
  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          <Checkbox
            className={styles.checkbox}
            value={value}
            onChange={onChange}
          />
        </div>
        <div className={styles.col}>
          <div className={styles.item}>
            <div className={styles.preview}>
              <img
                srcSet={`${item.image2x} 2x`}
                src={item.image}
                alt="Product"
              />
            </div>
            <div className={styles.details}>
              <div className={styles.product}>{item.title}</div>
              <div className={styles.wrap}>
                <div className={styles.category}> SKU : {item.sku}</div>
                <div className={cn("status-green-dark", styles.status)}>
                  ₹ {item.price}
                </div>
              </div>
            </div>
            <div className={styles.details} style={{ marginLeft: "5px" }}>
              <div className={cn("status-role-active", styles.product)}>
                {item.barcode}
              </div>
            </div>
            <div className={styles.detailss} style={{ marginLeft: "30px" }}>
              <div className={styles.wrap} style={{ display: "flex" }}>
                <span className={styles.onhold}>
                  {item.VariantInventory.on_hand}
                </span>{" "}
                <div className={cn(styles.arrow)} style={{ marginTop: "4px" }}>
                  <Icon name="arrow-right" size="16" />
                </div>{" "}
                <span>{newValue}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.col}>{item.updateBy}</div>
        <div className={styles.col}>
          {item.VariantInventory.quality_control}
        </div>
        <div className={styles.col}>{item.VariantInventory.committed}</div>
        <div className={styles.col}>{item.VariantInventory.available}</div>
        <div className={styles.col}>{item.VariantInventory.on_hand}</div>
      </div>
    </>
  );
};

export default Row;
