import styles from "./Row.module.sass";
import cn from "classnames";

import moment from "moment";

import { useMenu } from "../../../../../context/Roles/MenuContext";

const Row = ({ item, activeTable, activeId }) => {
  const { setSubActiveTab, setRole } = useMenu();

  const handleUpdate = (role) => {
    setRole(role);
    if (item.type === "MASTER") {
      setSubActiveTab(3);
    } else {
      setSubActiveTab(4);
    }
  };
  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.id },
          { [styles.active]: activeTable }
        )}
        onClick={() => handleUpdate(item)}
      >
        <div className={styles.col}>
          <div className={styles.email}>{item.role}</div>
        </div>
        <div className={styles.col}>
          <div className={styles.email}>
            {item.type == "CUSTOM" ? item.creator.full_name : "Default"}
          </div>
        </div>

        <div className={styles.col}>
          <div className={styles.email}>
            {moment(item.created_at).format("DD MMM YYYY")}
          </div>
        </div>
        <div className={styles.col}>
          <div
            className={cn(styles.purchase, {
              "status-role-active": item.status === true,
              "status-role-inactive": item.status === false,
            })}
          >
            {item.status ? "Active" : "Inactive"}
          </div>
        </div>
      </div>
    </>
  );
};

export default Row;
