"use client";

import Layout from "../../components/Layout/Layout";
import Join from "../../components/Join/Join";
import Reviews from "../../components/Reviews/Reviews";
import Main from "../../templates/HomePage/Main/Main";
import Solutions from "../../templates/HomePage/Solutions/Solutions";
import Details from "../../templates/HomePage/Details/Details";
import Global from "../../templates/HomePage/Global/Global";

const HomePage = () => {
    return (
        <Layout darkHeader>
            <Main />
            <Solutions />
            <Details />
            <Global />
            <Reviews />
            <Join />
        </Layout>
    );
};

export default HomePage;
