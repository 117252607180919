import Dividers from "../../../components/Dividers/Dividers";
import Image from "../../../components/Image/Image";

const details = [
    {
        title: "Monitor Packing",
        content: "Launch a B2B business and collect one-time or recurring payments from customers.",
        image: "/images/contents/morfdesk/monitor-packing.png",
    },
    {
        title: "Generate Picklists",
        content: "Test your product idea by launching payments with little to no code.",
        image: "/images/contents/morfdesk/generate-picklist.png",
    },
    {
        title: "Manage Inventory",
        content: "Test your product idea by launching payments with little to no code.",
        image: "/images/contents/morfdesk/manage-inventory.png"
    },

];
const details2 = [
    {
        title: "Get Insights",
        content: "Launch a B2C business with a prebuilt payment page that’s optimized for conversion.",
        image: "/images/contents/morfdesk/getInsight.png",
    },
    {
        title: "Generate Barcodes",
        content: "Launch a B2B business and collect one-time or recurring payments from customers.",
        image: "/images/contents/morfdesk/generate-barcode.png",
    },
    {
        title: "Manage Staff",
        content: "Launch a B2C business with a prebuilt payment page that’s optimized for conversion.",
        image: "/images/contents/morfdesk/manage-staff.png",
    },

];


const Details = () => (
    <Dividers className="pt-24 pb-28 bg-greyscale-25 xl:pb-24 md:py-12">
        <div className="container">
            <div className="mb-18 lg:mb-14 md:mb-10">
                <div className="stage">Modular Architecture</div>
                <div className="mb-4 text-h3 md:text-h4">
                    Tailored for Growth
                </div>
                <div className="max-w-[80.9rem] text-greyscale-400">
                    Start with one module, or all of them! Pick Pack Go scales with your business and allows you to keep all processes seamlessly integrated.
                    From inventory management to order processing, our modular approach ensures flexibility and efficiency, adapting to your evolving needs.
                </div>
            </div>
            <div className="flex space-x-8 lg:block lg:space-x-0 lg:space-y-10 md:space-y-6">
                {details.map((item, index) => (
                    <div className="flex-1" key={index}>
                        <div className="mb-8 p-2 bg-greyscale-0 border border-greyscale-100 shadow-2 lg:mb-6">
                            <div className="border border-primary-50">
                                <Image
                                    className="w-full"
                                    src={item.image}
                                    width={345}
                                    height={230}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="mb-3 text-h5 xl:text-h6 lg:text-h5 md:text-h6">
                            {item.title}
                        </div>
                        <div className="text-greyscale-400">{item.content}</div>
                    </div>
                ))}
            </div>
            <div className="flex space-x-8 lg:block lg:space-x-0 lg:space-y-10 md:space-y-6" style={{ marginTop: "30px" }}>
                {details2.map((item, index) => (
                    <div className="flex-1" key={index}>
                        <div className="mb-8 p-2 bg-greyscale-0 border border-greyscale-100 shadow-2 lg:mb-6">
                            <div className="border border-primary-50">
                                <Image
                                    className="w-full"
                                    src={item.image}
                                    width={345}
                                    height={230}
                                    alt=""
                                />
                            </div>
                        </div>
                        <div className="mb-3 text-h5 xl:text-h6 lg:text-h5 md:text-h6">
                            {item.title}
                        </div>
                        <div className="text-greyscale-400">{item.content}</div>
                    </div>
                ))}
            </div>
        </div>

    </Dividers>

);

export default Details;
