import React, { useEffect, useState } from "react";
import cn from "classnames";
import styles from "./View.module.sass";
import Item from "../../Item";

import Table from "../Table";
import { get_all_barcode } from "../../../../API/Camera";
import { useMenu } from "../../../../context/Roles/MenuContext";
import { Formik, Form } from "formik";
import { useFormikContext } from "../../context/SettingsContext";

const View = () => {
  const { formikRef } = useFormikContext();
  const [visible, setVisible] = useState(false);

  const { setSubActiveTab } = useMenu();
  const [barcodes, setBarcodes] = useState([]);
  const handleCreateBarcode = () => {
    setSubActiveTab(2);
  };

  const fetchAllBarcode = async () => {
    const res = await get_all_barcode();

    setBarcodes(res.data);
  };
  const initialValues = {
    name: "",
  };
  useEffect(() => {
    fetchAllBarcode();
  }, []);
  return (
    <Item className={cn(styles.card)} title="Barcode" classTitle="title-green">
      <Formik
        initialValues={initialValues}
        enableReinitialize
        onSubmit={handleCreateBarcode}
        innerRef={formikRef}
      >
        {({ isSubmitting, setFieldValue, values, errors, touched }) => (
          <Form>{/* Empty form to trigger formik ref */}</Form>
        )}
      </Formik>
      <div className={styles.list}>
        <Table
          data={barcodes}
          className={styles.table}
          activeTable={visible}
          setActiveTable={setVisible}
        />
      </div>
    </Item>
  );
};

export default View;
