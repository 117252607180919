import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Icon from "../Icon";

const Dropdown = ({
  className,
  classDropdownHead,
  value,
  setValue,
  options,
  dropdownStyles,
  disabled = false,
  onLabelClick,
}) => {
  const [visible, setVisible] = useState(false);

  const handleClick = (option) => {
    option.onClick();
    setVisible(false);
  };

  const toggleDropdown = (e) => {
    e.stopPropagation();
    if (!disabled) {
      setVisible(!visible);
    }
  };

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div
        className={cn(styles.dropdown, className, { [styles.active]: visible })}
      >
        <div
          className={cn(styles.head, classDropdownHead)}
          style={dropdownStyles}
        >
          <div className={styles.selection} onClick={onLabelClick}>
            {value}
          </div>
          <div
            className={cn(styles.arrowWrapper, { [styles.active]: visible })}
            onClick={toggleDropdown}
          >
            {/* <Icon name="arrow-bottom" size="24" /> */}
          </div>
        </div>
        {visible && !disabled && (
          <div
            className={cn(
              styles.body,
              "absolute top-full left-0 mt-1 w-full bg-white rounded shadow-lg"
            )}
          >
            {options.map((option, index) => (
              <div
                className={cn(styles.option, option.className)}
                onClick={() => handleClick(option)}
                key={index}
              >
                {option.icon && (
                  <span className={cn("mr-2", styles.icon)}>{option.icon}</span>
                )}
                <span>{option.label}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Dropdown;
