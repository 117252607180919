import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Update.module.sass";

import Icon from "../../../../components/Icon";
import TextInput from "../../../../components/TextInput";
import Dropdown from "../../../../components/Dropdown";
import { useMenu } from "../../../../context/Roles/MenuContext";
import {
  update_barcode,
  get_all_cameras,
  get_camera_modes,
  get_barcode_setting,
  delete_barcode,
} from "../../../../API/Camera";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { message } from "antd";
import Card from "../../../../components/Card";
import { useFormikContext } from "../../context/SettingsContext";

const UpdateBarcode = () => {
  const { formikRef, deleteRef } = useFormikContext();
  const { setSubActiveTab, barcode } = useMenu();

  const [initialValues, setInitialValues] = useState({
    name: "",
    cameraSettings: [{ camera_id: "", purpose_id: "" }],
  });

  const validationSchema = Yup.object({
    name: Yup.string().required("Barcode is required"),
    cameraSettings: Yup.array().of(
      Yup.object().shape({
        camera_id: Yup.string().required("Camera is required"),
        purpose_id: Yup.string().required("Purpose is required"),
      })
    ),
  });

  const [cameraOptions, setCameraOptions] = useState([]);
  const [purpose, setPurpose] = useState([]);

  const handleSave = async (
    values,
    { setTouched, setErrors, validateForm }
  ) => {
    try {
      const errors = await validateForm();
      if (Object.keys(errors).length > 0) {
        setTouched({
          name: true,
          cameraSettings: values.cameraSettings.map(() => ({
            camera_id: true,
            purpose_id: true,
          })),
        });
        setErrors(errors);
        return;
      }

      const formData = {
        barcode: values.name,
        cameras: values.cameraSettings.map((item) => {
          return { id: item.camera_id, mode: item.purpose_id };
        }),
      };

      const res = await update_barcode(formData);

      if (res.status === 200) {
        setSubActiveTab(1);

        message.success(res.data.message);
      }
    } catch (error) {
      if (error.response.status === 400) {
        const errors = error.response.data.errors;
        message.error(Object.values(errors[0])[0]);
      } else if (error.response.status === 500) {
        message.error(error.response.data.err);
      }
    }
  };
  const handleDelete = async () => {
    const res = await delete_barcode(barcode.barcode_value);

    if (res.status === 200) {
      setSubActiveTab(1);
      message.success("Barcode deleted successfully");
    }
  };
  useEffect(() => {
    deleteRef.current = handleDelete;
  }, [deleteRef]);
  useEffect(() => {
    const fetchCameras = async () => {
      try {
        const response = await get_all_cameras();
        setCameraOptions(response.data);
      } catch (error) {
        message.error("Failed to fetch cameras.");
      }
    };

    const fetchPurpose = async () => {
      try {
        const response = await get_camera_modes();
        const result = Object.entries(response.data).map(([key, value]) => ({
          id: key,
          name: value,
        }));
        setPurpose(result);
      } catch {
        message.error("Failed to fetch camera modes.");
      }
    };

    const fetchBarcodeDetails = async () => {
      try {
        const response = await get_barcode_setting(barcode.barcode_value);
        const barcodeDetails = response.data;

        setInitialValues({
          name: barcodeDetails.barcode_value,
          cameraSettings: barcodeDetails.cameras.map((camera) => ({
            camera_id: camera.id,
            purpose_id: camera.mode,
          })),
        });
      } catch {
        message.error("Failed to fetch barcode details.");
      }
    };

    fetchCameras();
    fetchPurpose();
    fetchBarcodeDetails();
  }, [barcode.barcode_value]);

  const handleBack = () => {
    setSubActiveTab(1);
  };

  const handleDropdownChange = (index, field, value, setFieldValue, values) => {
    const newSelectedCameras = [...values.cameraSettings];
    newSelectedCameras[index] = {
      ...newSelectedCameras[index],
      [field.split(".")[2]]: value,
    };
    setFieldValue("cameraSettings", newSelectedCameras);
  };

  const handleRemoveSet = (index, remove) => {
    remove(index);
  };

  const getAvailableCameras = (selectedCameras) => {
    const selectedCameraIds = selectedCameras.map(
      (setting) => setting.camera_id
    );
    return cameraOptions.filter(
      (camera) => !selectedCameraIds.includes(camera.id)
    );
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={handleSave}
      innerRef={formikRef}
    >
      {({ isSubmitting, setFieldValue, values, errors, touched }) => (
        <Form>
          <Card
            className={cn(styles.card)}
            title="Edit Barcode"
            classTitle="title-red"
            head={
              <div className={styles.flex_box}>
                <button className={cn("button-stroke")} onClick={handleBack}>
                  <Icon name="arrow-left" size="24" />
                  <span>Back</span>
                </button>
              </div>
            }
          >
            <div className={styles.description}>
              <div className={styles.txt_container}>
                <Field name="name">
                  {({ field, meta }) => (
                    <TextInput
                      {...field}
                      label="Barcode"
                      tooltip="Maximum 50 characters."
                      className={cn(styles.field)}
                      error={meta.touched && meta.error ? meta.error : ""}
                      required
                      disabled
                    />
                  )}
                </Field>
              </div>
              <FieldArray name="cameraSettings">
                {({ insert, remove, push }) => (
                  <>
                    {values.cameraSettings.length > 0 &&
                      values.cameraSettings.map((setting, index) => (
                        <>
                          <div className={styles.groups} key={index}>
                            <div className={styles.group}>
                              <Field name={`cameraSettings.${index}.camera_id`}>
                                {({ field, meta }) => (
                                  <Dropdown
                                    {...field}
                                    label="Select Camera"
                                    tooltip="Maximum 100 characters. No HTML or emoji allowed"
                                    value={
                                      cameraOptions.find(
                                        (option) =>
                                          option.id ===
                                          values.cameraSettings[index].camera_id
                                      )?.name || ""
                                    }
                                    setValue={(value) => {
                                      const selectedCameraId =
                                        cameraOptions.find(
                                          (option) => option.name === value
                                        )?.id || "";
                                      handleDropdownChange(
                                        index,
                                        `cameraSettings.${index}.camera_id`,
                                        selectedCameraId,
                                        setFieldValue,
                                        values
                                      );
                                    }}
                                    options={getAvailableCameras(
                                      values.cameraSettings
                                    ).map((option) => option.name)}
                                    className={cn(styles.field)}
                                    error={
                                      meta.touched && meta.error
                                        ? meta.error
                                        : ""
                                    }
                                  />
                                )}
                              </Field>
                              <Field
                                name={`cameraSettings.${index}.purpose_id`}
                              >
                                {({ field, meta }) => (
                                  <Dropdown
                                    {...field}
                                    label="Select Purpose"
                                    tooltip="Maximum 100 characters. No HTML or emoji allowed"
                                    value={
                                      purpose.find(
                                        (option) =>
                                          option.id ===
                                          values.cameraSettings[index]
                                            .purpose_id
                                      )?.name || ""
                                    }
                                    setValue={(value) => {
                                      setFieldValue(
                                        `cameraSettings.${index}.purpose_id`,
                                        purpose.find(
                                          (option) => option.name === value
                                        )?.id || ""
                                      );

                                      handleDropdownChange(
                                        index,
                                        `cameraSettings.${index}.purpose_id`,
                                        purpose.find(
                                          (option) => option.name === value
                                        )?.id || "",
                                        setFieldValue,
                                        values
                                      );
                                    }}
                                    options={purpose.map(
                                      (option) => option.name
                                    )}
                                    className={cn(styles.field)}
                                    error={
                                      meta.touched && meta.error
                                        ? meta.error
                                        : ""
                                    }
                                  />
                                )}
                              </Field>
                            </div>{" "}
                            <div className={styles.delete_container}>
                              {index > 0 ? (
                                <button
                                  type="button"
                                  className={cn(styles.link, styles.active)}
                                  onClick={() => handleRemoveSet(index, remove)}
                                >
                                  <Icon name="trash" size="24" />
                                </button>
                              ) : (
                                <button
                                  type="button"
                                  className={cn(styles.empty_box)}
                                >
                                  {/* <Icon name="trash" size="24" /> */}
                                </button>
                              )}
                            </div>
                          </div>
                          <div>
                            {values.cameraSettings.length > 0 &&
                            values.cameraSettings.every(
                              (setting) =>
                                setting.camera_id !== "" &&
                                setting.purpose_id !== ""
                            ) &&
                            getAvailableCameras(values.cameraSettings).length >
                              0 ? (
                              <button
                                type="button"
                                className={cn("button-stroke", styles.button)}
                                onClick={() =>
                                  push({ camera_id: "", purpose_id: "" })
                                }
                              >
                                Add Camera
                              </button>
                            ) : (
                              ""
                            )}
                          </div>
                        </>
                      ))}
                  </>
                )}
              </FieldArray>
            </div>
          </Card>
        </Form>
      )}
    </Formik>
  );
};

export default UpdateBarcode;
