import React, { useState } from "react";
import styles from "./Table.module.sass";
import cn from "classnames";

import Row from "./Row";

const Table = ({ data, className, activeTable, setActiveTable }) => {
  const [activeId, setActiveId] = useState();

  const [selectedFilters, setSelectedFilters] = useState([]);

  return (
    <div className={cn(styles.wrapper, className)}>
      <div className={cn(styles.table)}>
        <div className={cn(styles.row, { [styles.active]: activeTable })}>
          <div className={styles.col}>Tag</div>
          <div className={styles.col}></div>
          <div className={styles.col}></div>
          <div className={styles.col}></div>
          <div className={styles.col}>Status</div>
        </div>
        {data?.map((role, index) => (
          <Row
            item={role}
            key={index}
            activeTable={activeTable}
            setActiveTable={setActiveTable}
            activeId={activeId}
            setActiveId={setActiveId}
          />
        ))}
      </div>
    </div>
  );
};

export default Table;
