import React, { useState, useEffect } from "react";
import styles from "./Row.module.sass";
import Checkbox from "../../../../../components/Checkbox/index.js";
import ModalProduct from "../../../../../components/ModalProduct";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import cn from "classnames";

const Row = ({ key, data, checkedItems = {}, handleCheckboxChange }) => {
  const [visibleModalProduct, setVisibleModalProduct] = useState(false);
  const navigate = useNavigate();

  const tagClassNames = [
    "status-green-dark",
    "status-yellow",
    "status-purple-dark",
    "status-light_orange-dark",
    "status-light-dark",
  ];

  const formatTagName = (name) => {
    return name
      .split("_")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const assignTagColors = (tags) => {
    return tags.map((tag, index) => ({
      ...tag,
      className: tagClassNames[index % tagClassNames.length],
    }));
  };

  const processValue = (key, value) => {
    if (
      key === "created_at" ||
      key === "processed_at" ||
      key === "updated_at"
    ) {
      return <span>{moment(value).format("YYYY-MM-DD")}</span>;
    } else if (key === "financial_status") {
      const formattedValue = value.replace(/_/g, " ");
      const capitalizedValue =
        formattedValue.charAt(0).toUpperCase() + formattedValue.slice(1);
      return (
        <div
          className={cn(styles.purchase, {
            "status-role-active": capitalizedValue === "Paid",
            "status-role-inactive": capitalizedValue !== "Paid",
          })}
        >
          {capitalizedValue}
        </div>
      );
    } else if (key === "fulfillment_status") {
      if (!value) {
        return "-";
      } else {
        const formattedValue = value?.replace(/_/g, " ");
        const capitalizedValue =
          formattedValue?.charAt(0).toUpperCase() + formattedValue?.slice(1);
        return <div className={cn("status-yellow")}>{capitalizedValue}</div>;
      }
    } else if (key === "tags") {
      const taggedItems = Array.isArray(value) ? value : [];
      const tagsWithColors = assignTagColors(taggedItems);
      const firstTag = tagsWithColors.length > 0 ? tagsWithColors[0] : null;
      const tagCount = tagsWithColors.length;

      const tags = `
        <div style="display:flex;font-weight: bold; margin-bottom:30px;margin-top:20px; margin-left:10px">
          <div style="padding: 0 8px;border-radius: 6px; font-size: 12px;background:#FFD88D;font-size:20px">${tagCount}</div>
          <div style="padding: 0 8px;border-radius: 6px; font-size: 12px;font-size:20px;margin-left:10px">Tags</div>
        </div>
        ${tagsWithColors
          .map(
            (tag, index) =>
              `<li style="margin-left:10px;margin-bottom:20px" key=${index} class="${
                tag.className
              }">${formatTagName(tag.tag_name)}</li>`
          )
          .join("")}
      `;

      return firstTag ? (
        <div>
          <ul
            data-tooltip-id={`tooltip-${data.id}`}
            data-tooltip-html={tags}
            className={firstTag.className}
          >
            {formatTagName(firstTag.tag_name)}
          </ul>
          <span
            className={cn(
              "bg-gray-200 px-[8px] py-[3px] rounded-lg ml-[5px]",
              styles.tagname
            )}
          >
            {tagCount > 1 ? `+${tagCount}` : tagCount}
          </span>
        </div>
      ) : (
        "-"
      );
    } else if (
      Array.isArray(value) &&
      value.length > 0 &&
      typeof value[0] === "object"
    ) {
      return "";
    }
    return value;
  };

  const handleRowClick = () => {
    navigate(`/order-details/${data.order_number}`);
  };

  return (
    <>
      <div className={styles.row}>
        <div className={styles.col}>
          {/* <Checkbox
            className={styles.checkbox}
            checked={checkedItems[data.id] || false}
            onChange={(e) => handleCheckboxChange(e, null, data.id)}
          /> */}
          <Checkbox
            className={styles.checkbox}
            checked={checkedItems[data.id] || false}
            onChange={(e) => {
              console.log("Row checkbox clicked:", data.id);
              handleCheckboxChange(e, null, data.id);
            }}
          />
        </div>
        {/* <div className={styles.col}>{key}</div> */}
        {Object.entries(data).map(([key, value], colIndex) => (
          <div
            key={colIndex}
            className={`whitespace-nowrap ${styles.col}`}
            onClick={handleRowClick}
          >
            {processValue(key, value) || "-"}
          </div>
        ))}
        <Tooltip
          id={`tooltip-${data.id}`}
          place="left"
          style={{
            backgroundColor: "#ffffff",
            color: "black",
            height: "auto",
            width: "auto",
            padding: "10px",
            borderRadius: "20px",
            opacity: "10",
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            zIndex: 900,
            maxWidth: "60%",
          }}
        />
      </div>
      <ModalProduct
        visible={visibleModalProduct}
        onClose={() => setVisibleModalProduct(false)}
      />
    </>
  );
};

export default Row;
