import React from "react";
import cn from "classnames";
import styles from "./Panel.module.sass";

const Panel = ({ notes, setNotes, onClearAll, onUpdateInventory }) => {
  return (
    <div className={cn("panel", styles.panel)}>
      <div className={styles.info}>
        <input
          className={styles.input}
          type="text"
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
          placeholder="Write a note..."
        />
      </div>
      <button
        type="button"
        className={cn("button-stroke", styles.button)}
        onClick={onClearAll}
      >
        Clear All
      </button>
      <button
        className={cn("button", styles.button)}
        onClick={onUpdateInventory}
      >
        Update Inventory
      </button>
    </div>
  );
};

export default Panel;
