import React, { createContext, useState, useContext } from "react";

const MenuContext = createContext();

export const useMenu = () => useContext(MenuContext);

export const MenuProvider = ({ children }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [subActiveTab, setSubActiveTab] = useState(1);
  const [role, setRole] = useState();
  const [barcode, setBarcode] = useState();
  const [camera, setCamera] = useState();
  const [tag, setTag] = useState();
  return (
    <MenuContext.Provider
      value={{
        isMenuVisible,
        setIsMenuVisible,
        subActiveTab,
        setSubActiveTab,
        role,
        setRole,
        barcode,
        setBarcode,
        camera,
        setCamera,
        tag,
        setTag,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};
