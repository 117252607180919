import React from "react";
import styles from "./Row.module.sass";
import cn from "classnames";
import Checkbox from "../../../../../../components/Checkbox";
import Icon from "../../../../../../components/Icon";

const Row = ({ item, value, onChange, index }) => {
  // console.log("items", item);
  return (
    <div className={styles.row}>
      <div className={styles.col}>
        <Checkbox
          className={styles.checkbox}
          value={value}
          onChange={onChange}
        />
      </div>
      <div className={styles.col}>{index}</div>
      <div className={styles.col}>{item.variant.title || "-"}</div>
      <div className={styles.col}>{item.variant.barcode || "-"}</div>
      <div className={styles.col}>{item.variant.sku || "-"}</div>
      <div className={styles.col}>{item.bin_location || "-"}</div>
      <div className={styles.col} style={{ display: "flex", gap: "6px" }}>
        {item.from}{" "}
        <div className={styles.arrow} style={{ marginTop: "4px" }}>
          <Icon name="arrow-right" size="16" />
        </div>{" "}
        {item.to}
      </div>
      <div className={styles.col}>{item.quantity || "-"}</div>
    </div>
  );
};

export default Row;
