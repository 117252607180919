import styles from "./Row.module.sass";
import cn from "classnames";

const Row = ({ item, activeTable, activeId, onChange }) => {
  return (
    <>
      <div
        className={cn(
          styles.row,
          { [styles.selected]: activeId === item.code },
          { [styles.active]: activeTable }
        )}
        onClick={onChange}
      >
        <div className={styles.col}>
          <div className={styles.email}>{item.name}</div>
        </div>
      </div>
    </>
  );
};

export default Row;
