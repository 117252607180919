import React, { useState } from "react";
import styles from "./Comments.module.sass";
import Item from "./Item";
import TooltipGlodal from "../../../../../components/TooltipGlodal";
const Comments = ({ orderDetails }) => {
  const [content, setContent] = useState();

  return (
    <>
      {/* <div className={cn(styles.comments)}> */}
      <div className={styles.head}>
        <div className={styles.title}>
          <div
            className={styles.counter}
            style={{ backgroundColor: "#FFD88D" }}
          >
            {orderDetails?.OrderDetails?.note ? 1 : 0}
          </div>
          Notes
        </div>
        {/* <button className={styles.close}>
          <Icon name="close" size="24" />
        </button> */}
      </div>
      <div className={styles.list}>
        {orderDetails?.OrderDetails?.note ? (
          <Item className={styles.item} orderDetails={orderDetails} />
        ) : (
          "No notes from customer"
        )}
      </div>
      <TooltipGlodal />
    </>
  );
};

export default Comments;
