import React from "react";
import cn from "classnames";
import { Pagination } from "antd";
import { BiCamera } from "react-icons/bi";

const icons = {
  sun: "M8.002 12a4 4 0 1 0 0-8 4 4 0 1 0 0 8zM7.999 1c.368 0 .667.298.667.667v.667a.67.67 0 0 1-.667.667c-.368 0-.667-.298-.667-.667v-.667c0-.368.298-.667.667-.667zm0 12c.368 0 .667.298.667.667v.665a.67.67 0 0 1-.667.667c-.368 0-.667-.298-.667-.667v-.665c0-.368.298-.667.667-.667zM1 7.999c0-.368.298-.667.667-.667h.667c.368 0 .667.298.667.667s-.298.667-.667.667h-.667c-.368 0-.667-.298-.667-.667zm12 0c0-.368.298-.667.667-.667h.664c.368 0 .667.298.667.667s-.298.667-.667.667h-.664c-.368 0-.667-.298-.667-.667zm-9.95 4.949a.67.67 0 0 1 0-.943l.471-.471a.67.67 0 0 1 .943 0c.261.26.26.682 0 .943l-.471.471a.67.67 0 0 1-.943 0zm8.485-8.485a.67.67 0 0 1 0-.943l.47-.47a.67.67 0 0 1 .943 0c.261.26.26.682 0 .943l-.47.47a.67.67 0 0 1-.943 0zM3.05 3.05a.67.67 0 0 1 .943 0l.471.471a.67.67 0 0 1 0 .943c-.26.261-.682.26-.943 0l-.471-.471a.67.67 0 0 1 0-.943zm8.485 8.485a.67.67 0 0 1 .943 0l.47.47a.67.67 0 0 1 0 .943c-.26.261-.682.26-.943 0l-.47-.47a.67.67 0 0 1 0-.943z",
  moon: "M5.377 4.017c-1.235.841-2.044 2.257-2.044 3.861A4.67 4.67 0 0 0 8 12.544c1.603 0 3.02-.809 3.861-2.044a7.34 7.34 0 0 1-6.484-6.484zm7.29 5.194c.577 0 1.075.509.846 1.039-.92 2.134-3.042 3.628-5.513 3.628a6 6 0 0 1-6-6c0-2.471 1.494-4.593 3.628-5.513.53-.228 1.039.269 1.039.846a6 6 0 0 0 6 6z",
  "arrow-down":
    "M4.471 5.528c-.26-.26-.682-.26-.943 0s-.26.682 0 .943l.943-.943zM8 10l-.471.471c.26.26.682.26.943 0L8 10zm4.471-3.529c.26-.26.26-.682 0-.943s-.682-.26-.943 0l.943.943zm-8.943 0l4 4 .943-.943-4-4-.943.943zm4.943 4l4-4-.943-.943-4 4 .943.943z",
  "arrow-next":
    "M5.528 11.528c-.26.26-.26.682 0 .943s.682.26.943 0l-.943-.943zM10 8l.471.471c.26-.26.26-.682 0-.943L10 8zM6.471 3.528c-.26-.26-.682-.26-.943 0s-.26.682 0 .943l.943-.943zm0 8.943l4-4-.943-.943-4 4 .943.943zm4-4.943l-4-4-.943.943 4 4 .943-.943z",
  "arrow-left":
    "M7.138 11.862c.26.26.26.682 0 .943s-.682.26-.943 0L2.334 8.943c-.521-.521-.521-1.365 0-1.886l3.862-3.862c.26-.26.682-.26.943 0s.26.682 0 .943L3.943 7.333h9.39c.368 0 .667.298.667.667s-.298.667-.667.667h-9.39l3.195 3.195z",
  "arrow-right":
    "M8.862 11.862c-.26.26-.26.682 0 .943s.682.26.943 0l3.862-3.862c.521-.521.521-1.365 0-1.886L9.805 3.195c-.26-.26-.682-.26-.943 0s-.26.682 0 .943l3.195 3.195H2.667C2.298 7.333 2 7.632 2 8s.298.667.667.667h9.391l-3.195 3.195z",
  "arrow-bottom":
    "M8 1a1 1 0 0 1 .993.883L9 2v9.585l3.293-3.292.094-.081a1 1 0 0 1 1.32 1.495h0v-.001l-4.991 4.993c-.029.03-.061.059-.094.085l-.04.03-.062.041-.027.016-.078.04-.033.014-.067.025-.051.015-.06.015-.053.009-.063.008-.053.003L8 15l-.033-.001-.052-.003L8 15a1.01 1.01 0 0 1-.148-.011l-.054-.01-.058-.013-.052-.016-.069-.025-.033-.015-.07-.035-.039-.023-.063-.042a1.02 1.02 0 0 1-.12-.103l.09.08c-.035-.027-.068-.057-.099-.089L2.293 9.707l-.081-.088a1 1 0 0 1 .522-1.599 1 1 0 0 1 .973.273h0L7 11.585V2a1 1 0 0 1 1-1z",
  "arrow-top":
    "M8 1h.019l.07.004L8 1a1.01 1.01 0 0 1 .149.011l.053.01.061.014.05.015.069.025.032.014.079.04.027.016.063.041c.043.031.085.067.125.106l-.085-.076.085.076 5 5a1 1 0 0 1-1.414 1.414h0 0L9 4.413V14a1 1 0 0 1-1.993.117L7 14V4.413L3.707 7.707l-.088.081a1 1 0 0 1-1.599-.522 1 1 0 0 1 .273-.973h0l5-5 .082-.073.039-.03.062-.042.04-.024.069-.035.034-.015.067-.025.054-.016.056-.014.056-.01.059-.007.07-.004z",
  "arrows-up-down":
    "M11.5 2.666c.368 0 .667.298.667.667h0v8.057l1.529-1.529c.26-.26.682-.26.943 0s.26.682 0 .943h0L12.443 13c-.521.521-1.365.521-1.886 0h0l-2.195-2.195c-.26-.26-.26-.682 0-.943s.682-.26.943 0h0l1.529 1.529V3.333c0-.368.298-.667.667-.667zM5.776 3l2.195 2.195c.26.26.26.682 0 .943s-.682.26-.943 0L5.5 4.609v8.057c0 .368-.298.667-.667.667s-.667-.298-.667-.667V4.609L2.638 6.138c-.26.26-.682.26-.943 0s-.26-.682 0-.943L3.891 3c.521-.521 1.365-.521 1.886 0z",
  store:
    "M11.631 1.333a2 2 0 0 1 1.864 1.275h0l1.192 3.065a1.57 1.57 0 0 1-.688 1.935h0v5.059a2 2 0 0 1-2 2h0-8a2 2 0 0 1-2-2h0V7.608a1.57 1.57 0 0 1-.688-1.935h0l1.192-3.065a2 2 0 0 1 1.864-1.275h0zm-6.965 6h-.001l-.467.234c-.274.137-.567.223-.866.258v4.841c0 .368.298.667.667.667h1.333v-2a2 2 0 0 1 2-2h1.333a2 2 0 0 1 2 2v2h1.333c.368 0 .667-.299.667-.667V7.825c-.299-.035-.592-.122-.866-.258l-.468-.234-.428.171c-.795.318-1.681.318-2.476 0l-.429-.171-.429.171c-.795.318-1.681.318-2.476 0l-.428-.171zm4 3.333H7.333c-.368 0-.667.298-.667.667h0v2h2.667v-2c0-.368-.298-.667-.667-.667h0zm2.965-8h-.474l.741 3.459.03.015h0l.468.234c.274.137.588.171.885.097.138-.035.215-.182.163-.315h0l-1.192-3.065c-.1-.256-.346-.425-.621-.425h0zm-6.79 0h-.474c-.275 0-.522.169-.621.425L2.554 6.157c-.052.133.025.28.163.315.297.074.611.04.885-.097l.468-.234.03-.015.741-3.459zm4.952 0H8.666v3.497l.257.103a2 2 0 0 0 1.486 0l.144-.058-.759-3.543zm-2.461 0H6.205l-.759 3.543.144.058a2 2 0 0 0 1.486 0l.257-.103V2.666z",
  "profile-circle":
    "M8 1.333A6.67 6.67 0 0 1 14.666 8a6.65 6.65 0 0 1-2.294 5.033l-.132.112A6.64 6.64 0 0 1 8 14.666a6.64 6.64 0 0 1-4.373-1.634A6.65 6.65 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 10a3.33 3.33 0 0 0-2.642 1.301c.731.418 1.57.667 2.465.697l.16.003h0H8l.172-.003c.897-.028 1.738-.278 2.47-.697A3.33 3.33 0 0 0 8 11.333zm0-8.667C5.054 2.666 2.666 5.054 2.666 8c0 1.505.623 2.864 1.625 3.833A4.66 4.66 0 0 1 8 10a4.66 4.66 0 0 1 3.708 1.833c1.002-.97 1.625-2.329 1.625-3.833 0-2.946-2.388-5.333-5.333-5.333zM8 4c1.473 0 2.667 1.194 2.667 2.667S9.472 9.333 8 9.333 5.333 8.139 5.333 6.666 6.527 4 8 4zm0 1.333c-.736 0-1.333.597-1.333 1.333S7.263 8 8 8s1.333-.597 1.333-1.333S8.736 5.333 8 5.333z",
  lightning:
    "M10.44 3.711l-.78 2.194h2.443c1.743 0 2.652 2.073 1.471 3.355l-4.657 5.054c-1.475 1.601-4.085.026-3.355-2.025l.78-2.194H3.899c-1.743 0-2.652-2.074-1.471-3.355l4.657-5.054C8.56.084 11.169 1.66 10.44 3.711zM8.065 2.589L3.409 7.644a.67.67 0 0 0 .49 1.118h3.387c.217 0 .42.105.545.282s.156.404.083.608l-1.097 3.084a.67.67 0 0 0 1.118.675l4.656-5.054a.67.67 0 0 0-.49-1.118H8.715c-.217 0-.42-.105-.545-.282s-.156-.404-.083-.608l1.097-3.084a.67.67 0 0 0-1.118-.675z",
  plus: "M8 0a1.6 1.6 0 0 1 1.589 1.413L9.6 1.6v4.8h4.8a1.6 1.6 0 0 1 .187 3.189L14.4 9.6H9.6v4.8a1.6 1.6 0 0 1-3.189.187L6.4 14.4V9.6H1.6a1.6 1.6 0 0 1-.187-3.189L1.6 6.4h4.8V1.6A1.6 1.6 0 0 1 8 0z",
  "plus-circle":
    "M8 1.333A6.67 6.67 0 0 1 14.666 8 6.67 6.67 0 0 1 8 14.666 6.67 6.67 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 1.333C5.054 2.666 2.666 5.054 2.666 8S5.054 13.333 8 13.333 13.333 10.945 13.333 8 10.945 2.666 8 2.666zm0 2c.368 0 .667.298.667.667h0v2h2c.368 0 .667.298.667.667s-.298.667-.667.667h0-2v2c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0v-2h-2c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h0 2v-2c0-.368.298-.667.667-.667z",
  "pie-chart":
    "M6.666 4c.368 0 .667.298.667.667v4h4c.368 0 .667.298.667.667 0 1.055-.313 2.086-.899 2.963s-1.419 1.561-2.394 1.964-2.047.509-3.081.304-1.985-.714-2.731-1.46-1.254-1.696-1.46-2.731-.1-2.107.304-3.081 1.087-1.807 1.964-2.394S5.611 4 6.666 4zM6 5.389a4 4 0 0 0-3.029 2.413 4 4 0 0 0 .867 4.359 4 4 0 0 0 4.359.867A4 4 0 0 0 10.61 10H6.666c-.177 0-.346-.07-.471-.195S6 9.51 6 9.333V5.389zm3.333-4.056c1.414 0 2.771.562 3.771 1.562s1.562 2.357 1.562 3.771c0 .368-.298.667-.667.667H9.333c-.177 0-.346-.07-.471-.195s-.195-.295-.195-.471V2c0-.177.07-.346.195-.471s.295-.195.471-.195zM10 2.722V6h3.277A4 4 0 0 0 10 2.722z",
  home: "M9.338 1.87l4.667 4.2a2 2 0 0 1 .662 1.487v5.109a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V7.557a2 2 0 0 1 .662-1.487l4.667-4.2a2 2 0 0 1 2.676 0zm-.892.991c-.254-.228-.638-.228-.892 0h0l-4.667 4.2c-.14.126-.221.307-.221.496h0v5.109c0 .368.298.667.667.667h0 2V10a2 2 0 0 1 2-2h0 1.333a2 2 0 0 1 2 2h0v3.333h2c.368 0 .667-.298.667-.667h0V7.557c0-.189-.08-.369-.221-.496h0zm.221 6.471H7.333c-.368 0-.667.299-.667.667h0v3.333h2.667V10c0-.368-.298-.667-.667-.667h0z",
  diamond:
    "M11.213 1.333a2 2 0 0 1 1.609.812h0l1.951 2.644a2 2 0 0 1-.022 2.405h0l-5.164 6.736a2 2 0 0 1-3.174 0h0L1.249 7.194a2 2 0 0 1-.022-2.405h0l1.951-2.644a2 2 0 0 1 1.609-.812h0zm2.263 5.333H2.525l4.946 6.452c.267.348.791.348 1.058 0l4.946-6.452zm-2.263-4H4.787c-.212 0-.411.1-.536.271h0L2.483 5.333h11.035l-1.768-2.396c-.126-.17-.325-.271-.536-.271h0z",
  promotion:
    "M9.297 1.563l.848.722c.107.091.24.146.379.157l1.11.089a2 2 0 0 1 1.835 1.835l.089 1.11c.011.14.066.273.157.379l.722.848a2 2 0 0 1 0 2.594l-.722.848c-.091.107-.146.24-.157.379l-.089 1.11a2 2 0 0 1-1.835 1.835l-1.11.089c-.14.011-.273.066-.379.157l-.848.722a2 2 0 0 1-2.594 0l-.848-.722c-.107-.091-.24-.146-.379-.157l-1.11-.089a2 2 0 0 1-1.835-1.835l-.089-1.11c-.011-.14-.066-.273-.157-.379l-.722-.848a2 2 0 0 1 0-2.594l.722-.848c.091-.107.146-.24.157-.379l.089-1.11a2 2 0 0 1 1.835-1.835l1.11-.089c.14-.011.273-.066.379-.157l.848-.722a2 2 0 0 1 2.594 0zm-1.73 1.015L6.72 3.3a2 2 0 0 1-1.138.471l-1.11.089c-.326.026-.585.285-.612.612l-.089 1.11A2 2 0 0 1 3.3 6.72l-.722.848c-.212.249-.212.616 0 .865l.722.848a2 2 0 0 1 .471 1.138l.089 1.11c.026.326.285.586.612.612l1.11.089a2 2 0 0 1 1.138.471l.848.722c.249.212.616.212.865 0l.848-.722a2 2 0 0 1 1.138-.471l1.11-.089c.326-.026.585-.285.611-.612l.089-1.11A2 2 0 0 1 12.7 9.28l.722-.848c.212-.249.212-.616 0-.865L12.7 6.72a2 2 0 0 1-.471-1.138l-.089-1.11c-.026-.326-.285-.585-.611-.612l-1.11-.089A2 2 0 0 1 9.28 3.3l-.848-.722c-.249-.212-.616-.212-.865 0zm2.1 6.089a1 1 0 1 1 0 2 1 1 0 1 1 0-2zm.427-3.179c.283.236.321.656.085.939l-3.333 4c-.236.283-.656.321-.939.085s-.321-.656-.085-.939l3.333-4c.236-.283.656-.321.939-.085zm-3.76-.155a1 1 0 1 1 0 2 1 1 0 1 1 0-2z",
  help: "M8 1.333A6.67 6.67 0 0 1 14.666 8 6.67 6.67 0 0 1 8 14.666 6.67 6.67 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 1.333C5.054 2.666 2.666 5.054 2.666 8S5.054 13.333 8 13.333 13.333 10.945 13.333 8 10.945 2.666 8 2.666zm0 8.667c.368 0 .667.298.667.667s-.298.667-.667.667-.667-.298-.667-.667.298-.667.667-.667zM8 4c1.473 0 2.667 1.194 2.667 2.667a2.67 2.67 0 0 1-2 2.583V10c0 .368-.299.667-.667.667s-.667-.298-.667-.667V8.666C7.333 8.298 7.632 8 8 8c.736 0 1.333-.597 1.333-1.333S8.736 5.333 8 5.333c-.58 0-1.074.37-1.258.889-.123.347-.504.529-.851.406s-.529-.504-.406-.851C5.851 4.743 6.838 4 8 4z",
  search:
    // "M7.333 1.333a6 6 0 0 1 6 6c0 1.417-.491 2.719-1.312 3.745h0l1.783 1.783c.26.26.26.682 0 .943s-.682.26-.943 0h0l-1.783-1.783c-1.026.821-2.328 1.312-3.745 1.312a6 6 0 1 1 0-12zm0 1.333a4.67 4.67 0 0 0-4.667 4.667A4.67 4.67 0 0 0 7.333 12 4.67 4.67 0 0 0 12 7.333a4.67 4.67 0 0 0-4.667-4.667z
    "M12 7.33331C12 9.91064 9.9107 12 7.33337 12C4.75605 12 2.66671 9.91064 2.66671 7.33331C2.66671 4.75598 4.75605 2.66665 7.33337 2.66665C9.9107 2.66665 12 4.75598 12 7.33331ZM12.0213 11.0784C12.8424 10.052 13.3334 8.74999 13.3334 7.33331C13.3334 4.0196 10.6471 1.33331 7.33337 1.33331C4.01967 1.33331 1.33337 4.0196 1.33337 7.33331C1.33337 10.647 4.01967 13.3333 7.33337 13.3333C8.75005 13.3333 10.0521 12.8423 11.0785 12.0212L12.862 13.8047C13.1223 14.0651 13.5444 14.0651 13.8048 13.8047C14.0651 13.5444 14.0651 13.1223 13.8048 12.8619L12.0213 11.0784Z",
  "close-circle":
    "M8 14.666A6.67 6.67 0 0 0 14.666 8 6.67 6.67 0 0 0 8 1.333 6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.666zm-2.471-4.195c-.26-.26-.26-.682 0-.943L7.057 8 5.528 6.471c-.26-.26-.26-.682 0-.943s.682-.26.943 0L8 7.057l1.529-1.529c.26-.26.682-.26.943 0s.26.682 0 .943L8.942 8l1.529 1.529c.26.26.26.682 0 .943s-.682.26-.943 0L8 8.942l-1.529 1.529c-.26.26-.682.26-.943 0z",
  close:
    "M4.471 3.528c-.26-.26-.682-.26-.943 0s-.26.682 0 .943L7.057 8l-3.529 3.529c-.26.26-.26.682 0 .943s.682.26.943 0L8 8.942l3.529 3.529c.26.26.682.26.943 0s.26-.682 0-.943L8.942 8l3.529-3.529c.26-.26.26-.682 0-.943s-.682-.26-.943 0L8 7.057 4.471 3.528z",
  photos:
    "M12.666 1.333a2 2 0 0 1 2 2V10a2 2 0 0 1-2 2H12v.667a2 2 0 0 1-2 2h0-6.667a2 2 0 0 1-2-2h0V6a2 2 0 0 1 2-2h0H4v-.667a2 2 0 0 1 2-2h6.667zM4 5.333h-.667c-.368 0-.667.298-.667.667h0v6.667c0 .368.298.667.667.667h0H10c.368 0 .667-.298.667-.667h0V12H6a2 2 0 0 1-2-2V5.333zm2.893 1.278l-1.56 1.871V10c0 .368.298.667.667.667h5.636L7.896 6.587c-.273-.298-.745-.286-1.004.024zm5.774-3.945H6c-.335 0-.612.247-.659.568l-.007.099V6.4l.535-.642a2 2 0 0 1 2.896-.187l.115.116 4.251 4.638.066.08c.064-.084.109-.184.127-.293l.009-.111V3.333c0-.368-.298-.667-.667-.667zM11 4a1 1 0 1 1 0 2 1 1 0 1 1 0-2z",
  add: "M8.667 3.334c0-.368-.298-.667-.667-.667s-.667.298-.667.667v4h-4c-.368 0-.667.298-.667.667s.298.667.667.667h4v4c0 .368.298.667.667.667s.667-.298.667-.667v-4h4c.368 0 .667-.298.667-.667s-.298-.667-.667-.667h-4v-4z",
  check:
    "M13.138 4.202c.26.26.26.682 0 .943l-5.724 5.724a2 2 0 0 1-2.828 0L2.195 8.478c-.26-.26-.26-.682 0-.943s.682-.26.943 0l2.391 2.391c.26.26.682.26.943 0l5.724-5.724c.26-.26.682-.26.943 0z",
  "check-circle":
    "M8 14.666A6.67 6.67 0 0 0 14.666 8a6.64 6.64 0 0 0-.745-3.066c-.1-.192-.356-.224-.509-.07L8.276 10c-.521.521-1.365.521-1.886 0L4.528 8.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0l1.862 1.862 5.348-5.344c.126-.126.131-.331.004-.456C11.481 2.067 9.826 1.333 8 1.333A6.67 6.67 0 0 0 1.333 8 6.67 6.67 0 0 0 8 14.666z",
  "check-all":
    "M1.528 8.195c.26-.26.682-.26.943 0h0l1.724 1.724.009.009 1.325 1.325a2 2 0 0 1-2.277-.391h0L1.528 9.138c-.26-.26-.26-.682 0-.943zm12.943-3.367c.26.26.26.682 0 .943l-5.091 5.091a2 2 0 0 1-2.828 0L4.828 9.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0l1.724 1.724c.26.26.682.26.943 0l5.091-5.091c.26-.26.682-.26.943 0zm-4.276.033c.26-.26.682-.26.943 0s.26.682 0 .943h0L7.966 8.976l-.943-.943z",
  trash:
    "M6.666 6.673c.368 0 .667.298.667.667v3.333c0 .368-.298.667-.667.667S6 11.042 6 10.673V7.34c0-.368.298-.667.667-.667zm2.667 0c.368 0 .667.298.667.667v3.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667V7.34c0-.368.298-.667.667-.667zm0-5.333a2 2 0 0 1 2 2h0H14c.368 0 .667.298.667.667s-.298.667-.667.667h0-.667v8a2 2 0 0 1-2 2h0-6.667a2 2 0 0 1-2-2h0v-8H2c-.368 0-.667-.298-.667-.667S1.631 3.34 2 3.34h0 2.667a2 2 0 0 1 2-2h0zM12 4.673H4v8c0 .368.298.667.667.667h6.667c.368 0 .667-.298.667-.667v-8zm-2.667-2H6.666c-.368 0-.667.298-.667.667h4c0-.368-.298-.667-.667-.667z",
  message:
    "M4.666 6c0-.368.298-.667.667-.667h5.333c.368 0 .667.298.667.667s-.298.667-.667.667H5.333c-.368 0-.667-.298-.667-.667zm0 2.667c0-.368.298-.667.667-.667H8c.368 0 .667.298.667.667s-.298.667-.667.667H5.333c-.368 0-.667-.298-.667-.667zm8-6.667a2 2 0 0 1 2 2h0v6.667a2 2 0 0 1-2 2h0-7.333L3.52 14.178c-.868.724-2.187.106-2.187-1.024h0V4a2 2 0 0 1 2-2h0zm0 1.333H3.333c-.368 0-.667.298-.667.667v9.153l1.813-1.511c.24-.2.542-.309.854-.309h7.333c.368 0 .667-.298.667-.667V4c0-.368-.298-.667-.667-.667z",
  messages:
    "M12.593 1.526c.886-.591 2.073.044 2.073 1.109h0v6.175a2 2 0 0 1-2 2h0H12v.667a2 2 0 0 1-1.851 1.995l-.149.005H4.666l-1.167.934c-.839.672-2.067.123-2.161-.914l-.006-.127V7.478a2 2 0 0 1 1.851-1.995l.149-.005H4v-.667a2 2 0 0 1 2-2h0 4.667zM10 6.811H3.333c-.368 0-.667.298-.667.667v5.893l1.167-.934c.236-.189.53-.292.833-.292H10c.368 0 .667-.298.667-.667v-4c0-.368-.298-.667-.667-.667zm-1.333 2c.368 0 .667.298.667.667s-.298.667-.667.667h-4c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h4zm4.667-6.175L11.406 3.92c-.219.146-.476.224-.74.224h0H6c-.368 0-.667.298-.667.667h0v.667H10a2 2 0 0 1 1.995 1.851l.005.149v2h.667c.368 0 .667-.298.667-.667h0V2.636z",
  "more-horizontal":
    "M5.334 8.006c0 .736-.597 1.333-1.333 1.333s-1.333-.597-1.333-1.333S3.264 6.673 4 6.673s1.333.597 1.333 1.333zm4 0c0 .736-.597 1.333-1.333 1.333s-1.333-.597-1.333-1.333S7.264 6.673 8 6.673s1.333.597 1.333 1.333zm4 0c0 .736-.597 1.333-1.333 1.333s-1.333-.597-1.333-1.333.597-1.333 1.333-1.333 1.333.597 1.333 1.333z",
  notification:
    "M8.019 1.333c2.935 0 5.314 2.379 5.314 5.314v2.134c0 .354.14.693.391.943l.667.667c.177.177.276.417.276.667 0 .521-.422.943-.943.943h-3.057c0 1.473-1.194 2.667-2.667 2.667S5.333 13.472 5.333 12H2.269c-.517 0-.936-.419-.936-.936 0-.254.103-.497.286-.673l.686-.663c.261-.252.408-.6.407-.963l-.006-2.102c-.009-2.941 2.373-5.33 5.314-5.33zM9.333 12H6.666c0 .736.597 1.333 1.333 1.333S9.333 12.736 9.333 12zM8.019 2.666a3.98 3.98 0 0 0-3.98 3.992h0l.006 2.102c.002.716-.284 1.403-.793 1.906h0 9.529c-.5-.5-.781-1.178-.781-1.886h0V6.647a3.98 3.98 0 0 0-3.98-3.98z",
  "bar-chart":
    "M2 1.34c.368 0 .667.298.667.667v10.667c0 .368.298.667.667.667H14c.368 0 .667.298.667.667s-.298.667-.667.667H3.333a2 2 0 0 1-2-2V2.007c0-.368.298-.667.667-.667zm2.666 4c.368 0 .667.298.667.667v5.333c0 .368-.298.667-.667.667S4 11.708 4 11.34V6.007c0-.368.298-.667.667-.667zm2.667 4c.368 0 .667.298.667.667v1.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667v-1.333c0-.368.298-.667.667-.667zM10 2.673c.368 0 .667.298.667.667v8c0 .368-.298.667-.667.667s-.667-.298-.667-.667v-8c0-.368.298-.667.667-.667zm2.666 4c.368 0 .667.298.667.667v4c0 .368-.298.667-.667.667S12 11.708 12 11.34v-4c0-.368.298-.667.667-.667z",
  grid: "M12.666 1.34a2 2 0 0 1 2 2h0v9.333a2 2 0 0 1-2 2h0-9.333a2 2 0 0 1-2-2h0V3.34a2 2 0 0 1 2-2h0zm-3.333 9.333H6.666v2.667h2.667v-2.667zm4 0h-2.667v2.667h2c.368 0 .667-.299.667-.667h0v-2zm-8 0H2.666v2c0 .368.298.667.667.667h0 2v-2.667zm4-4H6.666V9.34h2.667V6.673zm4 0h-2.667V9.34h2.667V6.673zm-8 0H2.666V9.34h2.667V6.673zm4-4H6.666V5.34h2.667V2.673zm3.333 0h-2V5.34h2.667v-2c0-.368-.298-.667-.667-.667zm-7.333 0h-2c-.368 0-.667.298-.667.667h0v2h2.667V2.673z",
  leaderboard:
    "M6 4.673c0-.736.597-1.333 1.333-1.333h1.333c.736 0 1.333.597 1.333 1.333v7.333c0 .368-.298.667-.667.667H6.666a.67.67 0 0 1-.667-.667V4.673zm5.333 4c0-.368.298-.667.667-.667h1.333c.736 0 1.333.597 1.333 1.333v2.667c0 .368-.298.667-.667.667h-2c-.368 0-.667-.298-.667-.667V8.673zm-10-.666c0-.736.597-1.333 1.333-1.333H4c.368 0 .667.298.667.667v4.667c0 .368-.298.667-.667.667H2c-.368 0-.667-.298-.667-.667v-4z",
  ticket:
    "M12.666 2.673a2 2 0 0 1 2 2h0v1.333c0 .368-.316.653-.639.83a1.33 1.33 0 0 0 0 2.34c.323.177.639.461.639.83h0v1.333a2 2 0 0 1-2 2h0-9.333a2 2 0 0 1-2-2h0v-1.333c0-.368.316-.653.639-.83a1.33 1.33 0 0 0 0-2.34c-.323-.177-.639-.461-.639-.83h0V4.673a2 2 0 0 1 2-2h0zm0 1.333H3.333c-.368 0-.667.298-.667.667v1.024C3.461 6.156 4 7.017 4 8.006a2.67 2.67 0 0 1-1.333 2.31v1.024c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667v-1.024C12.539 9.857 12 8.995 12 8.006a2.67 2.67 0 0 1 1.333-2.31V4.673c0-.368-.298-.667-.667-.667zM7.371 5.818a.67.67 0 0 1 1.259 0h0l.158.456a.67.67 0 0 0 .616.448h0l.482.01a.67.67 0 0 1 .389 1.198h0l-.385.292c-.222.168-.316.457-.235.724h0l.14.462a.67.67 0 0 1-1.019.74h0l-.396-.276a.67.67 0 0 0-.762 0h0l-.396.276a.67.67 0 0 1-1.019-.74h0l.14-.462c.081-.267-.013-.556-.235-.724h0l-.385-.292a.67.67 0 0 1 .389-1.198h0l.482-.01c.279-.006.525-.184.616-.448h0zM8 7.466a2 2 0 0 1-.514.374c.097.19.164.394.196.605a2 2 0 0 1 .636 0c.033-.211.099-.415.196-.605-.19-.096-.364-.223-.514-.374z",
  "shopping-bag":
    "M8 1.333c1.473 0 2.667 1.194 2.667 2.667h0 .901a2 2 0 0 1 1.985 1.752h0l.833 6.667a2 2 0 0 1-1.985 2.248h0-8.802a2 2 0 0 1-1.985-2.248h0l.833-6.667A2 2 0 0 1 4.433 4h0 .901c0-1.473 1.194-2.667 2.667-2.667zm-2.667 4h-.901c-.336 0-.62.25-.662.584h0l-.833 6.667c-.05.398.261.749.662.749h0 8.802c.401 0 .711-.351.662-.749h0l-.833-6.667c-.042-.334-.325-.584-.662-.584h0-.901V6c0 .368-.298.667-.667.667S9.334 6.368 9.334 6h0v-.667H6.667V6c0 .368-.298.667-.667.667S5.334 6.368 5.334 6h0v-.667zM8 2.666c-.736 0-1.333.597-1.333 1.333h2.667c0-.736-.597-1.333-1.333-1.333z",
  info: "M8.001 1.334a6.67 6.67 0 0 1 6.667 6.667 6.67 6.67 0 0 1-6.667 6.667 6.67 6.67 0 0 1-6.667-6.667 6.67 6.67 0 0 1 6.667-6.667zm0 6c-.368 0-.667.298-.667.667h0v3.334c0 .368.298.667.667.667s.667-.298.667-.667h0V8.001c0-.368-.298-.667-.667-.667zm0-2.667c-.368 0-.667.298-.667.667s.298.667.667.667.667-.298.667-.667-.298-.667-.667-.667z",
  "info-stroke":
    "M8.003 1.334a6.67 6.67 0 0 1 6.667 6.667 6.67 6.67 0 0 1-6.667 6.667 6.67 6.67 0 0 1-6.667-6.667 6.67 6.67 0 0 1 6.667-6.667zm0 1.333c-2.945 0-5.333 2.388-5.333 5.333s2.388 5.333 5.333 5.333 5.333-2.388 5.333-5.333-2.388-5.333-5.333-5.333zm0 3.999c.368 0 .667.298.667.667v3.334c0 .368-.298.667-.667.667s-.667-.298-.667-.667V7.333c0-.368.298-.667.667-.667zm0-2c.368 0 .667.298.667.667S8.371 6 8.003 6s-.667-.298-.667-.667.298-.667.667-.667z",
  activity:
    "M6.326 3.369c-.075-.351-.577-.351-.652 0l-.796 3.716a2 2 0 0 1-1.956 1.581H2c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h.922c.314 0 .586-.22.652-.527l.796-3.716c.376-1.757 2.883-1.757 3.259 0l2.044 9.54c.075.351.577.351.652 0l.796-3.716a2 2 0 0 1 1.956-1.581H14c.368 0 .667.298.667.667s-.298.667-.667.667h-.922c-.314 0-.586.22-.652.527l-.796 3.716c-.376 1.757-2.883 1.757-3.259 0L6.326 3.369z",
  link: "M1.333 8c0-1.841 1.492-3.333 3.333-3.333h2c.368 0 .667.298.667.667S7.035 6 6.666 6h-2a2 2 0 1 0 0 4h2c.368 0 .667.298.667.667s-.298.667-.667.667h-2c-1.841 0-3.333-1.492-3.333-3.333zm7.334-2.666c0-.368.298-.667.667-.667h2c1.841 0 3.333 1.492 3.333 3.333s-1.492 3.333-3.333 3.333h-2c-.368 0-.667-.298-.667-.667S8.966 10 9.334 10h2a2 2 0 1 0 0-4h-2c-.368 0-.667-.298-.667-.667zM5.333 8c0-.368.298-.667.667-.667h4c.368 0 .667.298.667.667s-.298.667-.667.667H6c-.368 0-.667-.298-.667-.667z",
  heart:
    "M7.867 3.475L8 3.611l.133-.136c1.495-1.523 3.923-1.523 5.418 0a3.93 3.93 0 0 1 0 5.485l-4.123 4.202a2 2 0 0 1-2.855 0L2.449 8.961a3.93 3.93 0 0 1 0-5.485c1.495-1.523 3.923-1.523 5.418 0zM3.4 4.409c-.979.998-.979 2.62 0 3.618l4.123 4.202c.261.266.69.266.952 0l4.123-4.202c.979-.998.979-2.62 0-3.618a2.45 2.45 0 0 0-3.514 0l-.609.621c-.125.128-.297.2-.476.2s-.35-.072-.476-.2l-.609-.621a2.45 2.45 0 0 0-3.514 0z",
  "heart-fill":
    "M7.867 3.475L8 3.611l.133-.136c1.495-1.523 3.923-1.523 5.418 0a3.93 3.93 0 0 1 0 5.485l-4.123 4.202a2 2 0 0 1-2.855 0L2.449 8.961a3.93 3.93 0 0 1 0-5.485c1.495-1.523 3.923-1.523 5.418 0z",
  basket:
    "M9.414 2.472l2.862 2.862h1.622c.406 0 .717.359.66.761l-.979 6.855a2 2 0 0 1-1.98 1.717H4.401a2 2 0 0 1-1.98-1.717l-.979-6.855c-.057-.402.254-.761.66-.761h1.622l2.862-2.862a2 2 0 0 1 2.828 0zm3.715 4.195H2.87l.871 6.094c.047.328.328.572.66.572h7.197c.332 0 .613-.244.66-.572l.871-6.094zM8.471 3.415a.67.67 0 0 0-.943 0h0L5.609 5.334h4.781zM5.333 8c.368 0 .667.298.667.667v2.667c0 .368-.298.667-.667.667s-.667-.298-.667-.667V8.667c0-.368.298-.667.667-.667zM8 8c.368 0 .667.298.667.667v2.667c0 .368-.298.667-.667.667s-.667-.298-.667-.667V8.667A.67.67 0 0 1 8 8zm2.667 0c.368 0 .667.298.667.667v2.667c0 .368-.298.667-.667.667S10 11.702 10 11.334V8.667c0-.368.298-.667.667-.667z",
  "video-recorder":
    "M9.333 3.333a2 2 0 0 1 2 2v.381l1.225-.875c.883-.63 2.108 0 2.108 1.085v4.151c0 1.085-1.226 1.715-2.108 1.085l-1.225-.875v.381a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2V5.333a2 2 0 0 1 2-2h6zm0 1.333h-6c-.368 0-.667.298-.667.667h0v5.333c0 .368.298.667.667.667h0 6c.368 0 .667-.298.667-.667h0V5.333c0-.368-.298-.667-.667-.667h0zm4 1.258l-2 1.429v1.294l2 1.429V5.924z",
  phone:
    "M3.526 2.121a1.55 1.55 0 0 1 2.094.385h0l1.005 1.371a2 2 0 0 1 .176 2.077h0l-.273.546c-.079.158-.092.341-.011.498.147.286.461.794 1.066 1.399s1.112.918 1.399 1.066c.157.081.34.068.498-.011h0l.546-.273a2 2 0 0 1 2.077.176h0l1.371 1.005a1.55 1.55 0 0 1 .385 2.094c-1.096 1.694-3.104 2.565-4.871 1.592-1.153-.635-2.54-1.6-3.998-3.057S2.568 8.146 1.933 6.992C.96 5.225 1.832 3.217 3.526 2.121zM4.25 3.24c-1.315.851-1.676 2.151-1.149 3.109.577 1.048 1.467 2.333 2.832 3.698s2.65 2.255 3.698 2.832c.958.528 2.258.166 3.109-1.149.063-.097.039-.226-.054-.295l-1.371-1.005c-.202-.148-.469-.17-.692-.059l-.546.273a1.88 1.88 0 0 1-1.705.004c-.403-.207-1.024-.601-1.731-1.309S5.539 8.012 5.331 7.608a1.88 1.88 0 0 1 .004-1.705l.273-.546c.112-.224.089-.491-.059-.692L4.544 3.295c-.068-.093-.198-.117-.295-.054z",
  design:
    "M3.757 1.872a2 2 0 0 1 2.828 0h0l1.9 1.899 1.69-1.69a2 2 0 0 1 2.828 0h0l.943.943a2 2 0 0 1 0 2.828h0l-1.69 1.69 1.872 1.872a2 2 0 0 1 0 2.828h0l-1.886 1.886a2 2 0 0 1-2.828 0h0l-1.872-1.872-1.467 1.467c-.093.093-.212.156-.341.182h0l-3.536.707c-.466.093-.878-.318-.784-.784h0l.707-3.536c.026-.129.089-.248.182-.341h0l1.467-1.467-1.899-1.9a2 2 0 0 1 0-2.828h0zm7.556 6.614l-2.828 2.828.458.458.471-.471c.26-.26.682-.26.943 0s.26.682 0 .943l-.471.471.471.471c.26.26.682.26.943 0l1.886-1.886c.26-.26.26-.682 0-.943l-1.872-1.872zM9.233 4.909L3.39 10.752l-.471 2.357 2.357-.471 5.843-5.843-1.886-1.886zM4.7 2.815L2.815 4.7c-.26.26-.26.682 0 .943l.471.471.943-.943c.26-.26.682-.26.943 0s.26.682 0 .943l-.943.943.485.485 2.828-2.828-1.9-1.9c-.26-.26-.682-.26-.943 0zm6.418.209l-.943.943 1.886 1.886.943-.943c.26-.26.26-.682 0-.943l-.943-.943c-.26-.26-.682-.26-.943 0z",
  multiselect:
    "M12.666 1.333a2 2 0 0 1 1.995 1.851l.005.149V10a2 2 0 0 1-1.851 1.995l-.149.005H12v.667a2 2 0 0 1-2 2h0-6.667a2 2 0 0 1-2-2h0V6a2 2 0 0 1 2-2h0H4v-.667A2 2 0 0 1 5.85 1.338L6 1.333h6.667zM4 5.333h-.667c-.368 0-.667.298-.667.667h0v6.667c0 .368.298.667.667.667h0H10c.368 0 .667-.298.667-.667h0V12H6a2 2 0 0 1-1.995-1.851L4 10V5.333zm8.667-2.667H6c-.368 0-.667.298-.667.667V10c0 .368.298.667.667.667h6.667c.368 0 .667-.298.667-.667V3.333c0-.368-.298-.667-.667-.667zm-.862 2.195c.26.26.26.682 0 .943L9.138 8.471c-.26.26-.682.26-.943 0L6.862 7.138c-.26-.26-.26-.682 0-.943s.682-.26.943 0l.862.862 2.195-2.195c.26-.26.682-.26.943 0z",
  schedule:
    "M15 11a4 4 0 0 1-4 4 3.98 3.98 0 0 1-2.229-.678l.017.011H3a2 2 0 0 1-2-2h0v-8a2 2 0 0 1 2-2h0 .667v-.667c0-.368.298-.667.667-.667S5 1.298 5 1.667h0v.667h5.333v-.667c0-.368.298-.667.667-.667s.667.298.667.667h0v.667h.667a2 2 0 0 1 2 2h0v4.455h0l.022.034A3.98 3.98 0 0 1 15 11zm-4-2.667c-1.473 0-2.667 1.194-2.667 2.667S9.527 13.667 11 13.667s2.667-1.194 2.667-2.667S12.473 8.333 11 8.333zM3.667 3.667H3c-.368 0-.667.298-.667.667h0v8c0 .368.298.667.667.667h0 4.535C7.198 12.423 7 11.735 7 11a4 4 0 0 1 4-4 3.98 3.98 0 0 1 2.015.544L13 7.535V4.333c0-.368-.298-.667-.667-.667h0-.667v.667c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0v-.667H5v.667C5 4.702 4.702 5 4.333 5s-.667-.298-.667-.667h0v-.667zM11 9c.368 0 .667.298.667.667v1.057l.471.471c.26.26.26.682 0 .943s-.682.26-.943 0l-.667-.667c-.125-.125-.195-.295-.195-.471V9.667c0-.368.298-.667.667-.667z",
  facebook:
    "M8.5 4.667c0-.736.597-1.333 1.333-1.333h.667c.368 0 .667-.298.667-.667S10.868 2 10.5 2h-.667C8.36 2 7.166 3.194 7.166 4.667V6H5.5c-.368 0-.667.298-.667.667s.298.667.667.667h1.667v6c0 .368.298.667.667.667s.667-.298.667-.667v-6h1.667c.368 0 .667-.298.667-.667S10.535 6 10.166 6H8.5V4.667z",
  "facebook-fill":
    "M6.023 16L6 9H3V6h3V4c0-2.699 1.672-4 4.079-4 1.153 0 2.145.086 2.434.124v2.821l-1.67.001c-1.31 0-1.563.622-1.563 1.535V6H13l-1 3H9.28v7H6.023z",
  twitter:
    "M10.266 2c1.133 0 1.867.733 2.267 1.2l1.533-.533c.267-.067.6 0 .733.2.2.2.2.533 0 .8l-1.2 1.8c.133.8.267 3.2-1.533 5.6-1.467 2.133-3.8 3-6.067 3-1.6 0-3.267-.4-4.6-1.133-.267-.133-.4-.4-.333-.733.067-.267.267-.533.6-.533 1.133-.133 2-.333 2.8-.733-1.933-1.467-2.933-4.133-2.8-7.6 0-.267.2-.533.4-.6.267-.133.533-.067.733.133C4.133 4.2 5.733 5.6 7.266 5.467c0-.667.067-1.733.667-2.467.533-.667 1.333-1 2.333-1zm.067 1.333c-.6 0-1.067.133-1.333.533-.467.6-.4 1.667-.333 2.067.067.333-.133.667-.467.733-1.933.667-3.867-.6-5.2-1.733.2 2.133.867 4.4 3 5.467.2.133.333.333.333.533a.7.7 0 0 1-.333.6c-.6.4-1.267.733-1.933.933 2.533.6 5.333.133 7.067-2.2 1.8-2.333 1.267-4.667 1.2-4.8-.067-.2 0-.4.067-.533l.267-.4-.133.067c-.333.133-.667-.067-.8-.333l-.025-.04c-.114-.179-.617-.893-1.375-.893z",
  "twitter-fill":
    "M13.404 5.756l-.05-.353 1.256-1.884c.079-.118.074-.274-.012-.387s-.235-.159-.37-.114l-1.747.582a3.37 3.37 0 0 0-.412-.502c-.37-.37-.949-.764-1.736-.764-.815 0-1.418.228-1.849.603s-.651.856-.766 1.317-.125.912-.108 1.243l.02.26c-.837.178-1.712-.095-2.57-.604-.946-.561-1.813-1.377-2.491-2.055-.094-.094-.235-.123-.358-.074s-.206.167-.21.3c-.085 2.718.475 6.008 3.007 7.676-1.065.576-2.109.845-3.382 1.004-.146.018-.262.13-.287.274s.048.289.179.354c3.439 1.72 7.896 1.595 10.416-1.765 1.045-1.393 1.395-2.789 1.482-3.839.044-.524.022-.962-.011-1.272z",
  instagram:
    "M10.663 1.333a4 4 0 0 1 3.995 3.8l.005.2v5.333a4 4 0 0 1-3.8 3.995l-.2.005H5.33a4 4 0 0 1-3.995-3.8l-.005-.2V5.333a4 4 0 0 1 3.8-3.995l.2-.005h5.333zm0 1.333H5.33c-1.473 0-2.667 1.194-2.667 2.667v5.333c0 1.473 1.194 2.667 2.667 2.667h5.333c1.473 0 2.667-1.194 2.667-2.667V5.333c0-1.473-1.194-2.667-2.667-2.667zm-2.667 2c1.841 0 3.333 1.492 3.333 3.333s-1.492 3.333-3.333 3.333S4.663 9.841 4.663 8s1.492-3.333 3.333-3.333zm0 1.333a2 2 0 1 0 0 4 2 2 0 1 0 0-4zm3.333-2c.368 0 .667.298.667.667s-.298.667-.667.667-.667-.298-.667-.667S10.962 4 11.33 4z",
  pinterest:
    "M8.213 1.36c1.774-.082 3.6.551 4.647 1.897 2.665 3.427.088 8.076-3.526 8.076-.858 0-1.532-.188-2.045-.527a2.5 2.5 0 0 1-.5-.434l-1.177 3.824c-.108.352-.481.549-.833.441s-.549-.481-.441-.833l2.667-8.667c.108-.352.481-.549.833-.441s.549.481.441.833l-.807 2.624-.005.039c-.006.053-.013.133-.014.23-.002.199.022.445.108.678.084.225.223.434.464.593s.645.306 1.31.306c2.477 0 4.475-3.351 2.474-5.924-.713-.917-2.067-1.451-3.533-1.384-1.449.067-2.884.719-3.745 2.011-.56.84-.611 1.642-.447 2.248.175.647.565 1.003.807 1.08.351.112.544.487.433.838s-.487.544-.838.433c-.803-.256-1.436-1.067-1.689-2.003-.264-.977-.149-2.175.625-3.335C4.559 2.255 6.423 1.442 8.213 1.36z",
  mail: "M12.666 2.667a2 2 0 0 1 2 2v6.667a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V4.667a2 2 0 0 1 2-2h9.333zm.667 3.246L9.109 8.729a2 2 0 0 1-2.219 0L2.666 5.913v5.421c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V5.913zM12.666 4H3.333c-.257 0-.481.146-.592.36h0L7.63 7.619c.224.149.516.149.74 0h0l4.889-3.259c-.111-.214-.334-.36-.592-.36h0z",
  payment:
    "M12.666 2.667a2 2 0 0 1 2 2v6.667a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2V4.667a2 2 0 0 1 2-2h9.333zm.667 4H2.666v4.667c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V6.667zM12.666 4H3.333c-.368 0-.667.298-.667.667h0v.667h10.667v-.667c0-.368-.298-.667-.667-.667h0zM4 10c0-.368.298-.667.667-.667h4c.368 0 .667.298.667.667s-.298.667-.667.667h-4C4.298 10.667 4 10.369 4 10z",
  edit: "M12.518 2.545l.943.943a2 2 0 0 1 0 2.828l-6.928 6.928c-.093.093-.212.157-.341.182l-3.536.707c-.466.093-.878-.318-.784-.784l.707-3.536c.026-.129.089-.248.182-.341L9.69 2.545a2 2 0 0 1 2.828 0zM8.747 5.373l-4.9 4.9-.471 2.357 2.357-.471 4.9-4.9-1.886-1.886zm2.828-1.886c-.26-.26-.682-.26-.943 0h0l-.943.943 1.886 1.886.943-.943c.26-.26.26-.682 0-.943h0z",
  upload:
    "M13.333 9.167c.368 0 .667.298.667.667v1.333a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V9.833c0-.368.298-.667.667-.667s.667.298.667.667v1.333c0 .368.298.667.667.667h8c.368 0 .667-.298.667-.667V9.833c0-.368.298-.667.667-.667zM8.001 2.833c.177 0 .346.07.471.195l2.999 2.999c.26.26.26.682 0 .943s-.682.26-.943 0L8.668 5.109v4.724c0 .368-.298.667-.667.667s-.667-.298-.667-.667V5.109L5.473 6.971c-.26.26-.682.26-.943 0s-.26-.682 0-.943l3-3c.125-.125.295-.195.471-.195z",
  expand:
    "M1.333 3.333a2 2 0 0 1 2-2h1.333c.368 0 .667.298.667.667s-.298.667-.667.667H3.333c-.368 0-.667.298-.667.667v1.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667V3.333zm0 9.333a2 2 0 0 0 2 2h1.333c.368 0 .667-.298.667-.667s-.298-.667-.667-.667H3.333c-.368 0-.667-.298-.667-.667v-1.333c0-.368-.298-.667-.667-.667s-.667.298-.667.667v1.333zm13.333 0a2 2 0 0 1-2 2h-1.333c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h1.333c.368 0 .667-.298.667-.667v-1.333c0-.368.298-.667.667-.667s.667.298.667.667v1.333zm0-9.333a2 2 0 0 0-2-2h-1.333c-.368 0-.667.298-.667.667s.298.667.667.667h1.333c.368 0 .667.298.667.667v1.333c0 .368.298.667.667.667s.667-.298.667-.667V3.333z",
  calendar:
    "M11.333 1.333c.368 0 .667.298.667.667v.667h.667a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H3.333a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2H4V2c0-.368.298-.667.667-.667s.667.298.667.667v.667h5.333V2c0-.368.298-.667.667-.667zm2 6.667H2.666v4.667c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V8zM4 4h-.667c-.368 0-.667.298-.667.667h0v2h10.667v-2c0-.368-.298-.667-.667-.667h0H12v.667c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0V4H5.333v.667c0 .368-.298.667-.667.667S4 5.035 4 4.666h0V4z",
  list: "M13.333 10.667c.368 0 .667.298.667.667s-.298.667-.667.667h-8c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h8zm-10.667 0c.368 0 .667.298.667.667S3.035 12 2.667 12 2 11.702 2 11.333s.298-.667.667-.667zm10.667-3.333c.368 0 .667.298.667.667s-.298.667-.667.667h-8c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h8zm-10.667 0c.368 0 .667.298.667.667s-.298.667-.667.667S2 8.368 2 8s.298-.667.667-.667zM13.333 4c.368 0 .667.298.667.667s-.298.667-.667.667h-8c-.368 0-.667-.298-.667-.667S4.965 4 5.333 4h8zM2.667 4c.368 0 .667.298.667.667s-.298.667-.667.667S2 5.035 2 4.667 2.298 4 2.667 4z",
  clock:
    "M8 1.333A6.67 6.67 0 0 1 14.666 8 6.67 6.67 0 0 1 8 14.666 6.67 6.67 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 1.333C5.054 2.666 2.666 5.054 2.666 8S5.054 13.333 8 13.333 13.333 10.945 13.333 8 10.945 2.666 8 2.666zM8 4c.368 0 .667.298.667.667v3.057l1.471 1.471c.26.26.26.682 0 .943s-.682.26-.943 0L7.724 8.666c-.25-.25-.391-.589-.391-.943V4.666C7.333 4.298 7.631 4 8 4z",
  "star-stroke":
    "M6.741 2.563c.415-1.194 2.104-1.194 2.519 0h0l.63 1.813c.183.527.675.884 1.232.895h0l1.919.039c1.264.026 1.786 1.632.778 2.396h0l-1.53 1.16c-.444.337-.632.915-.471 1.449h0l.556 1.838c.366 1.21-1 2.203-2.038 1.481h0l-1.576-1.097c-.458-.319-1.065-.319-1.523 0h0l-1.576 1.097c-1.038.722-2.404-.271-2.038-1.481h0l.556-1.838c.161-.534-.026-1.112-.471-1.449h0l-1.53-1.16c-1.007-.764-.486-2.37.778-2.396h0l1.919-.039c.558-.011 1.049-.369 1.232-.895h0zM8 3l-.63 1.813C7.003 5.867 6.02 6.582 4.905 6.604l-1.919.039 1.53 1.16c.889.674 1.264 1.83.941 2.897l-.556 1.838 1.576-1.097c.916-.637 2.131-.637 3.046 0l1.576 1.097-.556-1.838c-.323-1.068.053-2.223.941-2.897l1.53-1.16-1.919-.039C9.98 6.582 8.997 5.867 8.63 4.814L8 3z",
  "star-fill":
    "M6.758 2.563c.415-1.194 2.104-1.194 2.519 0l.63 1.813c.183.527.675.884 1.232.895l1.919.039c1.264.026 1.786 1.632.778 2.396l-1.53 1.16c-.444.337-.632.915-.471 1.449l.556 1.838c.366 1.21-1 2.203-2.038 1.481l-1.576-1.097c-.458-.319-1.065-.319-1.523 0L5.68 13.633c-1.038.722-2.404-.271-2.038-1.481l.556-1.838c.161-.534-.026-1.112-.471-1.449l-1.53-1.16c-1.007-.764-.486-2.37.778-2.396l1.919-.039c.558-.011 1.049-.369 1.232-.895l.63-1.813z",
  smile:
    "M8 1.333A6.67 6.67 0 0 1 14.666 8 6.67 6.67 0 0 1 8 14.666 6.67 6.67 0 0 1 1.333 8 6.67 6.67 0 0 1 8 1.333zm0 1.333C5.054 2.666 2.666 5.054 2.666 8S5.054 13.333 8 13.333 13.333 10.945 13.333 8 10.945 2.666 8 2.666zm3.323 6.056c.337.147.491.54.344.878a4 4 0 0 1-7.334 0c-.147-.337.007-.73.344-.878s.73.007.878.344c.412.943 1.353 1.6 2.445 1.6s2.033-.657 2.445-1.6c.147-.337.54-.491.878-.344zm-5.99-3.389c.368 0 .667.298.667.667s-.298.667-.667.667-.667-.298-.667-.667.298-.667.667-.667zm5.333 0c.368 0 .667.298.667.667s-.298.667-.667.667S10 6.368 10 6s.298-.667.667-.667z",
  repeat:
    "M10.805 1.862a.67.67 0 0 0-.943 0c-.261.26-.26.682 0 .943l.526.526H6.667A4.67 4.67 0 0 0 2 7.998v.667c0 .368.298.667.667.667s.667-.299.667-.667v-.667c0-1.841 1.492-3.333 3.333-3.333h3.724l-.529.529a.67.67 0 0 0 0 .943c.26.261.682.26.943 0l1.43-1.43a1 1 0 0 0 0-1.414l-1.43-1.43zM9.332 12.667A4.67 4.67 0 0 0 13.999 8v-.667c0-.368-.298-.667-.667-.667s-.667.298-.667.667V8c0 1.841-1.492 3.333-3.333 3.333H5.608l.529-.529a.67.67 0 0 0 0-.943c-.26-.261-.682-.26-.943 0l-1.43 1.43a1 1 0 0 0 0 1.414l1.43 1.43a.67.67 0 0 0 .943 0c.261-.26.26-.682 0-.943l-.526-.526h3.721z",
  mobile:
    "M10.666 1.333a2 2 0 0 1 2 2v9.333a2 2 0 0 1-2 2H5.333a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h5.333zM6 2.666h-.667c-.368 0-.667.298-.667.667v9.333c0 .368.298.667.667.667h5.333c.368 0 .667-.298.667-.667V3.333c0-.368-.298-.667-.667-.667H10a1 1 0 0 1-1 1H7a1 1 0 0 1-1-1z",
  tablet:
    "M12 1.333a2 2 0 0 1 1.995 1.851l.005.149v9.333a2 2 0 0 1-1.851 1.995l-.149.005H4a2 2 0 0 1-1.995-1.851L2 12.666V3.333a2 2 0 0 1 1.851-1.995L4 1.333h8zm0 1.333H4c-.368 0-.667.298-.667.667v9.333c0 .368.298.667.667.667h8c.368 0 .667-.298.667-.667V3.333c0-.368-.298-.667-.667-.667zm-4 8.667c.368 0 .667.298.667.667s-.298.667-.667.667-.667-.298-.667-.667.298-.667.667-.667z",
  desktop:
    "M14.667 12.667c.368 0 .667.298.667.667s-.298.667-.667.667H1.334c-.368 0-.667-.298-.667-.667s.298-.667.667-.667h13.333zM12.667 2a2 2 0 0 1 1.995 1.851l.005.149v6a2 2 0 0 1-1.851 1.995l-.149.005H3.334a2 2 0 0 1-1.995-1.851L1.334 10V4a2 2 0 0 1 1.851-1.995L3.334 2h9.333zm0 1.333H3.334c-.368 0-.667.298-.667.667v6c0 .368.298.667.667.667h9.333c.368 0 .667-.298.667-.667V4c0-.368-.298-.667-.667-.667z",
  filter:
    // "M13.333 1.467c.736 0 1.333.597 1.333 1.333v1.028a2 2 0 0 1-.764 1.573L10 8.467v4.454c0 .283-.179.535-.445.629l-2.667.938c-.434.153-.888-.169-.888-.629V8.467L2.097 5.401a2 2 0 0 1-.764-1.573V2.8c0-.736.597-1.333 1.333-1.333h10.667zm0 1.2H2.666v1.028c0 .205.094.398.255.524l3.902 3.066c.322.253.51.639.51 1.048v4.451l1.333-.469V8.334c0-.409.188-.796.51-1.048l3.902-3.066c.161-.126.255-.32.255-.524V2.667z"
    "M6.8238 7.28489C7.14552 7.53767 7.33337 7.92417 7.33337 8.33331V12.7841L8.66671 12.3149V8.33331C8.66671 7.92417 8.85456 7.53767 9.17628 7.28489L13.0786 4.21879C13.2394 4.0924 13.3334 3.89915 13.3334 3.69458V2.66665H2.66671V3.69458C2.66671 3.89915 2.76063 4.0924 2.92149 4.21879L6.8238 7.28489ZM13.9024 5.26721L10 8.33331V12.7871C10 13.07 9.8215 13.3221 9.55465 13.416L6.88798 14.3542C6.45434 14.5068 6.00004 14.185 6.00004 13.7253V8.33331L2.09773 5.26721C1.61515 4.88804 1.33337 4.3083 1.33337 3.69458V2.66665C1.33337 1.93027 1.93033 1.33331 2.66671 1.33331H13.3334C14.0698 1.33331 14.6667 1.93027 14.6667 2.66665V3.69458C14.6667 4.3083 14.3849 4.88804 13.9024 5.26721Z",
  mouse:
    "M7.834 1.333a4.67 4.67 0 0 1 4.662 4.453L12.5 6v4a4.67 4.67 0 0 1-4.667 4.667 4.67 4.67 0 0 1-4.662-4.453L3.167 10V6a4.67 4.67 0 0 1 4.667-4.667zm0 1.333C5.993 2.666 4.5 4.159 4.5 6v4c0 1.841 1.492 3.333 3.333 3.333s3.333-1.492 3.333-3.333V6c0-1.841-1.492-3.333-3.333-3.333zm0 1.333c.368 0 .667.298.667.667v2c0 .368-.298.667-.667.667s-.667-.298-.667-.667v-2c0-.368.298-.667.667-.667z",
  image:
    "M12.667 1.333a2 2 0 0 1 2 2v9.333a2 2 0 0 1-2 2H3.334a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h9.333zM4.036 6.424L2.822 7.881c-.1.12-.155.271-.155.427v4.359c0 .368.298.667.667.667h9.333l.055-.002c.322-.027.316-.434.098-.672L7.047 6.353a2 2 0 0 0-3.011.071zm7.298-3.091c-.736 0-1.333.597-1.333 1.333S10.598 6 11.334 6s1.333-.597 1.333-1.333-.597-1.333-1.333-1.333z",
  "image-stroke":
    "M12.667 1.333a2 2 0 0 1 2 2v9.333a2 2 0 0 1-2 2H3.334a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h9.333zM5.06 7.278l-2.393 2.871v2.517c0 .368.298.667.667.667h8.302L6.064 7.254c-.273-.298-.745-.286-1.004.024zm7.607-4.611H3.334c-.335 0-.612.247-.659.568l-.007.099v4.733l1.369-1.642a2 2 0 0 1 2.896-.187l.115.116 6.085 6.638.066.08c.064-.084.109-.184.127-.293l.009-.111V3.333c0-.368-.298-.667-.667-.667zm-2 1.333c.736 0 1.333.597 1.333 1.333s-.597 1.333-1.333 1.333-1.333-.597-1.333-1.333S9.931 4 10.667 4z",
  video:
    "M3.334 3.333a2 2 0 0 0-2 2v5.333a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2v-.381l1.225.875c.882.63 2.108 0 2.108-1.085V5.924c0-1.085-1.226-1.715-2.108-1.085l-1.225.875v-.381a2 2 0 0 0-2-2h-6z",
  "video-stroke":
    "M9.334 3.333a2 2 0 0 1 2 2v.381l1.225-.875c.882-.63 2.108 0 2.108 1.085v4.152c0 1.084-1.226 1.715-2.108 1.085l-1.225-.875v.381a2 2 0 0 1-2 2h-6a2 2 0 0 1-2-2V5.333a2 2 0 0 1 2-2h6zm0 1.333h-6c-.368 0-.667.298-.667.667h0v5.333c0 .368.298.667.667.667h0 6c.368 0 .667-.298.667-.667h0V5.333c0-.368-.298-.667-.667-.667h0zm4 1.258l-2 1.429v1.294l2 1.429V5.924z",
  setting:
    "M8.414.673c.574 0 1.083.367 1.265.912l.258.775c.122.364.399.651.735.838l.155.09c.33.198.717.295 1.094.218l.801-.164c.562-.115 1.135.143 1.422.64l.414.717c.287.497.224 1.122-.157 1.551l-.543.613c-.255.287-.365.67-.359 1.054v.181c-.006.384.104.767.359 1.054l.543.613c.381.429.444 1.054.157 1.551l-.414.717c-.287.497-.86.755-1.422.64l-.801-.164c-.377-.077-.764.02-1.094.218l-.155.09c-.336.187-.613.473-.735.838l-.258.775c-.181.544-.691.912-1.265.912h-.828c-.574 0-1.083-.367-1.265-.912l-.258-.775c-.121-.364-.399-.651-.734-.838l-.155-.09c-.33-.198-.717-.295-1.094-.218l-.801.164c-.562.115-1.135-.143-1.422-.64l-.414-.717c-.287-.497-.224-1.122.157-1.551l.543-.613c.255-.287.365-.67.359-1.054v-.181a1.59 1.59 0 0 0-.258-.925l-.1-.129-.543-.613c-.349-.394-.431-.951-.222-1.425l.064-.127.414-.717c.265-.459.773-.714 1.292-.66l.13.02.801.164c.377.077.764-.02 1.094-.218l.155-.09c.336-.187.613-.473.734-.838l.258-.775c.181-.544.691-.912 1.265-.912h.828zm0 1.333h-.828l-.258.775c-.25.75-.795 1.272-1.351 1.581l-.117.068c-.547.328-1.272.54-2.047.381l-.801-.164-.414.717.543.613c.476.536.668 1.19.692 1.783l.002.176v.139c.009.58-.14 1.237-.56 1.795l-.134.164-.543.613.414.717.801-.164c.775-.159 1.501.053 2.047.381l.117.068c.556.31 1.101.831 1.351 1.581l.258.775h.828l.258-.775c.25-.75.795-1.272 1.351-1.581l.117-.068c.547-.328 1.272-.54 2.047-.381l.801.164.414-.717-.543-.613c-.523-.59-.704-1.322-.694-1.959v-.139c-.01-.638.171-1.37.694-1.96l.543-.613-.414-.717-.801.164c-.775.159-1.501-.053-2.047-.381l-.117-.068c-.556-.309-1.101-.831-1.351-1.581l-.258-.775zM8 5.34c1.473 0 2.667 1.194 2.667 2.667S9.473 10.673 8 10.673 5.334 9.479 5.334 8.006 6.528 5.34 8 5.34zm0 1.333c-.736 0-1.333.597-1.333 1.333S7.264 9.34 8 9.34s1.333-.597 1.333-1.333S8.737 6.673 8 6.673z",
  "file-add":
    "M10.667 1.333l.007.007a2 2 0 0 1 1.245.579l1.495 1.495a2 2 0 0 1 .579 1.245l.007.007v8a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V3.333a2 2 0 0 1 2-2h6.667zM10 2.666H4c-.335 0-.612.247-.659.568l-.007.099v9.333c0 .335.247.612.568.659l.099.007h8c.335 0 .612-.247.659-.568l.007-.099V5.333h-1.333c-.693 0-1.263-.529-1.327-1.205L10 4V2.666zM8 6c.368 0 .667.298.667.667h0V8H10c.368 0 .667.298.667.667s-.298.667-.667.667h0-1.333v1.333c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0V9.333H6c-.368 0-.667-.298-.667-.667S5.632 8 6 8h0 1.333V6.666C7.333 6.298 7.632 6 8 6z",
  copy: "M12 4.667a2 2 0 0 1 1.995 1.851l.005.149V12a2 2 0 0 1-1.851 1.995L12 14H6.667a2 2 0 0 1-1.995-1.851L4.667 12v-.667H4a2 2 0 0 1-2-2h0V4a2 2 0 0 1 2-2h0 5.333a2 2 0 0 1 2 2h0v.667H12zM12 6H6.667C6.298 6 6 6.298 6 6.667V12c0 .368.298.667.667.667H12c.368 0 .667-.298.667-.667V6.667c0-.368-.298-.667-.667-.667zm-2-2c0-.368-.298-.667-.667-.667h0H4c-.368 0-.667.298-.667.667h0v5.333c0 .368.298.667.667.667h0 .667V6.667a2 2 0 0 1 1.851-1.995l.149-.005H10z",
  download:
    "M13.333 9.333c.368 0 .667.298.667.667v1.333a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V10c0-.368.298-.667.667-.667s.667.298.667.667v1.333c0 .368.298.667.667.667h8c.368 0 .667-.298.667-.667V10c0-.368.298-.667.667-.667zM8.001 2.666c.368 0 .667.298.667.667h0v5.391l1.861-1.861c.26-.26.682-.26.943 0s.26.682 0 .943h0l-2.527 2.527c-.521.521-1.365.521-1.886 0h0L4.53 7.804c-.26-.26-.26-.682 0-.943s.682-.26.943 0h0l1.862 1.862V3.333c0-.368.299-.667.667-.667z",
  share:
    "M4.667 10.666C3.194 10.666 2 9.472 2 8s1.194-2.667 2.667-2.667a2.66 2.66 0 0 1 1.886.781l-.015-.015h0l2.238-1.343.008.028c-.076-.248-.117-.511-.117-.784 0-1.473 1.194-2.667 2.667-2.667S14 2.527 14 4s-1.194 2.667-2.667 2.667c-.68 0-1.3-.254-1.771-.673l-.1-.094h0L7.224 7.242l-.015-.05c.081.255.124.526.124.807 0 .273-.041.536-.117.784l.008-.028 2.238 1.343h0l-.015.015a2.66 2.66 0 0 1 1.886-.781C12.806 9.333 14 10.527 14 12s-1.194 2.667-2.667 2.667S8.667 13.472 8.667 12c0-.281.044-.553.124-.807l-.015.05-2.238-1.343h0l-.1.094c-.471.419-1.091.673-1.771.673zm6.667 0c-.736 0-1.333.597-1.333 1.333s.597 1.333 1.333 1.333 1.333-.597 1.333-1.333-.597-1.333-1.333-1.333zm-6.667-4c-.736 0-1.333.597-1.333 1.333s.597 1.333 1.333 1.333S6 8.736 6 8s-.597-1.333-1.333-1.333zm6.667-4C10.597 2.666 10 3.263 10 4s.597 1.333 1.333 1.333S12.667 4.736 12.667 4s-.597-1.333-1.333-1.333z",
  lock: "M8 1.333c1.841 0 3.333 1.492 3.333 3.333v.667a2 2 0 0 1 2 2v5.333a2 2 0 0 1-2 2H4.667a2 2 0 0 1-2-2V7.333a2 2 0 0 1 2-2v-.667c0-1.841 1.492-3.333 3.333-3.333zm3.333 5.333H4.667c-.335 0-.612.247-.659.568L4 7.333v5.333c0 .335.247.612.568.659l.099.007h6.667c.335 0 .612-.247.659-.568l.007-.099V7.333c0-.368-.298-.667-.667-.667zM8 8c.736 0 1.333.597 1.333 1.333 0 .494-.268.924-.667 1.155h0v.845c0 .368-.298.667-.667.667s-.667-.298-.667-.667h0v-.845c-.399-.231-.667-.661-.667-1.155C6.667 8.597 7.264 8 8 8zm0-5.333a2 2 0 0 0-1.995 1.851L6 4.666v.667h4v-.667a2 2 0 0 0-2-2z",
  "donut-chart":
    "M7.333 3.334v2.661c0 .368-.306.656-.654.776-.161.056-.316.132-.46.228-.33.22-.587.534-.739.9s-.191.77-.114 1.159.268.746.549 1.027.638.471 1.027.549.792.038 1.159-.114.68-.409.9-.739c.096-.144.172-.298.228-.46.12-.348.408-.654.776-.654h2.661c.368 0 .67.299.63.665a6 6 0 0 1-3.667 4.878A6 6 0 0 1 3.09 12.91 6 6 0 0 1 1.79 6.371a6 6 0 0 1 4.878-3.667c.366-.041.665.261.665.63zM6 4.195c-.445.133-.869.331-1.259.592-.767.513-1.366 1.242-1.719 2.094s-.446 1.791-.266 2.696.625 1.737 1.277 2.389 1.484 1.097 2.389 1.277 1.844.088 2.696-.266 1.582-.951 2.094-1.719c.261-.39.459-.815.592-1.259h-1.411a3.34 3.34 0 0 1-1.783 1.751 3.34 3.34 0 0 1-3.638-.724 3.34 3.34 0 0 1-.724-3.638A3.34 3.34 0 0 1 6 5.606V4.195zm3.332-2.824a6 6 0 0 1 3.577 1.72 6 6 0 0 1 1.72 3.577c.041.366-.261.665-.63.665h-2.661c-.368 0-.656-.306-.776-.654l-.043-.113c-.101-.243-.248-.464-.435-.651s-.407-.334-.651-.435l-.113-.043c-.348-.12-.654-.408-.654-.776V2c0-.368.299-.67.665-.63zM10 2.862v1.411A3.34 3.34 0 0 1 11.727 6h1.411c-.046-.153-.099-.304-.161-.453-.235-.566-.578-1.081-1.012-1.514s-.948-.777-1.514-1.012A4.67 4.67 0 0 0 10 2.862z",
  order:
    "M4.98 4a.5.5 0 0 0-.39.188L1.54 8H6a.5.5 0 0 1 .5.5 1.5 1.5 0 1 0 3 0A.5.5 0 0 1 10 8h4.46l-3.05-3.812A.5.5 0 0 0 11.02 4H4.98zm-1.17-.437A1.5 1.5 0 0 1 4.98 3h6.04a1.5 1.5 0 0 1 1.17.563l3.7 4.625a.5.5 0 0 1 .106.374l-.39 3.124A1.5 1.5 0 0 1 14.117 13H1.883a1.5 1.5 0 0 1-1.489-1.314l-.39-3.124a.5.5 0 0 1 .106-.374l3.7-4.625z",
  export:
    "M8.66795 3.33305C8.66795 2.96486 8.36947 2.66638 8.00128 2.66638C7.63309 2.66638 7.33462 2.96486 7.33462 3.33305V8.72387L5.47267 6.86192C5.21232 6.60157 4.79021 6.60157 4.52986 6.86192C4.26951 7.12227 4.26951 7.54438 4.52986 7.80473L7.05848 10.3334C7.57918 10.8541 8.4234 10.8541 8.9441 10.3334L11.4714 7.80603C11.7318 7.54568 11.7318 7.12357 11.4714 6.86322C11.2111 6.60287 10.789 6.60287 10.5286 6.86322L8.66795 8.72389V3.33305Z" +
    "M2.66667 9.33331C3.03486 9.33331 3.33333 9.63179 3.33333 9.99998V11.3333C3.33333 11.7015 3.63181 12 4 12H12C12.3682 12 12.6667 11.7015 12.6667 11.3333V9.99998C12.6667 9.63179 12.9651 9.33331 13.3333 9.33331C13.7015 9.33331 14 9.63179 14 9.99998V11.3333C14 12.4379 13.1046 13.3333 12 13.3333H4C2.89543 13.3333 2 12.4379 2 11.3333V9.99998C2 9.63179 2.29848 9.33331 2.66667 9.33331Z",
  sortdownarrow:
    "M7.99947 14.3332L3.55078 9.8845L4.24695 9.1883L7.49947 12.4306V1.66016H8.49947V12.4242L11.7519 9.17163L12.4481 9.8845L7.99947 14.3332Z",
  sortuparrow:
    "M7.99947 1.66406L3.55078 6.11273L4.24695 6.80892L7.49947 3.56656V14.3371H8.49947V3.57306L11.7519 6.82559L12.4481 6.11273L7.99947 1.66406Z",
  customizingorder:
    "M3 4.7C3 4.3134 3.31603 4 3.70588 4H14.2941C14.684 4 15 4.3134 15 4.7C15 5.0866 14.684 5.4 14.2941 5.4H3.70588C3.31603 5.4 3 5.0866 3 4.7Z" +
    "M4.33337 8.99998C4.33337 8.63179 4.66916 8.33331 5.08337 8.33331H12.5834C12.9976 8.33331 13.3334 8.63179 13.3334 8.99998C13.3334 9.36817 12.9976 9.66665 12.5834 9.66665H5.08337C4.66916 9.66665 4.33337 9.36817 4.33337 8.99998Z" +
    "M6.33337 13C6.33337 12.6318 6.6132 12.3333 6.95837 12.3333H10.7084C11.0536 12.3333 11.3334 12.6318 11.3334 13C11.3334 13.3682 11.0536 13.6666 10.7084 13.6666H6.95837C6.6132 13.6666 6.33337 13.3682 6.33337 13Z",
  paginationleftarrow:
    "M1.66602 8.00053L6.11468 12.4492L6.81088 11.7531L3.56852 8.50053L14.339 8.50053L14.339 7.50053L3.57502 7.50053L6.82754 4.24807L6.11468 3.55187L1.66602 8.00053Z",
  camera:
    "M1.33337 3.33331C1.33337 2.22874 2.2288 1.33331 3.33337 1.33331H4.66671C5.0349 1.33331 5.33337 1.63179 5.33337 1.99998C5.33337 2.36817 5.0349 2.66665 4.66671 2.66665H3.33337C2.96518 2.66665 2.66671 2.96512 2.66671 3.33331V4.66665C2.66671 5.03484 2.36823 5.33331 2.00004 5.33331C1.63185 5.33331 1.33337 5.03484 1.33337 4.66665V3.33331Z" +
    "M1.33337 12.6667C1.33337 13.7713 2.2288 14.6667 3.33337 14.6667H4.66671C5.0349 14.6667 5.33337 14.3682 5.33337 14C5.33337 13.6318 5.0349 13.3334 4.66671 13.3334H3.33337C2.96518 13.3334 2.66671 13.0349 2.66671 12.6667V11.3334C2.66671 10.9652 2.36823 10.6667 2.00004 10.6667C1.63185 10.6667 1.33337 10.9652 1.33337 11.3334V12.6667Z" +
    "M14.6666 12.6667C14.6666 13.7713 13.7712 14.6667 12.6666 14.6667H11.3333C10.9651 14.6667 10.6666 14.3682 10.6666 14C10.6666 13.6318 10.9651 13.3334 11.3333 13.3334H12.6666C13.0348 13.3334 13.3333 13.0349 13.3333 12.6667V11.3334C13.3333 10.9652 13.6318 10.6667 14 10.6667C14.3681 10.6667 14.6666 10.9652 14.6666 11.3334V12.6667Z" +
    "M14.6666 3.33331C14.6666 2.22874 13.7712 1.33331 12.6666 1.33331H11.3333C10.9651 1.33331 10.6666 1.63179 10.6666 1.99998C10.6666 2.36817 10.9651 2.66665 11.3333 2.66665H12.6666C13.0348 2.66665 13.3333 2.96512 13.3333 3.33331V4.66665C13.3333 5.03484 13.6318 5.33331 14 5.33331C14.3681 5.33331 14.6666 5.03484 14.6666 4.66665V3.33331Z" +
    "M1.33337 7.99998C1.33337 7.63179 1.63185 7.33331 2.00004 7.33331H14C14.3682 7.33331 14.6667 7.63179 14.6667 7.99998C14.6667 8.36817 14.3682 8.66665 14 8.66665H2.00004C1.63185 8.66665 1.33337 8.36817 1.33337 7.99998Z" +
    "M9.33329 5.33333H6.66663C6.29844 5.33333 5.99996 5.63181 5.99996 6V10C5.99996 10.3682 6.29844 10.6667 6.66663 10.6667H9.33329C9.70148 10.6667 9.99996 10.3682 9.99996 10V6C9.99996 5.63181 9.70148 5.33333 9.33329 5.33333ZM6.66663 4C5.56206 4 4.66663 4.89543 4.66663 6V10C4.66663 11.1046 5.56206 12 6.66663 12H9.33329C10.4379 12 11.3333 11.1046 11.3333 10V6C11.3333 4.89543 10.4379 4 9.33329 4H6.66663Z",
  play: "M5.67139 11.7445L11.0601 8.57464C11.4983 8.31687 11.4983 7.68316 11.0601 7.42539L5.67139 4.25556C5.22696 3.99414 4.66671 4.31457 4.66671 4.83018V11.1699C4.66671 11.6855 5.22696 12.0059 5.67139 11.7445ZM11.7361 9.72389C13.0507 8.95058 13.0507 7.04945 11.7361 6.27615L6.34741 3.10632C5.01415 2.32204 3.33337 3.28335 3.33337 4.83018V11.1699C3.33337 12.7167 5.01415 13.678 6.34741 12.8937L11.7361 9.72389Z",
  moreArrow:
    "M8.04289 19.2071C7.65237 18.8166 7.65237 18.1834 8.04289 17.7929L13.8358 12L8.04289 6.20711C7.65237 5.81658 7.65237 5.18342 8.04289 4.79289C8.43342 4.40237 9.06658 4.40237 9.45711 4.79289L15.25 10.5858C16.031 11.3668 16.031 12.6332 15.25 13.4142L9.45711 19.2071C9.06658 19.5976 8.43342 19.5976 8.04289 19.2071Z",
  stop: "M22 12.2207C22 17.7436 17.5228 22.2207 12 22.2207C6.47715 22.2207 2 17.7436 2 12.2207C2 6.69786 6.47715 2.2207 12 2.2207C17.5228 2.2207 22 6.69786 22 12.2207ZM16.9056 18.5406C15.551 19.5936 13.8487 20.2207 12 20.2207C7.58172 20.2207 4 16.639 4 12.2207C4 10.372 4.62708 8.66974 5.68014 7.31506L16.9056 18.5406ZM18.3199 17.1263L7.09436 5.90085C8.44904 4.84778 10.1513 4.2207 12 4.2207C16.4183 4.2207 20 7.80242 20 12.2207C20 14.0694 19.3729 15.7717 18.3199 17.1263Z",
  clearAll:
    "M4.47145 3.52858C4.2111 3.26823 3.78899 3.26823 3.52864 3.52858C3.26829 3.78892 3.26829 4.21103 3.52864 4.47138L7.05724 7.99999L3.52866 11.5286C3.26831 11.7889 3.26831 12.211 3.52866 12.4714C3.78901 12.7317 4.21111 12.7317 4.47146 12.4714L8.00005 8.9428L11.5286 12.4714C11.789 12.7317 12.2111 12.7317 12.4714 12.4714C12.7318 12.211 12.7318 11.7889 12.4714 11.5286L8.94286 7.99999L12.4715 4.47139C12.7318 4.21104 12.7318 3.78893 12.4715 3.52858C12.2111 3.26823 11.789 3.26823 11.5287 3.52858L8.00005 7.05718L4.47145 3.52858Z",
  zoomin:
    "M12.0213 11.0784C12.8424 10.052 13.3334 8.74999 13.3334 7.33331C13.3334 4.0196 10.6471 1.33331 7.33337 1.33331C4.01967 1.33331 1.33337 4.0196 1.33337 7.33331C1.33337 10.647 4.01967 13.3333 7.33337 13.3333C8.75005 13.3333 10.0521 12.8423 11.0785 12.0212L12.862 13.8047C13.1223 14.0651 13.5444 14.0651 13.8048 13.8047C14.0651 13.5444 14.0651 13.1223 13.8048 12.8619L12.0213 11.0784ZM12 7.33331C12 9.91064 9.9107 12 7.33337 12C4.75605 12 2.66671 9.91064 2.66671 7.33331C2.66671 4.75598 4.75605 2.66665 7.33337 2.66665C9.9107 2.66665 12 4.75598 12 7.33331Z" +
    "M7.99996 5.33335C7.99996 4.96516 7.70148 4.66669 7.33329 4.66669C6.9651 4.66669 6.66663 4.96516 6.66663 5.33335V6.66669H5.33329C4.9651 6.66669 4.66663 6.96516 4.66663 7.33335C4.66663 7.70154 4.9651 8.00002 5.33329 8.00002H6.66663V9.33335C6.66663 9.70154 6.9651 10 7.33329 10C7.70148 10 7.99996 9.70154 7.99996 9.33335V8.00002H9.33329C9.70148 8.00002 9.99996 7.70154 9.99996 7.33335C9.99996 6.96516 9.70148 6.66669 9.33329 6.66669H7.99996V5.33335Z",

  zoomOut:
    "M12.0213 11.0784C12.8424 10.052 13.3334 8.74999 13.3334 7.33331C13.3334 4.0196 10.6471 1.33331 7.33337 1.33331C4.01967 1.33331 1.33337 4.0196 1.33337 7.33331C1.33337 10.647 4.01967 13.3333 7.33337 13.3333C8.75005 13.3333 10.0521 12.8423 11.0785 12.0212L12.862 13.8047C13.1223 14.0651 13.5444 14.0651 13.8048 13.8047C14.0651 13.5444 14.0651 13.1223 13.8048 12.8619L12.0213 11.0784ZM12 7.33331C12 9.91064 9.9107 12 7.33337 12C4.75605 12 2.66671 9.91064 2.66671 7.33331C2.66671 4.75598 4.75605 2.66665 7.33337 2.66665C9.9107 2.66665 12 4.75598 12 7.33331Z" +
    "M4.66663 7.33335C4.66663 6.96516 4.9651 6.66669 5.33329 6.66669H9.33329C9.70148 6.66669 9.99996 6.96516 9.99996 7.33335C9.99996 7.70154 9.70148 8.00002 9.33329 8.00002H5.33329C4.9651 8.00002 4.66663 7.70154 4.66663 7.33335Z",
  clone:
    "M8.68258 3.45765C8.24106 3.2971 7.75712 3.2971 7.3156 3.45765L2.61654 5.1664L7.3156 6.87516C7.75712 7.03571 8.24106 7.03571 8.68257 6.87516L13.3816 5.16641L8.68258 3.45765ZM6.85995 2.20459C7.5958 1.93701 8.40237 1.93701 9.13823 2.2046L13.8373 3.91335C15.0075 4.33889 15.0075 5.99392 13.8373 6.41946L9.13823 8.12822C8.40237 8.3958 7.5958 8.3958 6.85994 8.12821L2.16088 6.41946C0.99064 5.99392 0.990649 4.33889 2.16088 3.91335L6.85995 2.20459Z" +
    "M1.37269 8.26284C1.49604 7.91593 1.87726 7.73469 2.22417 7.85804L7.55415 9.75315C7.84308 9.85587 8.15859 9.85588 8.44751 9.75315L13.7775 7.85804C14.1244 7.73469 14.5056 7.91593 14.629 8.26284C14.7523 8.60976 14.5711 8.99098 14.2242 9.11433L8.89419 11.0094C8.31634 11.2149 7.68533 11.2149 7.10747 11.0094L1.77749 9.11433C1.43058 8.99098 1.24934 8.60976 1.37269 8.26284Z" +
    "M1.37269 11.0968C1.49604 10.7499 1.87726 10.5687 2.22417 10.692L7.55415 12.5871C7.84308 12.6899 8.15859 12.6899 8.44751 12.5871L13.7775 10.692C14.1244 10.5687 14.5056 10.7499 14.629 11.0968C14.7523 11.4437 14.5711 11.825 14.2242 11.9483L8.89419 13.8434C8.31634 14.0489 7.68533 14.0489 7.10747 13.8434L1.77749 11.9483C1.43058 11.825 1.24934 11.4437 1.37269 11.0968Z",
  customized_oders_grid:
    "M10.3496 3.37086H11.23C12.0038 3.37086 12.6311 3.99816 12.6311 4.77197V11.228C12.6311 12.0018 12.0038 12.6291 11.23 12.6291H10.3496V3.37086ZM6.8543 3.37086H9.14961V12.6291H6.8543V3.37086ZM5.6543 2.13086H6.8543H9.14961H10.3496H11.23C12.6886 2.13086 13.8711 3.31333 13.8711 4.77197V11.228C13.8711 12.6867 12.6886 13.8691 11.23 13.8691H10.3496H9.14961H6.8543H5.6543H4.77393C3.31528 13.8691 2.13281 12.6867 2.13281 11.228V4.77197C2.13281 3.31333 3.31528 2.13086 4.77393 2.13086H5.6543ZM5.6543 12.6291H4.77393C4.00011 12.6291 3.37281 12.0018 3.37281 11.228V4.77197C3.37281 3.99816 4.00011 3.37086 4.77393 3.37086H5.6543V12.6291Z",
  eye:
    "M8.00004 11.3333C10.7414 11.3333 12.3911 9.28865 13.122 8.0698C13.1387 8.04196 13.1433 8.0185 13.1433 7.99998C13.1433 7.98146 13.1387 7.958 13.122 7.93016C12.3911 6.71131 10.7414 4.66665 8.00004 4.66665C5.25868 4.66665 3.60899 6.71131 2.87804 7.93016C2.86134 7.958 2.85677 7.98146 2.85677 7.99998C2.85677 8.0185 2.86134 8.04196 2.87804 8.0698C3.60899 9.28865 5.25868 11.3333 8.00004 11.3333ZM14.2655 8.75555C13.4733 10.0765 11.4767 12.6666 8.00004 12.6666C4.52336 12.6666 2.52676 10.0765 1.73457 8.75555C1.45306 8.28614 1.45306 7.71382 1.73457 7.24441C2.52676 5.92345 4.52336 3.33331 8.00004 3.33331C11.4767 3.33331 13.4733 5.92345 14.2655 7.24441C14.547 7.71382 14.547 8.28614 14.2655 8.75555Z" +
    "M8 8.66667C8.36819 8.66667 8.66667 8.36819 8.66667 8C8.66667 7.63181 8.36819 7.33333 8 7.33333C7.63181 7.33333 7.33333 7.63181 7.33333 8C7.33333 8.36819 7.63181 8.66667 8 8.66667ZM8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z",
  itemsclock:
    "M2.66732 8.00065C2.66732 5.05513 5.05513 2.66732 8.00065 2.66732C9.67869 2.66732 11.1532 3.44298 12.1221 4.66279L11.0026 4.65951C10.6344 4.65843 10.3351 4.95603 10.334 5.32422C10.3329 5.69241 10.6305 5.99176 10.9987 5.99284L13.3711 5.99979L13.6654 6.00065C13.8425 6.00117 14.0126 5.93116 14.138 5.80608C14.2635 5.681 14.334 5.51113 14.334 5.33398L14.334 2.66732C14.334 2.29913 14.0355 2.00065 13.6673 2.00065C13.2991 2.00065 13.0006 2.29913 13.0006 2.66732L13.0007 3.63363C11.7923 2.22757 10.016 1.33398 8.00065 1.33398C4.31875 1.33398 1.33398 4.31875 1.33398 8.00065C1.33398 11.6825 4.31875 14.6673 8.00065 14.6673C10.6246 14.6673 12.8434 13.1525 13.931 10.964C14.0949 10.6343 13.9604 10.2342 13.6307 10.0703C13.301 9.90645 12.9008 10.0409 12.737 10.3706C11.861 12.1334 10.0883 13.334 8.00065 13.334C5.05513 13.334 2.66732 10.9462 2.66732 8.00065Z" +
    "M8.00065 4.66602C8.36884 4.66602 8.66732 4.96449 8.66732 5.33268V7.72321L9.80539 8.86128C10.0657 9.12163 10.0657 9.54374 9.80539 9.80409C9.54504 10.0644 9.12293 10.0644 8.86258 9.80409L7.72451 8.66602C7.47446 8.41597 7.33398 8.07683 7.33398 7.72321V5.33268C7.33398 4.96449 7.63246 4.66602 8.00065 4.66602Z",
  pending:
    "M0.666016 1.00065C0.666016 0.632461 0.964492 0.333984 1.33268 0.333984H10.666C11.0342 0.333984 11.3327 0.632461 11.3327 1.00065C11.3327 1.36884 11.0342 1.66732 10.666 1.66732V2.05251C10.666 3.10545 10.3543 4.13482 9.77028 5.01091L8.44379 7.00065L9.77028 8.99039C10.3543 9.86648 10.666 10.8959 10.666 11.9488V12.334C11.0342 12.334 11.3327 12.6325 11.3327 13.0007C11.3327 13.3688 11.0342 13.6673 10.666 13.6673H1.33268C0.964492 13.6673 0.666016 13.3688 0.666016 13.0007C0.666016 12.6325 0.964492 12.334 1.33268 12.334V11.9488C1.33268 10.8959 1.64435 9.86648 2.22841 8.99039L3.5549 7.00065L2.22841 5.01091C1.64435 4.13482 1.33268 3.10545 1.33268 2.05251V1.66732C0.964492 1.66732 0.666016 1.36884 0.666016 1.00065ZM2.66602 12.334H3.49934L4.58602 10.5953C5.2388 9.55086 6.7599 9.55086 7.41268 10.5953L8.49935 12.334H9.33268V11.9488C9.33268 11.1591 9.09893 10.3871 8.66088 9.72999L7.28577 7.66732H4.71293L3.33781 9.72999C2.89977 10.3871 2.66602 11.1591 2.66602 11.9488V12.334ZM2.66602 1.66732H9.33268V2.05251C9.33268 2.6111 9.21573 3.16084 8.99225 3.66732H3.00645C2.78297 3.16084 2.66602 2.6111 2.66602 2.05251V1.66732Z",
  packed:
    "M7.00065 13.6673C10.6825 13.6673 13.6673 10.6825 13.6673 7.00065C13.6673 5.97112 13.4339 4.9961 13.0172 4.12559C12.877 3.83259 12.4919 3.78552 12.2622 4.01522L7.27679 9.00065C6.75609 9.52135 5.91188 9.52135 5.39118 9.00065L3.52925 7.13872C3.2689 6.87837 3.2689 6.45626 3.52925 6.19591C3.7896 5.93556 4.21171 5.93556 4.47205 6.19591L6.33398 8.05784L11.5684 2.82708C11.7581 2.63755 11.7658 2.33059 11.5706 2.14676C10.3772 1.02278 8.76938 0.333984 7.00065 0.333984C3.31875 0.333984 0.333984 3.31875 0.333984 7.00065C0.333984 10.6825 3.31875 13.6673 7.00065 13.6673Z",
  minus:
    "M3.33337 7.99998C3.33337 7.63179 3.63185 7.33331 4.00004 7.33331H12C12.3682 7.33331 12.6667 7.63179 12.6667 7.99998C12.6667 8.36817 12.3682 8.66665 12 8.66665H4.00004C3.63185 8.66665 3.33337 8.36817 3.33337 7.99998Z",
};
const Icon = (props) => {
  const size = props.size ? props.size : 16;
  const style = props.style;
  return (
    <svg
      className={cn(props.className)}
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill={props.fill}
      style={style}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d={icons[props.name]}
      ></path>
    </svg>
  );
};

export default Icon;
